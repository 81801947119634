import styled from 'styled-components';

const LockIcon = (props) => {
    return (
        <StyledSvg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="2"
                y="6"
                width="12"
                height="9"
                rx="3"
                fill="currentColor"
            />
            <rect
                x="5"
                y="2"
                width="6"
                height="9"
                rx="3"
                stroke="currentColor"
                strokeWidth="2"
            />
        </StyledSvg>
    );
};

const StyledSvg = styled.svg`
    color: ${(p) => p.color || '#000'};
`;

export default LockIcon;
