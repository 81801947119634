import Router from 'next/router';
import { useEffect } from 'react';

let unsavedChanges;

export default function useUnsavedChanges() {
    const setUnsavedChanges = (bool) => (unsavedChanges = bool);

    const confirmNavigation = () => {
        const confirmed = confirm(
            'Du forlater nå siden og utførte endringer vil ikke bli lagret.'
        );
        if (confirmed) setUnsavedChanges(false);
        return confirmed;
    };

    const areUnsavedChanges = () => unsavedChanges;

    useEffect(() => {
        //console.log('useUnsavedChanges effect', window.unsavedChanges)

        const routeChangeStart = () => {
            if (unsavedChanges) {
                if (!confirmNavigation()) {
                    Router.events.emit('routeChangeError');
                    throw 'Route change interrupted by the user.';
                }
            }
        };

        if (unsavedChanges) {
            Router.events.on('routeChangeStart', routeChangeStart);
            return () => {
                Router.events.off('routeChangeStart', routeChangeStart);
            };
        } else {
            Router.events.off('routeChangeStart', routeChangeStart);
        }
    }, [unsavedChanges]);

    return {
        setUnsavedChanges,
        areUnsavedChanges,
        confirmNavigation,
    };
}
