import ContactCallbackModule from './ContactCallbackModule';
import ContactCallbackModuleSettings from './ContactCallbackModule.settings';
import { ContactCallbackModuleType } from './type';
import { ContainerSizes } from '/components/Container';

const moduleData: ContactCallbackModuleType = {
    _key: '',
    _type: 'contact_callback',
    title: 'Contact Callback',
    description: 'Contact Callback',
    image: '/modules/contact-callback.svg',
    preview: '/modules/contact-callback-preview.png',
    moduleComponent: ContactCallbackModule,
    settingsComponent: ContactCallbackModuleSettings,
    data: {
        title: 'Ønsker du å prate med noen angående bestillingen?',
        description: 'Legg igjen telefonnummeret ditt og vi ringer deg opp',
        buttonText: 'Snakk med våre solcelleeksperter',
        type: 'solar',
        containerSize: ContainerSizes.MEDIUM,
        modalTitle: 'Snakk med våre solcelleeksperter',
        modalDescription:
            'Legg igjen navn og nummer, og en av våre solcelleeksperter vil ta kontakt for å hjelpe deg med prosjektet ditt.',
        margin: '',
    },
};

export default moduleData;
