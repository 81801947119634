export default function House({ scale }: { scale: number }) {
    return (
        <svg
            width={scale * 545}
            height={scale * 478}
            viewBox="0 0 545 478"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.9716 180.708V356.557L244.377 478V331.297L121.219 143.789L32.9716 180.708Z"
                fill="#CCC3FE"
            />
            <path
                d="M217.224 244.828L122.189 293.405L253.105 368.214L299.653 313.808L217.224 244.828Z"
                fill="#ECEDF0"
            />
            <path
                d="M527.544 314.781L244.377 478V331.297L527.544 167.496V314.781Z"
                fill="#D8D2F9"
            />
            <path
                d="M244.377 318.666L122.189 136.016V146.703L244.377 331.296V318.666Z"
                fill="#B7ADF0"
            />
            <path
                d="M244.377 331.295V318.665L545 152.531V163.218L244.377 331.295Z"
                fill="#CCC3FE"
            />
            <path
                d="M432.509 0L122.189 136.016L244.377 318.666L545 152.532L432.509 0Z"
                fill="#7E69FD"
            />
            <path
                d="M284.137 61.2071L0 188.479L122.189 136.016L432.509 0L284.137 61.2071Z"
                fill="#7E69FD"
            />
            <path
                d="M0 196.251V188.479L122.189 136.016V146.703L0 196.251Z"
                fill="#B7ADF0"
            />
            <path
                d="M140.614 144.76L213.103 112.942L285.592 81.1243L358.081 49.3063L430.569 17.4883L455.298 51.2494L480.027 85.0105L504.755 118.772L529.484 152.533L459.177 191.151L388.87 229.77L318.563 268.389L248.256 307.008L221.346 266.446L194.435 225.884L167.524 185.322L140.614 144.76Z"
                fill="#0F0259"
            />
            <path
                d="M458.444 187.097L389.024 225.229L363.785 188.903L433.748 152.448L458.444 187.097ZM362.891 189.369L388.143 225.713L318.721 263.845L292.927 225.825L362.891 189.369ZM433.162 151.626L363.21 188.075L337.973 151.752L408.469 116.979L433.162 151.626ZM337.066 152.199L362.316 188.541L292.362 224.991L266.569 186.973L337.066 152.199ZM407.881 116.154L337.396 150.922L312.161 114.601L383.189 81.5097L407.881 116.154ZM311.241 115.03L336.489 151.369L266.002 186.138L240.211 148.122L311.241 115.03ZM382.599 80.6815L311.582 113.768L286.349 77.4512L357.909 46.0408L382.599 80.6815ZM285.416 77.8608L310.662 114.197L239.643 147.284L213.854 109.272L285.416 77.8608ZM383.52 80.252L358.845 45.6302L430.403 14.2206L454.535 47.1667L383.52 80.252ZM384.111 81.0802L455.137 47.9892L479.271 80.9388L408.789 115.706L384.111 81.0802ZM409.377 116.531L479.871 81.7579L504.008 114.71L434.058 151.159L409.377 116.531ZM434.644 151.981L504.606 115.527L528.744 148.482L459.326 186.612L434.644 151.981ZM212.923 109.681L238.724 147.712L167.703 180.801L141.359 141.092L212.923 109.681ZM248.419 302.461L222.07 262.746L292.034 226.29L317.841 264.328L248.419 302.461ZM265.664 187.42L291.469 225.457L221.514 261.908L195.166 222.194L265.664 187.42ZM265.097 186.585L194.608 221.354L168.262 181.643L239.293 148.55L265.097 186.585Z"
                fill="#2A262D"
                stroke="black"
            />
            <path
                d="M93.0961 401.247L58.1851 379.873V313.809L93.0961 331.296V401.247Z"
                fill="#95939F"
            />
            <path
                d="M58.1851 313.809L93.0961 331.296L101.824 326.439L65.9431 309.922L58.1851 313.809Z"
                fill="#EAE9F2"
            />
            <path
                d="M93.0961 331.295V401.246L101.824 396.389V326.438L93.0961 331.295Z"
                fill="#DAD9E2"
            />
            <path
                d="M140.614 144.761V140.875L143.523 143.79L140.614 144.761Z"
                fill="black"
            />
            <path
                d="M529.484 152.531V148.645L526.575 149.616L529.484 152.531Z"
                fill="black"
            />
            <path
                d="M122.189 293.406V146.703L194.92 256.487L122.189 293.406Z"
                fill="#C0BBDA"
            />
            <path
                d="M98.9146 341.981C123.158 341.981 116.194 301.897 127.037 294.376C137.881 286.855 142.553 281.746 142.553 250.656"
                stroke="#1C1C1C"
            />
            <path
                d="M159.039 247.744L138.674 256.488L134.795 254.545V219.569L155.16 211.797L159.039 212.768V247.744Z"
                fill="#DDDBEA"
            />
            <path
                d="M138.674 220.541V256.488M138.674 220.541L159.039 212.768M138.674 220.541L134.795 219.569M138.674 256.488L159.039 247.744V212.768M138.674 256.488L134.795 254.545V219.569M159.039 212.768L155.16 211.797L134.795 219.569"
                stroke="#95939F"
            />
            <path
                d="M68.3522 313.809C68.3522 314.086 68.5761 314.309 68.8522 314.309C69.1283 314.309 69.3522 314.086 69.3522 313.809H68.3522ZM68.8522 242.887L68.3566 242.953L68.8522 242.887ZM73.1476 196.53C74.0737 195.138 75.6615 193.583 77.8035 191.902C79.9394 190.227 82.5992 188.447 85.6427 186.601C91.7297 182.908 99.3202 178.969 107.256 175.085C123.095 167.333 140.36 159.777 149.574 154.919L149.108 154.034C139.896 158.891 122.736 166.395 106.816 174.187C98.8722 178.075 91.2498 182.03 85.1241 185.746C82.0613 187.603 79.3648 189.406 77.1862 191.116C75.0136 192.82 73.3285 194.453 72.3151 195.976L73.1476 196.53ZM69.3522 313.809C69.3522 312.349 69.5747 308.89 69.8559 304.059C70.1363 299.242 70.4729 293.09 70.6974 286.277C71.1461 272.657 71.1474 256.372 69.3479 242.821L68.3566 242.953C70.1449 256.42 70.1462 272.637 69.6979 286.244C69.4739 293.044 69.1378 299.187 68.8576 304.001C68.5782 308.802 68.3522 312.31 68.3522 313.809H69.3522ZM69.3479 242.821C66.4528 221.02 65.5103 208.007 73.1476 196.53L72.3151 195.976C64.4364 207.816 65.471 221.224 68.3566 242.953L69.3479 242.821Z"
                fill="#1C1C1C"
            />
        </svg>
    );
}
