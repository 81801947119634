import { useState, useEffect } from 'react';
import { atom, useAtom } from 'jotai';
import { MdViewModule } from 'react-icons/md';

import ui from './../ui';
import id from './../lib/id';
import styles from './../styles/AdminBar/ModuleBrowser.module.scss';
import { Row, Col } from './../styles';
import { error } from './../lib/message';
import {
    isInView,
    ensureArray,
    insertAtIndex,
    toSnake,
} from './../lib/helpers';
import _ from './../_';
import config from '../config';
import moduleMap from '/components/modules';
import ModuleTemplate from '../types/frontModuleTemplate';
import { PageTypes } from '../types/frontPageType';

const scrollToNewModule = (insertIndex: number) =>
    setTimeout(() => {
        const newModuleEl = document.querySelector(
            `.module-index-${insertIndex}`
        );
        if (newModuleEl && !isInView(newModuleEl))
            newModuleEl.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
    }, 500);

enum Tab {
    MODULES = 'modules',
    TEMPLATES = 'templates',
}

type Object = { [key: string]: any };

interface Item {
    title: string;
    _type: string;
    _key: string | number;
    data: Object;
    description?: string;
    image?: string;
    preview?: string;
    usedOn?: number;
}

let timeout: ReturnType<typeof setTimeout>;

export const modalVisibleAtom = atom<boolean>(false);
export const insertAtIndexAtom = atom<number>(0);

export default function ModuleBrowser() {
    const [modalVisible, setModalVisisble] = useAtom(modalVisibleAtom);
    const [insertIndex, setInsertIndex] = useAtom(insertAtIndexAtom);

    const [preview, setPreview] = useState<string>('');
    const [previewPosition, setPreviewPosition] = useState({
        top: '0px',
        left: '0px',
    });
    const [tab, setTab] = useState<Tab>(Tab.MODULES);
    const [moduleTemplates, setModuleTemplates] = useState<ModuleTemplate[]>(
        []
    );

    const { page, setPage } = config.usePage();

    const { Modal, editMode, Tabs } = ui();

    const shouldFetchTemplates = () =>
        tab === Tab.TEMPLATES && !moduleTemplates.length;

    const isTemplateModuleType = (moduleType: string) =>
        moduleType === toSnake(PageTypes.MODULE_TEMPLATE);

    const attemptingToNestTemplates = (moduleType: string) =>
        page?._type === PageTypes.MODULE_TEMPLATE &&
        isTemplateModuleType(moduleType);

    useEffect(() => {
        if (shouldFetchTemplates())
            (async () => {
                const resp = await config.api.moduleTemplate.get();
                if (!('error' in resp)) {
                    setModuleTemplates(resp);
                }
            })();
    }, [tab]);

    const addModule = (
        moduleKey: string | number,
        moduleType: string,
        moduleData: Object
    ) => {
        if (attemptingToNestTemplates(moduleType)) {
            error("You can't add template into another template.");
            return;
        }

        setPage({
            ...page,
            modules: insertAtIndex(ensureArray(page?.modules), insertIndex, {
                _key: id(),
                _type: moduleType,
                data: isTemplateModuleType(moduleType)
                    ? { templateId: moduleKey, modules: [] }
                    : moduleData,
            }),
        });
        scrollToNewModule(insertIndex);
        closeModal();
    };

    const calculateAndSetPreviewPosition = (
        e: React.MouseEvent<HTMLElement>
    ) => {
        const gridContainerRect =
            e.currentTarget.parentElement?.parentElement?.getBoundingClientRect();

        if (!gridContainerRect) return;

        setPreviewPosition({
            top: e.clientY - gridContainerRect?.top + 64 + 'px',
            left: e.clientX - gridContainerRect?.left + 'px',
        });
    };

    const showPreview = (previewImage?: string) => {
        if (!previewImage) return;

        timeout = setTimeout(() => {
            setPreview(previewImage);
        }, 500);
    };

    const hidePreview = () => {
        setPreview('');
        clearTimeout(timeout);
    };

    const closeModal = () => {
        setModalVisisble(false);
        setTab(Tab.MODULES);
    };

    const items = (): Item[] => {
        if (tab === Tab.MODULES)
            return Object.keys(moduleMap)
                .map((key) => moduleMap[key])
                .filter((item) => !isTemplateModuleType(item._type));

        if (tab === Tab.TEMPLATES)
            return moduleTemplates.map((t) => ({
                title: t.title,
                description: t.description,
                _type: toSnake(PageTypes.MODULE_TEMPLATE),
                _key: t.id,
                data: t.data,
                usedOn: t.usedOn.length,
                image: '/modules/template.svg',
                preview: t.preview?.url + '?w=400&auto=format&dpr=2',
            }));

        return [];
    };

    if (!page) return null;

    return (
        <>
            <div
                onClick={() => {
                    setInsertIndex(page.modules.length);
                    setModalVisisble(true);
                }}
                style={{ display: editMode ? 'block' : 'none' }}
            >
                <MdViewModule
                    style={{
                        width: '22px',
                        height: '22px',
                        margin: '0 4px -5px 0',
                    }}
                />{' '}
                Modules
            </div>
            {modalVisible && (
                <Modal
                    onClose={closeModal}
                    width="80vw"
                    height="80vh"
                    className={`${styles.moduleBrowser}`}
                    title={_('Modules')}
                    style={{ maxWidth: '1140px' }}
                    toolbarChildren={
                        <Tabs
                            tabs={[
                                { name: 'Modules', value: 'modules' },
                                { name: 'Templates', value: 'templates' },
                            ]}
                            active={tab}
                            onChange={(val) => setTab(val as Tab)}
                        />
                    }
                >
                    <Row>
                        {tab === 'templates' && (
                            <Col width={[12]}>
                                <p
                                    style={{
                                        whiteSpace: 'initial',
                                        marginBottom: '20px',
                                        color: '#fff',
                                    }}
                                >
                                    Templates are groups of modules that can be
                                    shared across different pages. Modules that
                                    are part of a template can&apos;t be edited
                                    on page where they are used. If you want to
                                    edit template (or its modules) go to
                                    &quot;Templates&quot; section in CMS
                                    settings.
                                </p>
                            </Col>
                        )}
                        {items().map((item) => {
                            const isTemplate = isTemplateModuleType(item._type);

                            return (
                                <Col
                                    width={[3, 3, 6]}
                                    className={`${styles.module}`}
                                    key={`${item._type}-${item._key}`}
                                >
                                    <div
                                        onClick={() =>
                                            addModule(
                                                item._key,
                                                item._type,
                                                item.data
                                            )
                                        }
                                        onMouseOver={() =>
                                            showPreview(item.preview)
                                        }
                                        onMouseOut={() => hidePreview()}
                                        onMouseMove={
                                            calculateAndSetPreviewPosition
                                        }
                                    >
                                        <ItemImage
                                            item={item}
                                            isTemplate={isTemplate}
                                        />
                                        <div>
                                            <span>{item.title}</span>
                                            <span>
                                                {item.description || ''}
                                                {item.description &&
                                                    item.usedOn && <br />}
                                                {item.usedOn !== undefined && (
                                                    <>
                                                        {item.data.length}{' '}
                                                        modules used on{' '}
                                                        {item.usedOn} page
                                                        {item.usedOn > 1 && 's'}
                                                    </>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                    {preview && (
                        <div className={styles.preview} style={previewPosition}>
                            <img src={preview} alt="preview" />
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
}

const ItemImage = ({
    item,
    isTemplate,
}: {
    item: Item;
    isTemplate: boolean;
}) => {
    if (isTemplate)
        return (
            <div
                style={{
                    backgroundImage: `url(${item.preview})`,
                }}
                className={styles.templateImage}
            />
        );

    return (
        <div>
            {item.image && (
                // eslint-disable-next-line @next/next/no-img-element
                <img src={item.image} alt="module-image" />
            )}
        </div>
    );
};
