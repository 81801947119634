import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';
import MarginSetting from './../_shared/MarginSetting';
import { RichTextModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import VerticalPaddingSetting from '../_shared/VerticalPaddingSetting';

export default function RichTextModuleSettings({
    module,
    onChange,
}: ModuleProps<RichTextModuleType>) {
    const { Select, ColorPicker } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        margin,
        containerWidth,
        textStyle,
        textAlignment,
        verticalPadding,
        backgroundColor,
    } = module.data;

    return (
        <>
            <Row className="mb-24">
                <Col width={[6]}>
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6]}>
                    <VerticalPaddingSetting
                        verticalPadding={verticalPadding}
                        onChange={change('verticalPadding')}
                    />
                </Col>
            </Row>
            <Row className="mb-24">
                <Col width={[6]}>
                    <ContainerSizeSetting
                        containerSize={containerWidth}
                        onChange={change('containerWidth')}
                    />
                </Col>
                <Col width={[6]}>
                    <ColorPicker
                        value={backgroundColor}
                        onChange={change('backgroundColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Background color"
                    />
                </Col>
            </Row>
            <Row className="mb-24">
                <Col width={[6]}>
                    <Select
                        value={textStyle ?? 'default'}
                        options={['default', 'terms']}
                        onChange={change('textStyle')}
                        medium
                        style={{ width: '100%' }}
                        label="Text style"
                    />
                </Col>
                <Col width={[6]}>
                    <Select
                        value={textAlignment}
                        options={['left', 'center', 'right']}
                        onChange={change('textAlignment')}
                        medium
                        style={{ width: '100%' }}
                        label="Text alignment"
                    />
                </Col>
            </Row>
        </>
    );
}
