import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';

export default function GoodBetterBestModuleSettings({ module, onChange }) {
    const { Select, ColorPicker, Switch, Input } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, background, withTitle, withButton, trackingId } =
        module.data;

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ColorPicker
                        value={background}
                        onChange={change('background')}
                        medium
                        style={{ width: '100%' }}
                        label="Background color"
                    />
                </Col>
                <Col width={12} className="mb-24">
                    <Input
                        value={trackingId}
                        onChange={change('trackingId')}
                        medium
                        style={{ width: '100%' }}
                        label="Tracking ID"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Switch
                        on={withTitle}
                        onChange={change('withTitle')}
                        style={{ marginTop: '5px' }}
                        label="Show title"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Switch
                        on={withButton}
                        onChange={change('withButton')}
                        style={{ marginTop: '5px' }}
                        label="Show button"
                    />
                </Col>
            </Row>
        </>
    );
}
