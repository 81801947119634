import TogglesModule from './TogglesModule';
import TogglesModuleSettings from './TogglesModuleSettings';
import { TogglesModuleType, Theme } from './types';
import { ContainerSizes } from '/components/Container';

const moduleData: TogglesModuleType = {
    _key: '',
    _type: 'toggles',
    title: 'Toggles',
    description: 'list of expadable items',
    image: '/modules/toggles.svg',
    preview: '/modules/toggles-preview.png',
    moduleComponent: TogglesModule,
    settingsComponent: TogglesModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
        containerSize: ContainerSizes.LARGE,
        listTitle: false,
        listSubtitle: false,
        title: '',
        subtitle: '',
        theme: Theme.DEFAULT,
        withReadMoreLink: false,
        readMoreLink: undefined,
    },
};

export default moduleData;
