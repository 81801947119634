import TextAndImageModule from './TextAndImageModule';
import TextAndImageModuleSettings from './TextAndImageModuleSettings';
import { TextAndImageModuleType } from './types';
import { ContainerSizes } from '/components/Container';

const moduleData: TextAndImageModuleType = {
    _key: '',
    _type: 'text_and_image',
    title: 'Text and image',
    description: 'Rich text and image on the side',
    image: '/modules/textAndImage.svg',
    preview: '/modules/textAndImage-preview.png',
    moduleComponent: TextAndImageModule,
    settingsComponent: TextAndImageModuleSettings,
    data: {
        content: '',
        buttons: [],
        margin: 'mb-60',
        image: null,
        imageAlignment: 'right',
        textPosition: 'center',
        containerSize: ContainerSizes.LARGE,
        verticalPadding: '',
        backgroundColor: '#ffffff',
        textImageSpacing: '24px',
        imageRoundedBorders: false,
        textColor: 'black',
        proportions: '50-50',
    },
};

export default moduleData;
