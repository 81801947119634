import { memo } from 'react';
import styled from 'styled-components';

import Container from '/components/Container';
import { Image, config } from '/front';
import { ImageGridModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import ImageType from '/front/types/frontImageType';

const gcd = (a: number, b: number): number => {
    while (b) {
        let t = b;
        b = a % b;
        a = t;
    }
    return a;
};

const ratio = (a: number, b: number): string => {
    const divisor = gcd(a, b);
    return `${a / divisor}:${b / divisor}`;
};

export default memo(function ImageGridModule({
    module,
    onChange,
}: ModuleProps<ImageGridModuleType>) {
    const { margin, images, theme } = module.data;

    const change = (index: number) => (image: ImageType | null) => {
        const newImages = [...images];
        newImages[index] = image;
        onChange({
            data: { ...module.data, images: newImages as ImageType[] },
        });
    };

    return (
        <div className={margin}>
            <StyledContainer>
                <div className={`theme-${theme}`}>
                    <div className="left image-wrapper">
                        <Image
                            image={images[0]}
                            onChange={change(0)}
                            src={config
                                .imageUrl(images[0])
                                .width(765)
                                .height(487)
                                .url()}
                            cropRatio={ratio(765, 487)}
                            alt={images[0]?.alt}
                            style={{ display: 'block' }}
                        />
                    </div>
                    <div className="right">
                        <div className="image-wrapper">
                            <Image
                                image={images[1]}
                                onChange={change(1)}
                                src={config
                                    .imageUrl(images[1])
                                    .width(371)
                                    .height(
                                        theme === 'big-small-small' ? 231 : 487
                                    )
                                    .url()}
                                cropRatio={ratio(
                                    371,
                                    theme === 'big-small-small' ? 231 : 487
                                )}
                                alt={images[1]?.alt}
                                style={{ display: 'block' }}
                            />
                        </div>
                        {theme === 'big-small-small' && (
                            <div className="image-wrapper">
                                <Image
                                    image={images[2]}
                                    onChange={change(2)}
                                    src={config
                                        .imageUrl(images[2])
                                        .width(371)
                                        .height(231)
                                        .url()}
                                    cropRatio={ratio(371, 231)}
                                    alt={images[2]?.alt}
                                    style={{ display: 'block' }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </StyledContainer>
        </div>
    );
});

const StyledContainer = styled(Container)`
    > div {
        display: flex;
        justify-content: space-between;
        border-radius: 24px;
        overflow: hidden;
        max-height: 487;
        .image-wrapper {
            background: #ccc;
            img {
                width: 100%;
            }
        }
        .left {
            width: 66%;
        }
        .right {
            width: 32%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &.theme-small-big {
            .left {
                order: 2;
            }
            .right {
                order: 1;
            }
        }
        &.theme-big-small {
        }
        &.theme-big-small-small {
            .right > div {
                height: 47.6%;
            }
        }
    }
`;
