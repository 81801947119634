import { memo } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import { Text, config, Button } from '/front';

export default memo(function TipModule({ module, onChange }) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        margin,
        description,
        heading,
        textAlignment,
        backgroundColor,
        backgroundImage,
        textColor,
        button,
        overlayOpacity,
        overlayColor,
    } = module.data;

    const bgd = backgroundImage
        ? config.imageUrl(backgroundImage).width(2000).url()
        : '';

    return (
        <Wrapper
            style={{
                backgroundColor: backgroundColor,
                backgroundImage: bgd ? `url(${bgd})` : 'none',
                backgroundrepeat: 'no-repeat',
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
            }}
            className={[margin, `text-alignment-${textAlignment}`]
                .filter((c) => c)
                .join(' ')}
        >
            <div
                className="image-overlay"
                style={{
                    background: overlayColor || 'black',
                    opacity: (overlayOpacity || 0) / 100,
                }}
            />
            <Container className="pt-80 pb-80 container">
                <div className="text-wrapper">
                    <div className="description-text-container">
                        <Text
                            value={description}
                            onChange={change('description')}
                            placeholder="Description"
                            className={`paragraph-medium text-align-${textAlignment}`}
                            style={{ color: textColor }}
                            tag="p"
                        />
                    </div>
                    <div className="heading-text-container">
                        <Text
                            value={heading}
                            onChange={change('heading')}
                            placeholder="Heading"
                            multiline
                            className={`heading-xl mb-32 text-align-${textAlignment}`}
                            style={{ color: textColor }}
                            tag="h3"
                        />
                    </div>
                </div>
                <Button
                    className="button"
                    value={button}
                    onChange={change('button')}
                    link={config.pageUrl(button)}
                >
                    {button?.title || 'button'}
                </Button>
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .image-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .heading-text-container {
        width: 800px;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    .description-text-container {
        width: 565px;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
    &.text-alignment-center {
        .text-wrapper {
            width: 100%;
            .heading-text-container,
            .description-text-container {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    &.text-alignment-left {
        .container {
            flex-direction: row;
            justify-content: space-between;
            .text-wrapper {
                padding: 0 50px 0 0;
            }
        }
    }
    &.text-alignment-right {
        .container {
            flex-direction: row;
            justify-content: space-between;
            .text-wrapper {
                padding: 0 0 0 50px;
                order: 2;
                .description-text-container {
                    margin-left: auto;
                }
            }
            .button {
                order: 1;
            }
        }
    }
    @media (max-width: 420px) {
        &.text-alignment-right,
        &.text-alignment-left {
            .container {
                flex-direction: column;
                .text-wrapper {
                    width: 100%;
                    padding: 0;
                    order: 1;
                    .heading-text-container,
                    .description-text-container {
                        margin-left: auto;
                        margin-right: auto;
                    }
                    .paragraph-medium,
                    .heading-xl {
                        text-align: center;
                    }
                }
                .button,
                .has-front-actions {
                    order: 2;
                }
            }
        }
    }
`;
