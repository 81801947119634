import HeroModule from './HeroModule';
import HeroModuleSettings from './HeroModuleSettings';
import { HeroModuleType } from './types';

const moduleData: HeroModuleType = {
    _key: '',
    _type: 'hero',
    title: 'Hero',
    description: 'Large heading, image and call to actions',
    image: '/modules/hero.svg',
    preview: '/modules/hero-preview.png',
    moduleComponent: HeroModule,
    settingsComponent: HeroModuleSettings,
    data: {
        description: '',
        heading: '',
        backgroundColor: '#EBF9F8',
        margin: 'mb-60',
        verticalPadding: '60',
        buttons: [],
        image: null,
        imagePlacement: 'below-text',
        imageRoundedCorners: false,
        backgroundImage: null,
        backgroundOverlayColor: 'transparent',
        backgroundOverlayOpacity: 0,
        withImage: true,
        textColor: 'black',
        topBleed: false,
    },
};

export default moduleData;
