import ImageGrid from './ImageGridModule';
import ImageGridSettings from './ImageGridModuleSettings';
import { ImageGridModuleType } from './types';

const moduleData: ImageGridModuleType = {
    _key: '',
    _type: 'image_grid',
    title: 'Image grid',
    description: '',
    image: '/modules/imageGrid.svg',
    preview: '/modules/imageGrid-preview.png',
    moduleComponent: ImageGrid,
    settingsComponent: ImageGridSettings,
    data: {
        images: [null, null, null],
        margin: 'mb-60',
        theme: 'small-big',
    },
};

export default moduleData;
