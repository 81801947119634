import { memo, useState } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';

import { colors } from '/css';
import Link from '/components/ui/Link';
import Card from '/components/ui/Card';
import Container from '/components/Container';
import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';
import LegacyInput from '../../ui/LegacyInput';
import Button from '/components/ui/buttons/Button';
import Select from '/components/ui/Select';
import Error from '/components/ui/Error';
import Icon from '/components/ui/Icon';
import { isEmail } from '/lib/helpers';
import miscApi from '/api/misc';

import { Text } from '/front';

export default memo(function ContactModule({ module, onChange }) {
    const {
        chatCardDescription,
        phoneCardDescription,
        emailCardDescription,
        phoneNumber,
    } = module.data;

    const change = (key) => (val) =>
        onChange({ data: { ...module.data, [key]: val } });

    const [formVisible, setFormVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [topic, setTopic] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const [sent, setSent] = useState(false);

    const openChat = () => {
        itxChatUtils.toggleChat(true);
    };

    const toggleForm = () => {
        setFormVisible(!formVisible);
        document
            .getElementById('anchor')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const call = () =>
        window.open(`tel:${phoneNumber.replace(/\s-/g, '')}`, '_self');

    const sendMessage = async () => {
        if (sending) return;
        setSending(true);
        setError(null);

        const resp = await miscApi.sendContactEmail({
            name,
            email,
            topic,
            message,
        });

        if (resp.error) {
            setError(resp.error);
            return setSending(false);
        }

        setSending(false);
        setSent(true);
        setName('');
        setEmail('');
        setMessage('');
        setTopic('');
        document
            .getElementById('anchor')
            ?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setTimeout(() => {
            setSent(false);
            setFormVisible(false);
        }, 5000);
    };

    return (
        <Wrapper className={module.data.margin}>
            <div className="cards" id="cards">
                <Card clickable className={'card'} onClick={openChat}>
                    <img
                        src={`/images/chat.svg`}
                        title={`Chat med oss`}
                        alt={`Chat med oss`}
                    />
                    <Link
                        underlined
                        orange
                        className="mb-16"
                        onClick={() => null}
                    >
                        Chat med oss
                    </Link>
                    <Text
                        value={chatCardDescription}
                        onChange={change('chatCardDescription')}
                        placeholder="Card description"
                        multiline
                        className="mb-16 card-description text-align-center"
                        tag="p"
                    />
                </Card>
                <Card clickable className={'card'} onClick={call}>
                    <img
                        src={`/images/call.svg`}
                        title={`Ring oss`}
                        alt={`Ring oss`}
                    />
                    <Link underlined orange className="mb-16" onClick={call}>
                        Ring oss
                    </Link>
                    <Text
                        value={phoneCardDescription}
                        onChange={change('phoneCardDescription')}
                        placeholder="Card description"
                        multiline
                        className="mb-16 card-description text-align-center"
                        tag="p"
                    />
                </Card>
                <Card clickable className={'card'} onClick={toggleForm}>
                    <img
                        src={`/images/email.svg`}
                        title={`Kontaktskjema`}
                        alt={`Kontaktskjema`}
                    />
                    <Link
                        underlined
                        orange
                        className="mb-16"
                        onClick={toggleForm}
                    >
                        Kontaktskjema
                    </Link>
                    <Text
                        value={emailCardDescription}
                        onChange={change('emailCardDescription')}
                        placeholder="Card description"
                        multiline
                        className="mb-16 card-description text-align-center"
                        tag="p"
                    />
                </Card>
            </div>

            <div id="anchor" />

            <AnimateHeight duration={300} height={sent ? 'auto' : 0}>
                <div className="mt-80 pt-48 pb-48 sent">
                    <Icon icon="check" className="mb-12" />
                    <Heading large centered className="mb-16">
                        Melding sendt
                    </Heading>
                    <Paragraph medium centered maxWidth="480px">
                        {' '}
                        Vi vil besvare din henvendelse så raskt som mulig, innen
                        24 timer.
                    </Paragraph>
                </div>
            </AnimateHeight>

            <AnimateHeight
                duration={500}
                height={formVisible && !sent ? 'auto' : 0}
            >
                <div className="pt-80" id="form">
                    <Container smaller>
                        <Heading large centered className="mb-32" tag="h6">
                            Kontaktskjema
                        </Heading>
                        <Paragraph medium centered className="mb-48">
                            Fyll ut kontaktskjema og vi vil besvare din
                            henvendelse innen 24 timer.
                        </Paragraph>
                        <LegacyInput
                            name="name"
                            label="Navn"
                            placeholder="Skriv inn navnet ditt"
                            rules={{ required: true }}
                            onChange={setName}
                            value={name}
                            className="mb-24"
                        />
                        <LegacyInput
                            name="email"
                            label="E-post"
                            placeholder="Skriv inn din e-postadresse"
                            rules={{ required: true, email: true }}
                            onChange={setEmail}
                            value={email}
                            className="mb-32"
                        />
                        <Select
                            name="topic"
                            placeholder="Velg kategori"
                            className="mb-24"
                            onChange={setTopic}
                            value={topic}
                            options={[
                                'Bestilling',
                                'Tilbud',
                                'Betaling',
                                'Reklamasjon',
                                'Andre henvendelser',
                            ]}
                        />
                        <LegacyInput
                            name="message"
                            label="Melding"
                            placeholder="Skriv inn din melding"
                            rules={{ required: true }}
                            onChange={setMessage}
                            value={message}
                            multiline
                            className="mb-48"
                        />
                        <Error>{error}</Error>
                        <Button
                            onClick={sendMessage}
                            variant="secondary"
                            busy={sending}
                            className="submit mw-220 btn-center-x"
                            disabled={
                                !(
                                    name &&
                                    email &&
                                    topic &&
                                    message &&
                                    isEmail(email)
                                )
                            }
                        >
                            Send
                        </Button>
                    </Container>
                </div>
            </AnimateHeight>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .cards {
        width: 100%;
        max-width: 774px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @media (max-width: 774px) {
            padding-left: 24px;
            padding-right: 24px;
        }
        @media (max-width: 650px) {
            width: 100%;
            max-width: 350px;
        }
        @media (max-width: 480px) {
            max-width: 100%;
        }
        .card {
            width: calc(33.33% - 16px);
            @media (max-width: 650px) {
                width: 100%;
                margin-bottom: 24px;
            }
            .children-container {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                }
                .editable-wrapper {
                    width: 100%;
                }
                .card-description {
                    font-size: 15px;
                    line-height: 23px;
                    color: ${colors.grey};
                    text-align: center;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .submit {
        @media (max-width: 480px) {
            width: 100%;
        }
    }
    .sent {
        background: ${colors.greenLight};
        text-align: center;
        padding-left: 24px;
        padding-right: 24px;
    }
`;
