import { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from '/lib/utils/moment';
import xor from 'lodash.xor';

import { colors } from '/css';
import Icon from '/components/ui/Icon';
import LockIcon from '/components/icons/LockIcon';
import Paragraph from '/components/ui/Paragraph';

const Callendar = ({
    daysPerWeek,
    date,
    onMonthChnage,
    canSelectDay,
    onDaySelect,
    onDayClick,
    canSelectDays,
    onDaysSelect,
    selected,
    marked,
    deleted,
    edited,
    locked,
    disableUnsellected,
    disableUnsellectedExcluding,
    disabled,
    className,
    canSelectPassed,
}) => {
    const init =
        selected && selected[0]
            ? moment(selected[0]).startOf('month')
            : moment(date).startOf('month');

    const [month, setMonth] = useState(init);

    const [monthChanged, setMonthChanged] = useState(false);

    const [selectedDays, setSelectedDays] = useState(selected || []);

    const prevMonth = () => {
        setMonth(month.clone().subtract(1, 'month'));
        setMonthChanged(true);
    };

    const nextMonth = () => {
        setMonth(month.clone().add(1, 'month'));
        setMonthChanged(true);
    };

    const today = moment();

    const monthSpan = Number(month.format('E')) - 1; // empty space before first day of the month

    if (!daysPerWeek) daysPerWeek = 7;

    const weekDays = (format) =>
        Array.apply(null, Array(daysPerWeek)).map((_, i) => {
            return moment(i, 'e')
                .startOf('week')
                .isoWeekday(i + 1)
                .format(format);
        });

    const onCallendarDayClick = (day) => {
        const date = day.format('YYYY-MM-DD');

        if (onDayClick) onDayClick(day);

        if (canSelectDay) {
            setSelectedDays(selectedDays.includes(date) ? [] : [date]);
            onDaySelect(day);
        } else if (canSelectDays) {
            setSelectedDays(xor(selectedDays, [date]));
            onDaysSelect(xor(selectedDays, [date]));
        }
    };

    useEffect(() => {
        if (monthChanged && onMonthChnage) onMonthChnage(month);
    }, [month]);

    useEffect(() => {
        if (selected !== selectedDays) setSelectedDays(selected || []);
    }, [selected]);

    return (
        <Wrapper className={className} disabled={disabled}>
            <Nav className="callendar-nav">
                <button onClick={prevMonth}>
                    <Icon icon="arrow-left" />
                </button>
                <Paragraph medium>{month.format('MMMM YYYY')}</Paragraph>
                <button onClick={nextMonth}>
                    <Icon icon="arrow-right" />
                </button>
            </Nav>

            <WeekDays daysPerWeek={daysPerWeek}>
                {weekDays('ddd').map((wd) => (
                    <span small="1" key={wd}>
                        {wd}
                    </span>
                ))}
            </WeekDays>

            <Month
                daysPerWeek={daysPerWeek}
                hasMarkedDays={
                    (marked && marked.length > 0) ||
                    (locked && locked.length > 0)
                }
            >
                {monthSpan < daysPerWeek
                    ? [...Array(monthSpan).keys()].map((i) => (
                          <DayWrapper key={i} className="hidden day-wrapper" />
                      ))
                    : null}

                {[...Array(month.daysInMonth()).keys()].map((i) => {
                    const day = month.clone().add(i, 'days');
                    const date = day.format('YYYY-MM-DD');
                    const isToday = day.isSame(today, 'day');
                    let isPassed =
                        day.startOf('day').diff(today.startOf('day')) < 0;
                    const isLocked = locked && locked.includes(date);
                    const isSelected = selectedDays.includes(date);
                    const isMarked = marked && marked.includes(date);
                    const isDeleted = deleted && deleted.includes(date);
                    const isEdited = edited && edited.includes(date);

                    if (
                        disableUnsellected &&
                        !isPassed &&
                        !isSelected &&
                        !(disableUnsellectedExcluding || []).includes(date)
                    )
                        isPassed = true;

                    if (Number(day.format('E')) > daysPerWeek) return null;

                    return (
                        <DayWrapper
                            key={i}
                            className={[
                                'day-wrapper',
                                isToday ? 'today' : '',
                                isPassed && !canSelectPassed ? 'passed' : '',
                                isSelected ? 'selected' : '',
                                isMarked ? 'marked' : '',
                                isLocked ? 'locked' : '',
                                isDeleted ? 'deleted' : '',
                                isEdited ? 'edited' : '',
                            ].join(' ')}
                            onClick={() =>
                                (isPassed && !canSelectPassed) || isLocked
                                    ? null
                                    : onCallendarDayClick(day)
                            }
                        >
                            <div className="day">
                                <span className="day-num">
                                    {day.format('D')}
                                </span>
                                {isMarked && (
                                    <div>
                                        <Icon icon="dot" className="dot" />
                                    </div>
                                )}
                                {isLocked && (
                                    <div>
                                        <LockIcon />
                                    </div>
                                )}
                                {isDeleted && (
                                    <div>
                                        <Icon
                                            icon="cross"
                                            fill={colors.grey}
                                            style={{ marginTop: '4px' }}
                                        />
                                    </div>
                                )}
                                {isEdited && (
                                    <div>
                                        <Icon icon="pen" className="pen" />
                                    </div>
                                )}
                            </div>
                        </DayWrapper>
                    );
                })}
            </Month>
        </Wrapper>
    );
};

export default Callendar;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${(props) =>
        props.disabled
            ? `
        &:before {
            content: '';
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background: rgba(255,255,255, .5);
            z-index: 10;
        }
    `
            : ``};
`;

const Nav = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    width: 100%;
    max-width: 400px;
    @media (max-width: 767px) {
        margin-bottom: 12px;
    }
    p {
        top: -3px;
        text-transform: capitalize;
        @media (max-width: 767px) {
            top: -1px;
            font-size: 20px;
        }
    }
`;

const WeekDays = styled.div`
    display: flex;
    width: 100%;
    > span {
        width: ${(props) => 100 / props.daysPerWeek}%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${colors.grey};
        padding: 10px;
        text-transform: capitalize;
    }
`;

const Month = styled.div`
    display: flex;
    flex-wrap: wrap;
    /*padding: 1px 0 0 1px;*/
    width: 100%;
    position: relative;

    .day-wrapper {
        width: calc(${(props) => 100 / props.daysPerWeek}% /* + 1px*/);
        max-width: calc(${(props) => 100 / props.daysPerWeek}% /* + 1px*/);
        &:before {
            content: '';
            float: left;
            padding-top: 150%;
        }
    }

    ${(props) =>
        props.hasMarkedDays
            ? `
        .day-wrapper {
            align-items: flex-start;
            padding-top:10px;
            &:before {
                padding-top: 130%;
            }
        }
    `
            : ``}
`;

const DayWrapper = styled.div`
    border: /*2*/ 1px solid #fff;
    /*margin: -1px 0 0 -1px;*/
    display: flex;
    justify-content: center;
    align-items: space-between;
    padding: 5px;
    font-size: 15px;
    font-family: 'gellix-bold';
    line-height: 1;
    cursor: pointer;
    max-height: 100px;
    background: #fafafa;

    @media (max-width: 767px) {
        font-size: 15px;
    }

    .day {
        text-align: center;
        align-items: space-between;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        .day-num {
            position: relative;
        }
        .pen {
            width: 18px;
            height: 18px;
        }
    }

    &.marked {
        .dot circle {
            fill: ${colors.orange};
        }
    }

    &.hidden {
        opacity: 0;
        visibility: hidden;
    }

    &.passed {
        background: #f1f1f1;
        cursor: default;
        color: ${colors.black};
        cursor: default;
        &.marked .dot circle {
            fill: ${colors.black};
        }
        .pen path {
            fill: #95939f;
        }
    }

    &.today {
        .day-num {
            color: #f2f5fc;
            background: ${colors.black};
            border-radius: 6px;
            display: inline-block;
            padding: 3px 6px 5px;
        }
    }

    &.locked {
        svg {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 8px;
            display: block;
            rect {
                &:first-child {
                    fill: ${colors.black};
                }
                &:last-child {
                    stroke: ${colors.black};
                }
            }
        }
        .dot {
            display: none;
        }
    }

    &.selected {
        background: ${colors.orange};
        color: #fff;
        &.marked .dot circle {
            fill: #fff;
        }
        .pen path {
            fill: #fff;
        }
        &.locked {
            background: #b53000;
            svg {
                rect {
                    &:first-child {
                        fill: #fff;
                    }
                    &:last-child {
                        stroke: #fff;
                    }
                }
            }
        }
        &.passed {
            background: #ffd8bf;
            svg {
                rect {
                    &:first-child {
                        fill: ${colors.grey};
                    }
                    &:last-child {
                        stroke: ${colors.grey};
                    }
                }
            }
        }
        &.passed.locked {
            background: #e5b7a6;
            svg {
                rect {
                    &:first-child {
                        fill: #fff;
                    }
                    &:last-child {
                        stroke: #fff;
                    }
                }
            }
        }
        &.today {
            .day-num {
                background: #fff;
                color: ${colors.black};
            }
        }
    }

    &.deleted {
        background: #f1f1f1;
        cursor: default;
        .day-num {
            color: #8a8895;
        }
        .pen path {
            fill: #8a8895;
        }
        &.marked .dot {
            display: none;
        }
    }
`;
