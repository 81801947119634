import { ui } from '/front';

export type VerticalPadding = '' | '60' | '80' | '100' | '120' | '150' | '180';

interface Props {
    verticalPadding: VerticalPadding;
    onChange: (val: VerticalPadding) => void;
}

export default function VerticalPaddingSetting({
    verticalPadding,
    onChange,
}: Props) {
    const { Select } = ui();
    return (
        <Select
            value={verticalPadding}
            options={[
                { name: 'None', value: '' },
                { name: '60px', value: '60' },
                { name: '80px', value: '80' },
                { name: '100px', value: '100' },
                { name: '120px', value: '120' },
                { name: '150px', value: '150' },
                { name: '180px', value: '180' },
            ]}
            onChange={onChange}
            medium
            style={{ width: '100%' }}
            label="Vertical padding"
        />
    );
}
