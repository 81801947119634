import PopularJobsModule from './PopularJobsModule';
import PopularJobsModuleSettings from './PopularJobsModuleSettings';

export default {
    _key: '',
    _type: 'popular_jobs',
    title: 'Popular jobs',
    description: 'Popular jobs',
    image: '/modules/popularJobs.svg',
    preview: '/modules/popularJobs-preview.png',
    moduleComponent: PopularJobsModule,
    settingsComponent: PopularJobsModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
        trackingId: 'Popular job list',
    },
};
