import { memo } from 'react';
import Container from '/components/Container';

import { RichText } from '/front';
import styled, { css } from 'styled-components';
import colors from '/css/colors';
import { RichTextModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import { containerWidthForSize } from '/components/Container';

export default memo(function RichTextModule({
    module,
    onChange,
}: ModuleProps<RichTextModuleType>) {
    const {
        margin,
        textAlignment,
        containerWidth,
        textStyle,
        content,
        verticalPadding,
        backgroundColor,
    } = module.data;
    return (
        <div
            style={{ backgroundColor: backgroundColor || 'transparent' }}
            className={`pt-${verticalPadding} pb-${verticalPadding} ${margin}`}
        >
            <StyledContainer alignment={textAlignment} size={containerWidth}>
                <RichText
                    className={`rich-text ${textStyle === 'terms' && 'small'}`}
                    value={content}
                    onChange={(content) =>
                        onChange({ data: { ...module.data, content } })
                    }
                    maxWidth={containerWidthForSize(containerWidth)}
                />
            </StyledContainer>
        </div>
    );
});

const StyledContainer = styled(Container)<{
    alignment: RichTextModuleType['data']['textAlignment'];
}>`
    .rich-text.small {
        p,
        li {
            font-family: 'gellix-medium';
            color: ${colors.grey};
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
            font-family: 'gellix-medium';
            margin-top: 1.3em;
        }
    }

    ${(p) =>
        p.alignment === 'center' &&
        css`
            text-align: center;
        `}

    ${(p) =>
        p.alignment === 'right' &&
        css`
            text-align: right;
        `}
`;
