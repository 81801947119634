import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import useWindowWidth from '/hooks/useWindowWidth';
import useScreenSize from '/hooks/useScreenSize';
import Icon from '/components/ui/Icon';
import arrayValue from '/lib/utils/arrayValue';

export default function ItemCarousel({
    className,
    children,
    startIndex = 0,
    onStage = [3, 3, 3],
    itemPixelGap = 24,
    onChange = (newIndex) => {},
}) {
    const [index, setIndex] = useState(startIndex);
    const [translateX, setTranslateX] = useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => move('NEXT'),
        onSwipedRight: () => move('PREV'),
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
    });

    const wrapperRef = useRef();

    const wrapperRefPassthrough = (el) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);
        // set wrapperRef el so we can access it
        wrapperRef.current = el;
    };

    const windowWidth = useWindowWidth();
    const screenSize = useScreenSize();

    const items = Array.isArray(children) ? children : [children];

    const itemsOnStage = (() => {
        const _onStage = arrayValue(onStage);
        if (screenSize.large) return _onStage[0];
        if (screenSize.medium) return _onStage[1] || _onStage[0];
        if (screenSize.small) return _onStage[2] || _onStage[1] || _onStage[0];
    })();

    const move = (dir) => {
        const newIndex = dir === 'PREV' ? index - 1 : index + 1;

        if (newIndex < 0 || newIndex + itemsOnStage > items.length) return;

        setIndex(newIndex);
        onChange(newIndex);
    };

    useEffect(() => {
        setIndex(startIndex);
    }, [startIndex]);

    useEffect(() => {
        const firstChild = ([...(wrapperRef?.current?.children || [])][0]
            .children || [])[0];

        if (!firstChild) return;

        const firstChildBCR = firstChild.getBoundingClientRect();
        const firstChildMarginRight = Number(
            getComputedStyle(firstChild).marginRight.replace(/\D/g, '')
        );

        setTranslateX(
            index * (firstChildBCR.width + firstChildMarginRight * 2) * -1
        );
    }, [index, windowWidth, children, onStage]);

    return (
        <Wrapper
            className={[className, 'item-carousel'].filter((c) => c).join(' ')}
            itemPixelGap={itemPixelGap}
            itemsOnStage={arrayValue(onStage)}
            ref={wrapperRefPassthrough}
        >
            <div
                {...handlers}
                className="row"
                style={{
                    transform: `translateX(${translateX}px)`,
                }}
            >
                {items.map((item, i) => (
                    <div className="item" key={i}>
                        {item}
                    </div>
                ))}
            </div>
            {index > 0 && (
                <div
                    role="button"
                    className="arrow arrow-left"
                    onClick={() => move('PREV')}
                >
                    <Icon icon="arrow-left" />
                </div>
            )}
            {index + itemsOnStage < items.length && (
                <div
                    role="button"
                    className="arrow arrow-right"
                    onClick={() => move('NEXT')}
                >
                    <Icon icon="arrow-right" />
                </div>
            )}
        </Wrapper>
    );
}

const widthValue = (itemsOnStage, itemGap) => {
    if (itemsOnStage === 1) return `calc(90% - ${itemGap}px)`;
    return `calc(${100 / itemsOnStage}% - ${
        (itemsOnStage * itemGap) / itemsOnStage
    }px)`;
};

const widthStyles = ({ itemsOnStage, itemPixelGap }) => {
    const onStageLarge = itemsOnStage[0];
    const onStageMedium = itemsOnStage[1] || itemsOnStage[0];
    const onStageSmall = itemsOnStage[2] || itemsOnStage[1] || itemsOnStage[0];
    return `
        min-width: ${widthValue(onStageLarge, itemPixelGap)};
        width: ${widthValue(onStageLarge, itemPixelGap)};
        @media (min-width: 768px) and (max-width: 1024px) {
            min-width: ${widthValue(onStageMedium, itemPixelGap)};
            width: ${widthValue(onStageMedium, itemPixelGap)};
        }
        @media(max-width: 767px) {
            min-width: ${widthValue(onStageSmall, itemPixelGap)};
            width: ${widthValue(onStageSmall, itemPixelGap)};
        }
    `;
};

const Wrapper = styled.div`
    position: relative;
    .row {
        margin: 0 -${(props) => props.itemPixelGap / 2}px;
        transition: transform 0.3s ease-in-out;
        cursor: grab;
        display: flex;
        .item {
            user-select: none;
            margin: 0 ${(props) => props.itemPixelGap / 2}px;
            ${widthStyles};
        }
    }
    .arrow {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        background: rgba(0, 0, 0, 0.6);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.arrow-right {
            right: -${(props) => props.itemPixelGap + 56}px;
        }
        &.arrow-left {
            left: -${(props) => props.itemPixelGap + 56}px;
        }
        @media (max-width: 1280px) {
            &.arrow-right {
                right: -${56}px;
            }
            &.arrow-left {
                left: -${56}px;
            }
        }
        @media (max-width: 1240px) {
            &.arrow-right {
                right: 0px;
            }
            &.arrow-left {
                left: 0px;
            }
        }
        @media (max-width: 767px) {
            display: none;
        }
        svg {
            min-width: 24px;
            min-height: 24px;
            path {
                fill: #fff;
            }
        }
        @media (pointer: fine) {
            &:hover {
                background: rgba(0, 0, 0, 0.7);
            }
        }
    }
`;
