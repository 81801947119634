import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ModuleTemplateType } from './types';
import { ModuleProps, SimpleModuleType } from '/front/types/frontModuleType';
import config from '/front/config';
import ModuleTemplate from '/front/types/frontModuleTemplate';
import { ui } from '/front';
import moduleMap from '/components/modules';
import Paragraph from '/components/ui/Paragraph';
import Heading from '/components/ui/Heading';
import Button from '/components/ui/buttons/Button';

export default memo(function HeadingModule({
    module: parentModule,
}: ModuleProps<ModuleTemplateType>) {
    const { templateId, modules } = parentModule.data;

    const { editMode } = ui();

    const [hydratedModules, setHydratedModules] =
        useState<SimpleModuleType[]>(modules);
    const [templateInfo, setTemplateInfo] = useState<ModuleTemplate>();

    useEffect(() => {
        if (editMode) hydrate();
    }, []);

    const hydrate = async () => {
        const resp = await config.api.page.renderModule({
            ...parentModule,
            data: {
                templateId: parentModule.data.templateId,
            },
        });
        if ('error' in resp) return console.log(resp.error);
        setHydratedModules(resp.data.modules);
        setTemplateInfo(resp.data.template);
    };

    return (
        <Wrapper>
            {hydratedModules.map((childModule, i) => {
                const ModuleComponent =
                    moduleMap[childModule._type].moduleComponent;
                const id = `template-${templateId}-${parentModule._key}-module-${childModule._key}`;
                return (
                    <div
                        key={id}
                        id={id}
                        className={`ft-module module-${childModule._type} module-index-${i}`}
                    >
                        <ModuleComponent
                            module={childModule}
                            onChange={() => {}}
                            addAction={() => {}}
                            createdAt=""
                        />
                    </div>
                );
            })}
            {editMode && (
                <div className="template-overlay">
                    <div className="info-box p-48">
                        <Heading large centered className="mb-48">
                            Denne seksjonen tilhører en mal
                        </Heading>
                        <Paragraph medium centered className="mb-48">
                            En mal består av en eller flere moduler som kan
                            gjenbrukes på flere sider. For å gjøre endringer på
                            innholdet i disse modulene må du endre på malen.
                        </Paragraph>
                        <Button
                            variant="secondary"
                            link={{
                                href: `/module-templates/${templateInfo?.id}`,
                                target: '_blank',
                            }}
                        >
                            Gå til mal
                        </Button>
                    </div>
                </div>
            )}
        </Wrapper>
    );
});

const Wrapper = styled.div`
    position: relative;
    .ft-module {
        position: relative;
    }
    .template-overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 19;
        transition: all 0.3s ease-in-out;
        .info-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            width: 800px;
            max-width: 100%;
            text-align: center;
            border-radius: 12px;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            @media (max-width: 848px) {
                width: calc(100% - 48px);
            }
        }
        @media (pointer: fine) {
            &:hover {
                background: rgba(0, 0, 0, 0.25);
                .info-box {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
`;
