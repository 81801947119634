import api from './api';

const contactApi = {
    requestCallback: (
        name: string,
        phoneNumber: string,
        type: 'solar' | 'regular',
        description?: string
    ) =>
        api.post('contact/callback', {
            name,
            phone_number: phoneNumber,
            description,
            type,
        }),
};

export default contactApi;
