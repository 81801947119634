import reactToast from 'react-hot-toast';
import styled from 'styled-components';

import { colors } from '/css';
import { truncate } from '/lib/helpers';
import Icon from '/components/ui/Icon';

const style = {
    minWidth: 'calc(100% + 32px)',
    zIndex: `9999`,
    top: '-16px',
    position: 'relative',
    borderRadius: '0',
    justifyContent: 'center',
    background: colors.green,
    padding: '2px 24px',
    boxShadow: 'none',
};

export function success(message, config = {}) {
    if (!message) return null;

    return reactToast.success(
        (t) => {
            return (
                <Wrapper className="pt-16 pb-16">
                    <div className="message success">
                        {truncate(message, 500)}
                    </div>
                    <Icon
                        icon="close"
                        onClick={() => reactToast.dismiss(t.id)}
                    />
                </Wrapper>
            );
        },
        {
            duration: 3000,
            style: { ...style, background: colors.green },
            className: 'spoton-toast',
            icon: null,
            ...config,
        }
    );
}

export function error(message, config = {}) {
    if (!message) return null;

    return reactToast.error(
        (t) => {
            return (
                <Wrapper className="pt-16 pb-16">
                    <div className="message error">
                        {truncate(message, 500)}
                    </div>
                    <Icon
                        icon="close"
                        onClick={() => reactToast.dismiss(t.id)}
                    />
                </Wrapper>
            );
        },
        {
            duration: 5000,
            style: { ...style, background: colors.orange },
            className: 'spoton-toast',
            icon: null,
            ...config,
        }
    );
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    z-index: 9999;
    justify-content: space-between;
    svg {
        min-width: 24px;
    }
    .message {
        font-size: 17px;
        color: ${colors.black};
        font-family: 'gellix-semi';
        &.error {
            color: ${colors.black};
        }
    }
`;
