export default function InstallationIllustration() {
    return (
        <svg
            width="340"
            height="287"
            viewBox="0 0 340 287"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M298.501 236.2C299.067 236.366 299.71 236.523 300.219 236.225C300.636 235.982 300.841 235.5 301.013 235.049C304.766 225.198 305.573 214.509 306.342 203.995C306.999 195.024 307.656 186.043 307.622 177.049C307.571 163.46 305.944 149.928 305.318 136.353C305.022 129.919 304.942 123.416 303.421 117.158C302.548 113.564 301.208 110.097 300.341 106.502C299.383 102.528 299.012 98.4402 298.644 94.3692C298.465 92.3847 298.285 90.4001 298.105 88.4158C298.04 87.6901 297.948 86.9037 297.44 86.3808C297.105 86.0345 296.635 85.8584 296.182 85.6929C285.422 81.7595 274.662 77.8259 263.902 73.8926C264.01 112.131 261.792 150.377 257.264 188.346C257.152 189.288 257.072 190.351 257.686 191.073C258.03 191.48 258.539 191.703 259.029 191.912C262.122 193.234 265.215 194.555 268.308 195.877C269.182 196.251 270.097 196.655 270.673 197.412C271.292 198.226 271.412 199.303 271.504 200.322C271.93 205.054 272.235 209.796 272.419 214.544C272.536 217.602 271.244 224.491 272.796 227.116C274.362 229.764 281.751 230.905 284.582 231.847C289.195 233.381 293.836 234.833 298.501 236.2"
                fill="#FFAA80"
            />
            <mask
                id="mask0_4578_9178"
                style={{ maskType: 'luminance' }}
                maskUnits="userSpaceOnUse"
                x="262"
                y="86"
                width="78"
                height="151"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M262.556 86.2559H339.257V236.514H262.556V86.2559Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_4578_9178)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M263.184 97.5792C275.056 94.2626 286.799 90.4842 298.379 86.2559C312.662 129.827 326.29 173.613 339.257 217.594C326.327 224.177 313.258 230.485 300.061 236.514C287.532 198.774 275.03 161.025 262.556 123.268"
                    fill="#A093FF"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M299.812 236.637C299.444 236.637 299.101 236.404 298.979 236.036C286.415 198.191 274.229 161.394 261.723 123.542C261.571 123.082 261.821 122.585 262.281 122.433C262.741 122.281 263.237 122.531 263.389 122.991C275.894 160.842 288.08 197.638 300.644 235.483C300.797 235.942 300.548 236.439 300.088 236.592C299.996 236.622 299.903 236.637 299.812 236.637"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M263.987 98.1968C263.603 98.1968 263.251 97.9429 263.143 97.5551C263.013 97.0883 263.285 96.6043 263.752 96.4741C275.594 93.166 286.025 89.81 297.574 85.5924C298.029 85.4264 298.532 85.6608 298.699 86.1159C298.865 86.571 298.631 87.0748 298.175 87.2411C286.582 91.4746 276.111 94.8435 264.224 98.1642C264.145 98.1862 264.065 98.1968 263.987 98.1968"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M213.527 198.023C213.045 198.023 212.653 197.634 212.65 197.151C212.356 142.301 211.581 81.8909 210.468 27.0498C210.458 26.5652 210.843 26.1646 211.327 26.1546C211.809 26.1416 212.212 26.5296 212.222 27.0139C213.335 81.8637 214.11 142.283 214.404 197.141C214.407 197.626 214.016 198.021 213.532 198.023H213.527Z"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M195.792 2.55601C196.733 14.7798 195.893 27.1959 198.041 39.266C200.755 34.581 204.495 29.2787 207.065 24.6324L215.114 38.7259C216.643 33.6006 218.355 28.5297 220.245 23.5263C223.254 27.8974 226.267 32.8271 229.276 37.1982C229.293 25.6411 229.268 14.0841 229.201 2.52734"
                fill="#C3F2EE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.1173 175.239C39.1017 176.019 41.0845 176.801 43.0658 177.581C79.1721 191.803 113.28 205.237 149.772 215.796C186.023 203.689 221.822 194.658 256.183 188.951C263.547 121.422 263.982 66.2462 262.338 4.37266C193.361 1.88996 147.749 1.45827 76.2043 5.65856C74.918 5.73422 72.6556 5.85784 69.7912 6.01421C60.0004 6.54885 42.1745 7.5224 36.3871 8.07712C35.7281 63.5461 35.9737 119.776 37.1173 175.239M149.791 217.592C149.709 217.592 149.627 217.58 149.548 217.557C112.878 206.965 77.0608 192.857 42.4232 179.214C40.2609 178.362 38.0971 177.51 35.9314 176.657C35.602 176.528 35.3829 176.213 35.3755 175.859C34.2157 119.929 33.9691 63.2088 34.6424 7.27399C34.6478 6.83118 34.9817 6.46169 35.4215 6.41256C40.2409 5.87051 58.6791 4.86362 69.6955 4.26191C72.5579 4.10553 74.8186 3.98211 76.1014 3.90685C148.001 -0.314314 193.728 0.137849 263.225 2.64863C263.688 2.6654 264.058 3.03898 264.07 3.50187C265.746 65.983 265.331 121.576 257.854 189.803C257.811 190.195 257.513 190.51 257.125 190.574C222.572 196.273 186.554 205.348 150.069 217.546C149.979 217.577 149.885 217.592 149.791 217.592"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M149.791 217.591H149.786C149.301 217.588 148.911 217.193 148.914 216.708C149.112 183.683 148.791 146.535 148.48 110.611C148.161 73.7628 147.831 35.6609 148.058 1.81883C148.062 1.33625 148.454 0.947266 148.935 0.947266H148.942C149.426 0.95058 149.816 1.346 149.813 1.83073C149.585 35.6591 149.915 73.7544 150.234 110.595C150.545 146.526 150.867 183.681 150.668 216.719C150.665 217.202 150.273 217.591 149.791 217.591"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.9233 145.022C85.9757 145.022 81.0017 143.93 76.4219 141.741C68.9046 138.149 62.7167 130.988 59.4446 122.094C56.6814 114.582 55.4622 105.979 55.9189 97.215C56.3697 88.5629 58.4281 80.1447 61.8711 72.8701C63.9891 68.3951 66.6802 64.6364 69.8693 61.6987C73.6846 58.1845 78.1111 55.9268 83.0254 54.9886C92.531 53.1737 100.808 54.2893 110.304 65.6309L99.8442 74.3947C93.8403 67.2242 90.6699 67.4239 85.5831 68.3955C79.679 69.5225 76.1359 74.6269 74.2031 78.7104C68.673 90.3947 67.9062 105.574 72.2496 117.38C74.3035 122.964 77.968 127.354 82.303 129.426C87.8212 132.062 94.2656 132.034 99.5425 129.349C103.766 127.201 107.124 123.57 109.255 118.85C111.315 114.287 112.159 108.836 111.631 103.503C111.381 100.976 111.131 98.6434 110.809 96.4393C105.499 103.536 100.226 110.167 95.1858 116.415C93.8329 118.092 91.7663 119.031 89.6152 118.949C87.4625 118.866 85.4753 117.771 84.2549 115.996L72.7769 99.2958L84.02 91.5632L90.2927 100.689C96.0713 93.3947 102.037 85.5943 107.884 77.2011C109.275 75.2052 111.622 74.102 114.047 74.3021C116.471 74.5039 118.604 75.9811 119.646 78.1799C123.374 86.0468 124.371 93.6934 125.209 102.158C125.98 109.946 124.763 117.661 121.69 124.467C118.268 132.049 112.748 137.944 105.727 141.515C101.135 143.852 96.0431 145.022 90.9233 145.022"
                fill="#FF6400"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M113.285 234.908L117.427 284.454C122.309 283.404 127.313 282.293 132.161 281.216C137.336 280.067 142.686 278.878 147.881 277.766C157.607 275.684 167.664 273.531 177.57 271.325C208.699 264.393 241.717 255.36 272.906 246.681L270.795 198.218C217.2 211.849 165.659 223.855 113.285 234.908M116.636 286.398C116.449 286.398 116.266 286.339 116.114 286.225C115.911 286.075 115.783 285.845 115.762 285.594L111.472 234.283C111.435 233.842 111.732 233.442 112.165 233.351C165.115 222.187 217.204 210.052 271.408 196.252C271.664 196.187 271.935 196.24 272.147 196.398C272.359 196.555 272.489 196.8 272.5 197.064L274.689 247.301C274.707 247.709 274.441 248.075 274.048 248.185C242.639 256.927 209.346 266.047 177.951 273.038C168.038 275.245 157.978 277.399 148.248 279.482C143.06 280.593 137.712 281.781 132.541 282.929C127.366 284.078 122.015 285.266 116.82 286.379C116.759 286.392 116.697 286.398 116.636 286.398"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M116.635 286.399C116.498 286.399 116.359 286.367 116.229 286.299C82.3331 268.564 43.1666 247.904 7.3043 227.144C6.35933 226.598 5.18299 225.917 4.47075 224.771C3.75676 223.623 3.66749 222.261 3.59596 221.166L0.666119 176.437C0.62733 175.845 0.568659 174.949 1.07448 174.197C1.66645 173.316 2.73578 173.077 3.37472 172.934C15.1054 170.315 26.0936 167 35.1513 163.349C35.6006 163.168 36.1117 163.385 36.2927 163.835C36.4738 164.284 36.2563 164.795 35.807 164.977C26.6622 168.663 15.5794 172.007 3.75715 174.647C3.23828 174.763 2.71609 174.899 2.53014 175.176C2.37147 175.412 2.38589 175.853 2.4165 176.322L5.34633 221.052C5.40832 221.997 5.47849 223.069 5.96052 223.844C6.4412 224.617 7.36648 225.153 8.183 225.626C44.0155 246.368 83.1623 267.017 117.042 284.744C117.472 284.968 117.638 285.499 117.413 285.928C117.257 286.227 116.951 286.399 116.635 286.399"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M113.161 234.852C113.022 234.852 112.881 234.819 112.75 234.75L1.33163 175.656C0.903583 175.43 0.74063 174.899 0.967711 174.47C1.1946 174.042 1.72556 173.879 2.15341 174.106L113.572 233.199C114 233.426 114.163 233.957 113.936 234.385C113.779 234.683 113.475 234.852 113.161 234.852"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M271.623 197.98C271.497 197.98 271.368 197.952 271.245 197.894C267.526 196.114 264.046 194.319 260.976 192.735C259.393 191.918 257.899 191.147 256.59 190.493C256.157 190.276 255.981 189.748 256.198 189.315C256.415 188.882 256.942 188.706 257.375 188.923C258.693 189.583 260.193 190.356 261.78 191.175C264.839 192.753 268.306 194.542 272.003 196.311C272.44 196.52 272.624 197.044 272.415 197.481C272.265 197.796 271.951 197.98 271.623 197.98"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M114.809 262.235C114.433 262.235 114.085 261.99 113.97 261.612C113.829 261.148 114.091 260.658 114.555 260.517C134.708 254.402 155.547 248.078 176.45 243.313C186.113 241.111 196.021 239.188 205.602 237.329C227.423 233.094 249.986 228.715 271.055 220.746C271.508 220.575 272.014 220.803 272.185 221.257C272.357 221.71 272.128 222.216 271.675 222.388C250.467 230.409 227.829 234.803 205.936 239.051C196.369 240.908 186.475 242.828 176.84 245.024C155.997 249.775 135.188 256.09 115.064 262.197C114.979 262.222 114.894 262.235 114.809 262.235"
                fill="#111112"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M322.279 225.134C321.923 225.134 321.589 224.916 321.457 224.564C305.472 181.826 290.65 138.017 277.403 94.3515C277.263 93.8879 277.524 93.3979 277.988 93.2571C278.451 93.1155 278.941 93.3784 279.082 93.842C292.318 137.472 307.128 181.246 323.1 223.949C323.27 224.403 323.04 224.908 322.586 225.078C322.485 225.116 322.381 225.134 322.279 225.134"
                fill="#111112"
            />
        </svg>
    );
}
