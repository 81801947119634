import QuoteModule from './QuoteModule';
import QuoteModuleSettings from './QuoteModuleSettings';
import { ContainerSizes } from '/components/Container';
import { QuoteModuleType } from './types';

const moduleData: QuoteModuleType = {
    _key: '',
    _type: 'quote',
    title: 'Quote',
    description: 'Quote with colored background',
    image: '/modules/quote.svg',
    preview: '/modules/quote-preview.png',
    moduleComponent: QuoteModule,
    settingsComponent: QuoteModuleSettings,
    data: {
        text: '',
        margin: 'mb-60',
        backgroundColor: '#EBF9F8',
        author: '',
        theme: 'minimal',
        containerWidth: ContainerSizes.LARGE,
    },
};

export default moduleData;
