import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';
import { QuoteModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function QuoteModuleSettings({
    module,
    onChange,
}: ModuleProps<QuoteModuleType>) {
    const { Select, ColorPicker } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, backgroundColor, theme, containerWidth } = module.data;

    return (
        <>
            <Row>
                <Col width={[6, 12]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6, 12]} className="mb-24">
                    <Select
                        value={theme || 'bold'}
                        options={['bold', 'minimal']}
                        onChange={change('theme')}
                        medium
                        style={{ width: '100%' }}
                        label="Theme"
                    />
                </Col>
                {theme !== 'minimal' && (
                    <Col width={[6, 12]} className="mb-24">
                        <ColorPicker
                            value={backgroundColor}
                            onChange={change('backgroundColor')}
                            medium
                            style={{ width: '100%' }}
                            label="Background color"
                        />
                    </Col>
                )}
                {theme === 'minimal' && (
                    <Col width={[6, 12]}>
                        <Select
                            value={containerWidth || 'default'}
                            options={['default', 'medium', 'small']}
                            onChange={change('containerWidth')}
                            medium
                            style={{ width: '100%' }}
                            label="Container size"
                        />
                    </Col>
                )}
            </Row>
        </>
    );
}
