import { useEffect } from 'react';
import styled from 'styled-components';

import { colors } from '/css';
import { goTo } from '/lib/helpers';
import Layout from '/components/Layout';
import Button from '../components/ui/buttons/Button';
import Heading from '/components/ui/Heading';
import Paragraph from '/components/ui/Paragraph';
import useMenus from '/hooks/useMenus';

const Error = ({ heading = '', message = '' }) => {
    const menus = useMenus();

    useEffect(() => {
        // temp. fix for broken 404 page when there are query parameters
        //if (window.location.href.includes('?')) {
        //Router.replace(window.location.pathname);
        //return;
        //}

        if (window.ga)
            window.ga('send', {
                hitType: 'event',
                eventCategory: '404 Response',
                eventAction: window.location.href,
                eventLabel: document.referrer,
            });
    }, []);

    return (
        <Layout menus={menus}>
            <Wrapper className="pt-120 pb-120">
                <Heading xxl centered className="mb-48">
                    {heading || (
                        <>
                            SpotOooo
                            <br />
                            ooops.
                        </>
                    )}
                </Heading>
                <div>
                    <Paragraph large centered className="mb-60">
                        {message ||
                            'Vi finner ikke siden du leter etter! Det kan være at den ikke eksisterer lenger eller at den har byttet adresse.'}
                    </Paragraph>
                    <div className="flex justify-center wrap children-full-width-on-mobile">
                        <Button
                            className="mr-8 mw-220"
                            onClick={() => goTo('/')}
                        >
                            Gå til forside
                        </Button>
                        <Button
                            variant="tertiary"
                            className="ml-8 mw-220"
                            onClick={() => goTo('/kontakt-oss')}
                        >
                            Kontakt oss
                        </Button>
                    </div>
                </div>
            </Wrapper>
        </Layout>
    );
};

export default Error;

const Wrapper = styled.div`
    text-align: center;
    min-height: calc(100vh - 64px);
    background: ${colors.orange};
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    h1,
    p,
    a,
    button {
        color: #fff !important;
    }
    h1 {
        @media (max-width: 767px) {
            font-size: 42px;
            line-height: 48px;
        }
    }
    > div {
        max-width: 560px;
        margin: 0 auto;
        padding: 0 24px;
    }
`;
