export default function Arrow() {
    return (
        <svg
            width="59"
            height="78"
            viewBox="0 0 59 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="arrow"
        >
            <path
                d="M0.0779759 67.8205C-0.297324 69.4342 0.70667 71.0467 2.32046 71.422L28.6187 77.5379C30.2325 77.9132 31.8449 76.9092 32.2202 75.2954C32.5955 73.6816 31.5915 72.0691 29.9778 71.6938L6.60156 66.2575L12.0379 42.8813C12.4132 41.2675 11.4092 39.6551 9.7954 39.2798C8.18162 38.9045 6.56914 39.9085 6.19384 41.5222L0.0779759 67.8205ZM52.4365 0C52.4365 31.1547 28.0695 49.3567 1.41432 65.9533L4.58568 71.0467C30.9305 54.6433 58.4365 34.8453 58.4365 0H52.4365Z"
                fill="#111112"
            />
        </svg>
    );
}
