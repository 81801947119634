import curry from 'ramda/src/curry';

import { Row, Col } from '/front';
import { SolarHeroModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';

export default function SolarHeroModuleSettings({
    module,
    onChange,
}: ModuleProps<SolarHeroModuleType>) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin } = module.data;

    return (
        <Row>
            <Col width={[12]} className="mb-24">
                <MarginSetting margin={margin} onChange={change('margin')} />
            </Col>
        </Row>
    );
}
