import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

export default function useWindowWidth(delay = 500) {
    const initialValue = 1024; //typeof window === 'undefined' ? 1024 : window.innerWidth;

    const [width, setWidth] = useState(initialValue);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);

        const debouncedHandleResize = debounce(handleResize, delay);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, [delay]);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    return width;
}
