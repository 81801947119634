import curry from 'ramda/src/curry';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ui, Row, Col, error, config } from '/front';
import { sortByPath } from '/lib/helpers';
import jobTypeApi from '/api/jobType';
import useFirstRender from '/hooks/useFirstRender';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import { JobModuleType } from './types';
import { JobTemplate } from '/types/jobTemplateTypes';
import xor from 'lodash.xor';

export default function JobModuleSettings({
    module,
    onChange,
}: Readonly<ModuleProps<JobModuleType>>) {
    const { ColorPicker, Label, Select, Button } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        background,
        backgroundSecondary,
        margin,
        jobId,
        job,
        selectedProducts,
        textColor,
    } = module.data;

    const [jobs, setJobs] = useState<JobTemplate[]>([]);

    const products = job?.products;

    const firstRender = useFirstRender();

    const productsCount = (products || []).length;
    const selectedProductsCount = Object.keys(selectedProducts || {}).length;
    const removedProductsCount = productsCount - selectedProductsCount;

    // fetch job choices
    useEffect(() => {
        (async () => {
            const jobTypeResp = await jobTypeApi.index();
            if ('error' in jobTypeResp) return error(jobTypeResp.error);
            setJobs(
                sortByPath(
                    jobTypeResp.data.filter((job) => job.published),
                    'order',
                    true
                )
            );
        })();
    }, []);

    useEffect(() => {
        if (!jobId) return;
        fetchJobInfo({
            resetSelectedProducts: !firstRender,
        });
    }, [jobId]);

    const fetchJobInfo = async ({
        resetSelectedProducts = false,
    }: {
        resetSelectedProducts?: boolean;
    }) => {
        const renderResp = await config.api.page.renderModule(module);
        if ('error' in renderResp) return;
        onChange({
            data: {
                ...module.data,
                job: renderResp.data.job,
                selectedProducts: resetSelectedProducts ? [] : selectedProducts,
            },
        });
    };

    return (
        <>
            <Row className="mb-24">
                <Col width={12}>
                    <Select
                        placeholder="Select job"
                        medium
                        value={jobId}
                        onChange={change('jobId')}
                        options={jobs.map((j) => ({
                            name: j.jobTypeVersion.name,
                            value: j.jobTypeId,
                        }))}
                        searchable
                        style={{ width: '100%' }}
                    />
                </Col>
                {job && (
                    <Col width={12} className="mb-24">
                        <Label className="mt-12 mb-8">
                            Click to select / deselect products visible on job
                            module
                        </Label>
                        <ProductList>
                            {job.products.map((p, i) => (
                                <li key={i + '-' + p.productPage}>
                                    <div
                                        className={
                                            selectedProducts.includes(
                                                p.productPage
                                            )
                                                ? 'selected'
                                                : 'removed'
                                        }
                                        onClick={() => {
                                            onChange({
                                                data: {
                                                    ...module.data,
                                                    selectedProducts: xor(
                                                        selectedProducts,
                                                        [p.productPage]
                                                    ),
                                                },
                                            });
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundImage: `url(${p.imageUrl}?auto=format&w=170)`,
                                            }}
                                        />
                                        <span>{p.title}</span>
                                    </div>
                                </li>
                            ))}
                        </ProductList>
                    </Col>
                )}
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ColorPicker
                        value={background}
                        onChange={change('background')}
                        medium
                        style={{ width: '100%' }}
                        label="Background"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ColorPicker
                        value={backgroundSecondary}
                        onChange={change('backgroundSecondary')}
                        medium
                        style={{ width: '100%' }}
                        label="Background (secondary)"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={textColor}
                        options={['black', 'white']}
                        onChange={change('textColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Text color"
                    />
                </Col>
            </Row>
        </>
    );
}

const ProductList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    li {
        width: 20%;
        padding: 4px;
        > div {
            border: 2px solid #5847bf;
            height: 100%;
            padding: 8px 8px 4px;
            border-radius: 8px;
            font-size: 13px;
            text-align: center;
            cursor: pointer;
            > div {
                height: 65px;
                width: 85px;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                margin-bottom: 8px;
                background-color: #fff;
                border-radius: 4px;
            }
            &.removed {
                opacity: 0.5;
                border: 2px solid #7e7e7e;
            }
        }
    }
`;
