import CardsModule from './CardsModule';
import CardsModuleSettings from './CardsModuleSettings';

export default {
    _key: '',
    _type: 'cards',
    title: 'Cards',
    description: 'Cards with icons shown as grid or list',
    image: '/modules/cards.svg',
    preview: '/modules/cards-preview.png',
    moduleComponent: CardsModule,
    settingsComponent: CardsModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
        iconType: 'spoton-logo',
        iconColor: '#FF6400',
        columnCount: '2',
    },
};
