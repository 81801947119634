import { memo, useState } from 'react';

import AddressSearch from '/modules/solar/components/search/AddressSearch';
import AddressSearchResult from '/modules/solar/types/AddressSearchResults';
import { goTo } from '/lib/helpers';
import { useSolarJobLocationState } from '/modules/solar/state/solarJobLocationState';
import { SOLAR_PAGES } from '/modules/solar/solarConstants';
import styled from 'styled-components';
import notProduction from '/lib/utils/notProduction';
import Switch from '/components/ui/Switch';
import { dummyAddress } from '/modules/solar/solarDummyData';
import { mobile } from '/css/breakpoints';
import { SolarJobEntryModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';

export default memo(function SolarJobEntryModule({
    module,
}: ModuleProps<SolarJobEntryModuleType>) {
    const setAddress = useSolarJobLocationState((state) => state.setAddress);

    const onSelectAddress = (address: AddressSearchResult) => {
        setAddress(address);
        goTo(SOLAR_PAGES.configure);
    };

    const [enableDummyData, setEnableDummyData] = useState(false);

    const toggleDummyData = (enabled: boolean) => {
        setEnableDummyData(enabled);
        setEnableDummyData(true);
        setAddress(dummyAddress);
        goTo(SOLAR_PAGES.configure);
    };

    return (
        <Component className={`${module.data.margin} flex justify-center`}>
            <AddressSearch onSelectAddress={onSelectAddress} />
            {notProduction && (
                <div className="dummy-data-switch">
                    <span>Static?</span>
                    <Switch on={enableDummyData} onChange={toggleDummyData} />
                </div>
            )}
        </Component>
    );
});

const Component = styled.div`
    .dummy-data-switch {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    ${mobile} {
        padding: 0 24px;
    }
`;
