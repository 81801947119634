import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { colors } from '/css';

interface Props extends HTMLAttributes<SVGElement> {
    size?: number;
    color?: string;
    direction?: 'up' | 'down' | 'left' | 'right';
}

const ArrowIcon = ({
    size = 32,
    color = colors.purple,
    direction = 'down',
    ...props
}: Props) => (
    <StyledSvg
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        className="icon"
        direction={direction}
        color={color}
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.66681 13.174L5.83838 16.0025L16.5859 26.75L27.3335 16.0025L24.505 13.174L18.5859 19.0935V5.25491H14.5859L14.5859 19.0935L8.66681 13.174Z"
            fill="currentColor"
        />
    </StyledSvg>
);

const StyledSvg = styled.svg<{ direction: Props['direction']; color: string }>`
    color: ${(p) => p.color};
    ${(p) =>
        p.direction === 'left' &&
        css`
            transform: rotate(90deg);
        `};

    ${(p) =>
        p.direction === 'right' &&
        css`
            transform: rotate(-90deg);
        `};

    ${(p) =>
        p.direction === 'up' &&
        css`
            transform: rotate(180deg);
        `};
`;

export default ArrowIcon;
