import { useEffect, useRef, ReactNode } from 'react';

interface Props {
    children: ReactNode;
    onVisible: (revealCount: number) => void;
    threshold?: number;
}
export default function ScrollObserver({
    children,
    onVisible,
    threshold = 0.5,
}: Props) {
    const targetRef = useRef(null);

    const visibleCountRef = useRef(0);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        visibleCountRef.current += 1;
                        onVisible(visibleCountRef.current);
                    }
                });
            },
            { threshold } // Adjust the threshold as needed
        );

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        return () => {
            if (targetRef.current) {
                observer.unobserve(targetRef.current);
            }
        };
    }, [onVisible]);

    return <div ref={targetRef}>{children}</div>;
}
