import ModuleTemplate from './ModuleTemplate';
import { ModuleTemplateType } from './types';

const moduleData: ModuleTemplateType = {
    _key: '',
    _type: 'module_template',
    title: 'Module template',
    description: '',
    image: '/modules/template.svg',
    moduleComponent: ModuleTemplate,
    data: {
        templateId: 0,
        modules: [],
    },
};

export default moduleData;
