import SolarJobEntryModule from './SolarJobEntryModule';
import SolarJobEntryModuleSettings from './SolarJobEntryModuleSettings';
import { SolarJobEntryModuleType } from './types';

const moduleData: SolarJobEntryModuleType = {
    _key: '',
    _type: 'solar_job_entry',
    title: 'Solar job entry',
    description: 'Solar job entry point - address search',
    image: '/modules/solar.svg',
    preview: '',
    moduleComponent: SolarJobEntryModule,
    settingsComponent: SolarJobEntryModuleSettings,
    data: {
        margin: 'mb-60',
    },
};

export default moduleData;
