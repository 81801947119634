import SolarHeroModule from './SolarHeroModule';
import SolarHeroModuleSettings from './SolarHeroModuleSettings';
import { SolarHeroModuleType } from './types';

const moduleData: SolarHeroModuleType = {
    _key: '',
    _type: 'solar_hero',
    title: 'Solar hero',
    description: 'Hero module meant to be used on solar pages',
    image: '/modules/solarHero.svg',
    preview: '/modules/solarHero-preview.png',
    moduleComponent: SolarHeroModule,
    settingsComponent: SolarHeroModuleSettings,
    data: {
        title: '',
        description: '',
        margin: 'mb-60',
    },
};

export default moduleData;
