import curry from 'ramda/src/curry';

import { Row, Col } from '/front';
import { SolarJobEntryModuleType } from './types';
import MarginSetting from './../_shared/MarginSetting';
import { ModuleProps } from '/front/types/frontModuleType';

export default function SolarJobEntryModuleSettings({
    module,
    onChange,
}: ModuleProps<SolarJobEntryModuleType>) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin } = module.data;

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
            </Row>
        </>
    );
}
