import { memo } from 'react';

import Description from '/components/ui/typography/Description';
import { ImageModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import ImageType from '/front/types/frontImageType';

import { Image, config } from '/front';

export default memo(function ImageModule({
    module,
    onChange,
}: ModuleProps<ImageModuleType>) {
    const { image, margin, width, roundedBorders } = module.data;

    return (
        <div className={`${margin} flex `}>
            <div style={{ maxWidth: width + 'px' }} className="centered">
                <div
                    style={{
                        borderRadius: roundedBorders ? '12px' : '0px',
                        overflow: 'hidden',
                    }}
                >
                    <Image
                        image={image}
                        onChange={(image: ImageType | null) =>
                            onChange({ data: { ...module.data, image } })
                        }
                        src={config.imageUrl(image).width(width).url()}
                        alt={image?.alt}
                        style={{ display: 'block', margin: '0 auto' }}
                    />
                </div>
                {image && image.title && (
                    <Description
                        style={{ maxWidth: '325px', paddingTop: '16px' }}
                    >
                        {image.title}
                    </Description>
                )}
            </div>
        </div>
    );
});
