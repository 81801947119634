import JobListModule from './JobListModule';
import JobListModuleSettings from './JobListModuleSettings';

export default {
    _key: '',
    _type: 'job_list',
    title: 'Job list',
    description: 'Job list from selected category',
    image: '/modules/jobList.svg',
    preview: '/modules/jobList-preview.png',
    moduleComponent: JobListModule,
    settingsComponent: JobListModuleSettings,
    data: {
        categoryId: 0,
        margin: 'mb-60',
        trackingId: 'Generic job list',
    },
};
