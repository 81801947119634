import { colors } from '/css';

const SearchIcon = ({ color = colors.black, size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.4748 3C14.603 3 17.9496 6.34659 17.9496 10.4748C17.9496 11.9983 17.4938 13.4153 16.7112 14.597L21 18.8858L18.8858 21L14.597 16.7112C13.4153 17.4938 11.9983 17.9496 10.4748 17.9496C6.34659 17.9496 3 14.603 3 10.4748C3 6.34659 6.34659 3 10.4748 3ZM10.4748 5.98993C7.99789 5.98993 5.98993 7.99789 5.98993 10.4748C5.98993 12.9518 7.99789 14.9597 10.4748 14.9597C12.9518 14.9597 14.9597 12.9518 14.9597 10.4748C14.9597 7.99789 12.9518 5.98993 10.4748 5.98993Z"
            fill={color}
        />
    </svg>
);

export default SearchIcon;
