import HeadingModule from './HeadingModule';
import HeadingModuleSettings from './HeadingModuleSettings';
import { HeadingModuleType } from './types';

const moduleData: HeadingModuleType = {
    _key: '',
    _type: 'heading',
    title: 'Heading',
    description: 'An eyecatching large header often used in top of a page',
    image: '/modules/heading.svg',
    moduleComponent: HeadingModule,
    settingsComponent: HeadingModuleSettings,
    data: {
        text: '',
        margin: 'mb-60',
        textAlignment: 'center',
        withDescription: false,
        description: '',
        withButtons: false,
        buttons: [],
        headingSize: 'xl',
        headingContainer: 'default',
        headingTag: 'h1',
        withBulletList: false,
        bulletList: [],
    },
};

export default moduleData;
