interface Props {
    size?: number;
    color?: string;
}

const CheckmarkSimpleIcon = ({
    size = 20,
    color = 'white',
    ...props
}: Props) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.04939 8.27901L8.33356 11.5616L14.9487 4.94531L16.718 6.71499L8.33356 15.0973L3.28223 10.0464L5.04939 8.27901Z"
            fill={color}
        />
    </svg>
);

export default CheckmarkSimpleIcon;
