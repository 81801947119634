import TableModule from './TableModule';
import TableModuleSettings from './TableModuleSettings';
import { ContainerSizes } from '/components/Container';
import { TableModuleType } from './types';

const moduleData: TableModuleType = {
    _key: '',
    _type: 'table',
    title: 'Table',
    description: '',
    image: '/modules/table.svg',
    preview: '/modules/table-preview.png',
    moduleComponent: TableModule,
    settingsComponent: TableModuleSettings,
    data: {
        margin: 'mb-60',
        containerSize: ContainerSizes.MEDIUM,
        rows: [['', '', '']],
        headers: ['', '', ''],
        rowHeaders: [null, null, null],
        useRowHeaders: true,
        useFixedLayout: true,
        rowHeaderContentType: 'image',
        textAlignment: 'center',
    },
};

export default moduleData;
