import curry from 'ramda/src/curry';
import { useEffect, useState } from 'react';

import { ui, Row, Col } from '/front';
import jobCategoryApi from '/api/cms/frontCategoryApi';

export default function JobCategoriesModuleSettings({ module, onChange }) {
    const { Select } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { margin, activeCategoryId, theme, topPadding } = module.data;

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        (async () => {
            const resp = await jobCategoryApi.get({ type: 'job-category' });
            if (resp.error) return error(resp.error);
            setCategories(resp);
        })();
    }, []);

    return (
        <>
            <Row>
                <Col width={[6, 6, 12]} className="mb-24">
                    <Select
                        value={activeCategoryId}
                        options={[
                            { name: '-', value: '' },
                            ...categories.map((c) => ({
                                name: c.title,
                                value: c._id,
                            })),
                        ]}
                        onChange={change('activeCategoryId')}
                        medium
                        style={{ width: '100%' }}
                        label="Active category"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <Select
                        value={margin}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'mb-32' },
                            { name: 'Medium (48px)', value: 'mb-48' },
                            { name: 'Large (60px)', value: 'mb-60' },
                            { name: 'Extra large (80px)', value: 'mb-80' },
                        ]}
                        onChange={change('margin')}
                        medium
                        style={{ width: '100%' }}
                        label="Bottom margin"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <Select
                        value={theme}
                        options={[
                            { name: 'Default', value: 'default' },
                            { name: 'Minimal', value: 'minimal' },
                        ]}
                        onChange={change('theme')}
                        medium
                        style={{ width: '100%' }}
                        label="Theme"
                    />
                </Col>
                <Col width={[6, 6, 12]} className="mb-24">
                    <Select
                        value={topPadding}
                        options={[
                            { name: 'None', value: '' },
                            { name: 'Small (32px)', value: 'pt-32' },
                            { name: 'Medium (48px)', value: 'pt-48' },
                            { name: 'Large (60px)', value: 'pt-60' },
                            { name: 'Extra large (80px)', value: 'pt-80' },
                        ]}
                        onChange={change('topPadding')}
                        medium
                        style={{ width: '100%' }}
                        label="Top padding"
                    />
                </Col>
            </Row>
        </>
    );
}
