import { memo, useEffect, useState, useCallback } from 'react';
import curry from 'ramda/src/curry';
import styled from 'styled-components';
import Link from 'next/link';

import Container from '/components/Container';
import { Text, Image, config, ui, Button, Row, Col, error } from '/front';
import { effects, colors } from '/css';
import { formatPrice, changeArrayItemPosition } from '/lib/helpers';
import ItemCarousel from '/components/layout/ItemCarousel';
import ScrollObserver from '/components/ScrollObserver';
import { trackSelectItem, trackViewItemList } from '/lib/ga-data-layer';

const ItemWrapper = ({ editMode, item, children, trackingId, index }) =>
    editMode ? (
        <div className="item-wrapper">{children}</div>
    ) : (
        <Link
            prefetch={false}
            href={item.jobUrl || '#'}
            onClick={() => {
                if (!editMode) {
                    trackSelectItem(item.job || {}, trackingId, index);
                }
            }}
            passHref
            className="item-wrapper"
        >
            {children}
        </Link>
    );

export default memo(function GoodBetterBestModule({
    module,
    onChange,
    addAction,
}) {
    const {
        margin,
        background,
        items,
        withTitle,
        title,
        withButton,
        button,
        trackingId = 'Good better best job list',
    } = module.data;

    const [editedItemIndex, setEditedItemIndex] = useState(null);

    const { editMode, Actions, Modal, Input, Label, Select } = ui();

    const newItem = {
        jobUrl: '',
        title: '',
        description: '',
        image: null,
        salesTag: '',
    };

    // render module - get job data for selected category
    useEffect(() => {
        if (!editMode || editedItemIndex !== null) return; // exit if modal is open

        if (items.length) {
            (async () => {
                const { data, error: e } = await config.api.page.renderModule(
                    module
                );
                if (e) return error(e);
                onChange({ data });
            })();
        }
    }, [editedItemIndex]);

    useEffect(() => {
        if (!items.length) addItem();
    }, []);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [module.data.items, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () =>
        onChange({
            data: { ...module.data, items: [...module.data.items, newItem] },
        });

    const removeItem = (index) =>
        onChange({
            data: {
                ...module.data,
                items: module.data.items.filter((_, i) => i !== index),
            },
        });

    const changeItem = curry((index, key, value) =>
        onChange({
            data: {
                ...module.data,
                items: items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                }),
            },
        })
    );

    const moveItems = useCallback(
        (currentIndex, dir) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            onChange({ data: { ...module.data, items: newItems } });
        },
        [items]
    );

    const salesTagLabels = {
        campaign: 'Kampanje',
        popular: 'Popular',
        cheapest: 'Billigst',
        new: 'Nyhet',
    };

    const onVisible = (revealCount) => {
        if (!editMode && revealCount === 1) {
            trackViewItemList(
                items.map((item) => item.job || {}),
                trackingId
            );
        }
    };

    return (
        <ScrollObserver onVisible={onVisible}>
            <Wrapper className={margin} style={{ backgroundColor: background }}>
                <Container className="pt-60 pb-80">
                    {(withTitle || withButton) && (
                        <div
                            className={`intro-text ${
                                withTitle ? 'with-title' : ''
                            } ${withButton ? 'with-button' : ''} mb-60`}
                        >
                            {withTitle && (
                                <Text
                                    value={title}
                                    onChange={(val) =>
                                        onChange({
                                            data: {
                                                ...module.data,
                                                title: val,
                                            },
                                        })
                                    }
                                    placeholder="Title"
                                    multiline
                                    className={`heading-large`}
                                    tag="div"
                                />
                            )}
                            {withButton && (
                                <Button
                                    value={button}
                                    onChange={(val) =>
                                        onChange({
                                            data: {
                                                ...module.data,
                                                button: val,
                                            },
                                        })
                                    }
                                    className="button-desktop"
                                    link={config.pageUrl(button)}
                                >
                                    {button?.title || 'button'}
                                </Button>
                            )}
                        </div>
                    )}
                    <ItemCarousel
                        className="items"
                        onStage={[3, 2, 1]}
                        itemPixelGap={24}
                    >
                        {items.map((item, index) => (
                            <ItemWrapper
                                key={index}
                                editMode={editMode}
                                item={item}
                                trackingId={trackingId}
                                index={index}
                            >
                                <div>
                                    <div className="image-container">
                                        <Image
                                            src={config
                                                .imageUrl(item.image)
                                                .width(370)
                                                .height(245)
                                                .url()}
                                            image={item.image}
                                            onChange={changeItem(index)(
                                                'image'
                                            )}
                                        />
                                        {item.salesTag && (
                                            <div
                                                className={`sales-tag style-${item.salesTag}`}
                                            >
                                                {salesTagLabels[item.salesTag]}
                                            </div>
                                        )}
                                    </div>
                                    <div className="pl-32 pr-32">
                                        <Text
                                            value={item.title}
                                            onChange={changeItem(index)(
                                                'title'
                                            )}
                                            placeholder="Title"
                                            multiline
                                            className={`title mb-16`}
                                            tag="div"
                                        />
                                        <Text
                                            value={item.description}
                                            onChange={changeItem(index)(
                                                'description'
                                            )}
                                            placeholder="Description"
                                            multiline
                                            className={`description mb-24`}
                                            tag="div"
                                        />
                                    </div>
                                </div>
                                <div className="pb-48">
                                    <span className="link">
                                        Fra{' '}
                                        {formatPrice(
                                            item?.job?.startingPrice?.gross
                                        )}
                                    </span>
                                </div>
                                <Actions
                                    onMoveLeft={
                                        index
                                            ? () => moveItems(index, -1)
                                            : null
                                    }
                                    onMoveRight={
                                        index < items.length - 1
                                            ? () => moveItems(index, 1)
                                            : null
                                    }
                                    onEdit={() => setEditedItemIndex(index)}
                                    onDelete={
                                        items.length > 1
                                            ? () => removeItem(index)
                                            : null
                                    }
                                    align="bottom-left"
                                />
                            </ItemWrapper>
                        ))}
                    </ItemCarousel>
                    {withButton && (
                        <div className="pt-60 button-mobile">
                            <Button
                                value={button}
                                onChange={(val) =>
                                    onChange({
                                        data: { ...module.data, button: val },
                                    })
                                }
                                link={config.pageUrl(button)}
                                style={{ width: '100%' }}
                            >
                                {button?.title || 'button'}
                            </Button>
                        </div>
                    )}
                    {editedItemIndex !== null && (
                        <Modal
                            onClose={() => setEditedItemIndex(null)}
                            width="500px"
                            height="300px"
                            title={items[editedItemIndex]?.title}
                        >
                            <Row>
                                <Col width={12} className="mb-24">
                                    <Input
                                        value={items[editedItemIndex]?.jobUrl}
                                        onChange={changeItem(editedItemIndex)(
                                            'jobUrl'
                                        )}
                                        label="Job link"
                                        medium
                                    />
                                </Col>
                                <Col width={12}>
                                    <Label style={{ marginBottom: '4px' }}>
                                        Sales tag
                                    </Label>
                                    <Select
                                        value={items[editedItemIndex]?.salesTag}
                                        onChange={changeItem(editedItemIndex)(
                                            'salesTag'
                                        )}
                                        medium
                                        options={[
                                            { name: 'None', value: '' },
                                            {
                                                name: 'Kampanje',
                                                value: 'campaign',
                                            },
                                            {
                                                name: 'Popular',
                                                value: 'popular',
                                            },
                                            {
                                                name: 'Billigst',
                                                value: 'cheapest',
                                            },
                                            { name: 'Nyhet', value: 'new' },
                                        ]}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                        </Modal>
                    )}
                </Container>
            </Wrapper>
        </ScrollObserver>
    );
});

const Wrapper = styled.div`
    .intro-text {
        display: flex;
        align-items: flex-end;
        &.with-title {
            > div:first-child {
                width: 60%;
                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }
        &.with-button {
            justify-content: flex-end;
        }
        &.with-title.with-button {
            justify-content: space-between;
        }
    }
    .items {
        .item-wrapper {
            padding-top: 14px;
            min-height: 100%;
            flex: 0 1 auto;
            ${effects.shadow};
            background: #fff;
            border-radius: 12px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            transition: box-shadow 0.2s ease-in-out;
            margin-right: 25px;
            @media (pointer: fine) {
                &:hover {
                    ${effects.shadowHover};
                }
            }
            &:last-child {
                margin-right: 0;
            }
            .image-container {
                position: relative;
                img {
                    display: block;
                    margin-bottom: 12px;
                    user-drag: none;
                    width: 100%;
                }
                .sales-tag {
                    position: absolute;
                    top: 22px;
                    left: 52%;
                    height: 36px;
                    border-radius: 12px;
                    background: #ccc;
                    color: #000;
                    padding: 9px 18px;
                    line-height: 1;
                    font-family: 'gellix-semi';
                    transform: rotate(30deg);
                    &.style-campaign {
                        background: ${colors.orange};
                        color: white;
                    }
                    &.style-popular {
                        background: ${colors.purple};
                        color: white;
                    }
                    &.style-cheapest {
                        background: ${colors.green};
                        color: black;
                    }
                    &.style-new {
                        background: ${colors.mint};
                        color: white;
                    }
                }
            }
            .title {
                font-size: 26px;
                font-family: 'gellix-bold';
                line-height: 32px;
            }
            .description {
                font-size: 15px;
                line-height: 23px;
                color: ${colors.grey};
                text-align: center;
            }
            .link {
                font-size: 20px;
                font-family: 'gellix-semi';
                border-bottom: 3px solid ${colors.orange};
                padding-bottom: 2px;
                @media (pointer: fine) {
                    &:hover {
                        border-bottom: 3px solid ${colors.purple};
                    }
                }
            }
        }
    }
    .button-desktop {
        @media (max-width: 767px) {
            display: none;
        }
    }
    .button-mobile {
        @media (min-width: 768px) {
            display: none;
        }
    }
`;
