import { memo } from 'react';
import { EmptySpaceModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';

export default memo(function EmptySpaceModule({
    module,
}: ModuleProps<EmptySpaceModuleType>) {
    return (
        <div
            className={module.data.size}
            style={{ background: module.data.background || 'transparent' }}
        />
    );
});
