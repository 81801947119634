import { ReactNode } from 'react';
import Actions from '../Actions';
import spreadPropsClassName from '/lib/utils/spreadPropsClassName';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode;
    fullWidth?: boolean;
}

const ModalFooter = ({ children, ...props }: Props) => (
    <Actions centered {...spreadPropsClassName(props, 'mt-48')}>
        {children}
    </Actions>
);

export default ModalFooter;
