import JobModule from './JobModule';
import JobModuleSettings from './JobModuleSettings';
import { JobModuleType } from './types';

const moduleData: JobModuleType = {
    _key: '',
    _type: 'job',
    title: 'Job',
    description: 'Job module with product presentation',
    image: '/modules/job.svg',
    preview: '/modules/job-preview.png',
    moduleComponent: JobModule,
    settingsComponent: JobModuleSettings,
    data: {
        title: '',
        description: '',
        image: null,
        background: '#C3F2EE',
        backgroundSecondary: '#EBF9F8',
        jobId: null,
        job: null,
        margin: 'mb-60',
        selectedProducts: [],
        button: {
            url: '#',
            title: 'Fra ...',
            id: '',
            path: '',
            slug: '',
            target: '_self',
            type: '',
        },
        textColor: 'black',
    },
};

export default moduleData;
