import PromoBannerModule from './PromoBannerModule';
import PromoBannerModuleSettings from './PromoBannerModuleSettings';
import { PromoBannerModuleType } from './types';

const moduleData: PromoBannerModuleType = {
    _key: '',
    _type: 'promo_banner',
    title: 'Promo banner',
    description: 'Seasonal promotional banner with more info on click',
    image: '/modules/promoBanner.svg',
    moduleComponent: PromoBannerModule,
    settingsComponent: PromoBannerModuleSettings,
    data: {
        margin: 'mb-60',
    },
};

export default moduleData;
