import {
    ChangeEvent,
    HTMLAttributes,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled, { css } from 'styled-components';
import CircleCrossIcon from '/components/icons/CircleCrossIcon';
import SearchIcon from '/components/icons/SearchIcon';
import { colors } from '/css';
import AddressSearchResult from '../../types/AddressSearchResults';
import useOnClickOutside from '/hooks/useOnClickOutside';

export enum AddressSearchTheme {
    DARK = 'dark',
    LIGHT = 'light',
}

interface AddressSuggestion {
    id: string;
    text: string;
    placeholder?: string;
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    onSelectAddress: (address: AddressSearchResult) => void;
    onClearAddress?: () => void;
    initialAddress?: string;
    theme?: AddressSearchTheme;
    placeholder?: string;
}

const AddressSearch = ({
    onSelectAddress,
    onClearAddress,
    initialAddress = '',
    placeholder = 'Søk etter din bolig',
    theme = AddressSearchTheme.DARK,
    ...props
}: Props) => {
    const [value, setValue] = useState(initialAddress);
    const [focused, setFocused] = useState(false);
    const [results, setResults] = useState<AddressSearchResult[]>([]);
    const [showResults, setShowResults] = useState(false);
    const debounceRef = useRef<ReturnType<typeof setTimeout>>();
    const inputRef = useRef<HTMLInputElement>(null);

    const containerRef = useOnClickOutside(() => {
        setFocused(false);
        setShowResults(false);
        setResults([]);
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        debouceQuery(e.target.value);
    };

    const debouceQuery = (val: string) => {
        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }
        if (val.length === 0) setResults([]);

        setValue(val);

        if (val.length > 2) {
            debounceRef.current = setTimeout(() => {
                fetchAutocompleteResults(val);
            }, 300);
        }
    };

    const onFocus = () => {
        setFocused(true);
        if (value) {
            debouceQuery(value);
        }
    };

    const fetchAutocompleteResults = (query: string) => {
        const url = `https://fritekstsok.api.norkart.no/suggest/kommunecustom?Targets=gateadresse&Size=10&Query=${encodeURIComponent(
            query.trim()
        )}`;

        fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'X-WAAPI-TOKEN': '1a39f153-4cf3-4654-9b69-1c8a6aa0fad3',
            },
        })
            .then((res) => res.json())
            .then((res) => {
                const hits = res?.Options || [];
                setResults(
                    hits.map(
                        (hit: any): AddressSearchResult => ({
                            id: hit?.PayLoad?.AdresseId,
                            address: hit?.Text?.split(', ')
                                .slice(0, -1)
                                .join(', '),
                            city:
                                hit?.PayLoad?.PostSted.charAt(0).toUpperCase() +
                                hit?.PayLoad?.PostSted.slice(1).toLowerCase(),
                            postCode: hit?.PayLoad?.PostNummer,
                            position: {
                                lat: hit?.PayLoad?.Posisjon?.Y,
                                lng: hit?.PayLoad?.Posisjon?.X,
                            },
                        })
                    )
                );
            })
            .catch((err) => {
                console.log('Unable to fetch address suggestions');
            });
    };

    const handleSelectAddress = (address: AddressSearchResult) => {
        setFocused(false);

        setValue(`${address.address}`);

        onSelectAddress(address);
        setResults([]);

        if (inputRef.current) {
            inputRef.current.blur();
        }
    };

    const clearInput = () => {
        setValue('');
        setResults([]);
        onClearAddress && onClearAddress();
    };

    useEffect(() => {
        if (focused && results.length) {
            setShowResults(true);
        } else if (!focused) {
            setShowResults(false);
        }
    }, [results, focused]);

    return (
        <Container
            focused={focused}
            ref={containerRef}
            theme={theme}
            {...props}
            className={`address-search ${props.className || ''}`}
        >
            <input
                type="search"
                value={value}
                onChange={handleInputChange}
                onFocus={onFocus}
                placeholder={placeholder}
                ref={inputRef}
            />
            <SearchIcon
                className="search-icon"
                onClick={() => inputRef?.current?.focus()}
            />
            {!!value && (
                <button className="clear-button" onClick={clearInput}>
                    <CircleCrossIcon />
                </button>
            )}
            {showResults && !!results.length && (
                <SearchResults>
                    {results.map((r) => (
                        <li key={r.id}>
                            <button onClick={() => handleSelectAddress(r)}>
                                <div className="street">{r.address}</div>
                                <div className="city">{r.city}</div>
                            </button>
                        </li>
                    ))}
                </SearchResults>
            )}
        </Container>
    );
};

const SearchResults = styled.ul`
    all: unset;
    top: calc(100% + 16px);
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 1000; //due to map
    border-radius: 12px;
    overflow: hidden;

    li {
        all: unset;
        background-color: #ebe8fe;
        width: 100%;
        display: block;
        border-bottom: 1px solid white;
        cursor: pointer;

        transition: opacity 0.2s ease;

        &:hover {
            opacity: 0.95;
        }

        &:last-of-type {
            border-bottom: none;
        }

        button {
            all: unset;
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0 12px;
            color: ${colors.black};
            min-width: 100%;

            .city {
                margin-left: 12px;
                color: ${colors.grey};
            }
        }
    }
`;

const Container = styled.div<{
    focused: boolean;
    ref: any;
    theme: AddressSearchTheme;
}>`
    height: 58px;
    border-radius: 12px;
    border: 3px solid ${colors.black};
    width: 500px;
    max-width: 100%;
    position: relative;
    transition: all 0.2s ease;

    input {
        all: unset;
        height: 100%;
        width: 100%;
        box-sizing: border-box;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            -webkit-appearance: none;
        }

        font-size: 20px;
        padding: 0 44px;
    }

    .clear-button {
        all: unset;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        cursor: pointer;
    }

    .search-icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        cursor: text;
    }

    ${(p) =>
        p.focused &&
        p.theme === AddressSearchTheme.DARK &&
        css`
            input {
                background-color: transparent;
                &::placeholder {
                    opacity: 0;
                }
            }
        `};

    ${(p) =>
        p.theme === AddressSearchTheme.DARK &&
        css`
            border-color: ${colors.black};
            input {
                color: ${colors.white};
                background-color: ${colors.black};

                &::placeholder {
                    color: ${colors.white};
                }
            }

            .search-icon path,
            .clear-button svg path {
                fill: ${colors.white};
            }
        `}

    ${(p) =>
        p.theme === AddressSearchTheme.LIGHT &&
        css`
            border-color: ${colors.lightGray};
            input {
                color: ${colors.black};
                background-color: transparent;

                &::placeholder {
                    color: ${colors.grey};
                }
            }

            .search-icon path,
            .clear-button svg path {
                fill: ${colors.black};
            }
        `}

    ${(p) =>
        p.focused &&
        p.theme === AddressSearchTheme.LIGHT &&
        css`
            border-color: ${colors.purple};
        `}

    ${(p) =>
        p.focused &&
        p.theme === AddressSearchTheme.DARK &&
        css`
            input {
                background-color: transparent;
                color: ${colors.black};
            }

            .search-icon path {
                fill: ${colors.black};
            }

            .clear-button svg path {
                fill: ${colors.black};
            }
        `};
`;

export default AddressSearch;
