import { useState } from 'react';
import curry from 'ramda/src/curry';
import { useAtom } from 'jotai';

import ui from './../ui';
import {
    changeArrayItemPosition,
    clone,
    copyToClipboard,
} from './../lib/helpers';
import { success } from './../lib/message';
import id from './../lib/id';
import config from '../config';
import _ from './../_';
import {
    modalVisibleAtom,
    insertAtIndexAtom,
} from './../AdminBar/ModuleBrowser';
import moduleMap from '/components/modules';
import ModuleType, { SimpleModuleType } from '../types/frontModuleType';

import { ActionTypes } from '../ui/FrontActions';

interface Props {
    modules: ModuleType[] | SimpleModuleType[];
    createdAt?: string;
}

export default function Modules({ modules, createdAt }: Props) {
    const [, setModuleBrowserVisible] = useAtom(modalVisibleAtom);
    const [, setInsertIndex] = useAtom(insertAtIndexAtom);

    const { Actions, Modal } = ui();

    const { setPage } = config.usePage();

    const [moduleSettings, setModuleSettings] = useState<boolean | string>(
        false
    );

    const [customActions, setCustomActions] = useState<{
        [key: string]: ActionTypes;
    }>({});

    const onChange = curry((key, data) => {
        const newModules = modules.map((m) =>
            m._key === key ? { ...m, ...data } : m
        );
        setPage({ modules: newModules });
    });

    const onMove = (key: string, dir: 1 | -1) => {
        const currentIndex = modules
            .map((m) => m._key)
            .reduce((acc, k, i) => (k === key ? i : acc), -1);

        const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
        const newModules = changeArrayItemPosition(
            modules,
            currentIndex,
            newIndex
        );
        setPage({ modules: newModules });
    };

    const onDuplicate = (key: string) => {
        const candidate = modules.find((m) => m._key === key);
        if (!candidate) return;

        const candidateIndex = modules
            .map((m) => m._key)
            .reduce((acc, k, i) => (k === key ? i + 1 : acc), modules.length);

        const duplicate = { ...clone(candidate), _key: id() };
        const newModules = [
            ...modules.slice(0, candidateIndex),
            duplicate,
            ...modules.slice(candidateIndex),
        ];
        setPage({ modules: newModules });
    };

    const onRemove = (key: string) => {
        const newModules = modules.filter((m) => m._key !== key);
        setPage({ modules: newModules });
    };

    const addCustomAction =
        (moduleKey: string) =>
        (action: keyof ActionTypes, callback: () => void) => {
            setCustomActions((prevCustomActions) => {
                return {
                    ...prevCustomActions,
                    [moduleKey]: {
                        ...(prevCustomActions[moduleKey] || {}),
                        [action]: callback,
                    },
                };
            });
        };

    return (
        <div className="ft-modules">
            {modules.map((module, index) => {
                if (!moduleMap[module._type]) {
                    console.warn(
                        `Module type "${module._type}" does not exist in module map`,
                        moduleMap
                    );
                    return null;
                }

                const ModuleComponent = moduleMap[module._type].moduleComponent;
                const SettingsComponent =
                    moduleMap[module._type].settingsComponent;

                return (
                    <div
                        style={{ position: 'relative' }}
                        key={module._key + '-' + index}
                        id={'module-' + module._key}
                        className={`ft-module module-${module._type} module-index-${index}`}
                    >
                        <ModuleComponent
                            module={module}
                            onChange={onChange(module._key)}
                            addAction={addCustomAction(module._key)}
                            createdAt={createdAt}
                        />

                        <Actions
                            label={`${moduleMap[module._type].title}`}
                            {...(customActions[module._key] || {})}
                            onMoveUp={
                                index
                                    ? () => onMove(module._key, -1)
                                    : undefined
                            }
                            onMoveDown={
                                index < modules.length - 1
                                    ? () => onMove(module._key, 1)
                                    : undefined
                            }
                            onCopy={() => onDuplicate(module._key)}
                            onEdit={
                                !SettingsComponent
                                    ? undefined
                                    : () => {
                                          setModuleSettings(module._key);
                                      }
                            }
                            onDelete={() => onRemove(module._key)}
                            onAddModule={() => {
                                setInsertIndex(index + 1);
                                setModuleBrowserVisible(true);
                            }}
                            toolTips={{
                                onMoveUp: 'Move module up',
                                onMoveDown: 'Move module down',
                                onCopy: 'Duplicate module',
                                onEdit: 'Open module settings',
                                onDelete: 'Delete module',
                                onAddModule: 'Add module below',
                            }}
                        />

                        {SettingsComponent &&
                            moduleSettings === module._key && (
                                <Modal
                                    onClose={() => setModuleSettings(false)}
                                    title={_(
                                        '$1 module settings',
                                        moduleMap[module._type].title
                                    )}
                                    width="600px"
                                    height="550px"
                                >
                                    <SettingsComponent
                                        module={module}
                                        onChange={onChange(module._key)}
                                    />
                                    <button
                                        style={{
                                            all: 'unset',
                                            fontSize: '14px',
                                            opacity: '.5',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            copyToClipboard(
                                                `module-${module._key}`,
                                                () =>
                                                    success(
                                                        _('Copied to clipboard')
                                                    )
                                            );
                                        }}
                                    >{`ID: module-${module._key}`}</button>
                                </Modal>
                            )}
                    </div>
                );
            })}
            <div style={{ fontSize: '0', opacity: '0' }}>.</div>
        </div>
    );
}
