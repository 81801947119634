import objectPick from '/lib/utils/objectPick';
import AddressSearchResult from '/modules/solar/types/AddressSearchResults';
import { create } from 'zustand';
import { RoofData } from '../types/AddressSearchResults';
import SolarJobLocation from '../types/SolarJobLocation';
import SolarJobLocationStatus from '../types/SolarJobLocationStatus';
import SolarJobOrder from '../types/SolarJobOrder';

interface SolarJobLocationState {
    address?: SolarJobLocation['address'];
    position?: SolarJobLocation['position'];
    buildings: SolarJobLocation['buildings'];
    roofs: SolarJobLocation['roofs'];
    status: SolarJobLocationStatus;
    unavailableLocation: boolean;
    isDummyLocation: boolean;
    addRoofMode: boolean;
}

interface SolarJobLocationStateMutators {
    setRoofs: (roofs: SolarJobLocationState['roofs']) => void;
    addRoofs: (roofs: SolarJobLocationState['roofs']) => void;
    setStatus: (status: SolarJobLocationState['status']) => void;
    setLocationAvailability: (
        unavailable: SolarJobLocationState['unavailableLocation']
    ) => void;
    setAddress: (address: AddressSearchResult) => void;
    addBuildings: (buildings: SolarJobLocation['buildings']) => void;
    setLocation: (location: SolarJobOrder['location']) => void;
    reset: () => void;
    toggleAddRoofMode: () => void;
}

interface SolarJobLocationStateGetters {
    getLocationState: () => SolarJobLocationState;
}

interface SolarJobLocationStore
    extends SolarJobLocationState,
        SolarJobLocationStateMutators,
        SolarJobLocationStateGetters {}

const defaultState: SolarJobLocationState = {
    address: undefined,
    position: undefined,
    buildings: [],
    roofs: [],
    status: SolarJobLocationStatus.missingAddress,
    unavailableLocation: false,
    isDummyLocation: false,
    addRoofMode: false,
};

export const useSolarJobLocationState = create<SolarJobLocationStore>(
    (set, get) => ({
        ...defaultState,
        setRoofs: (roofs) => set({ roofs }),
        addRoofs: (roofs) =>
            set((state) => ({ roofs: [...state.roofs, ...roofs] })),
        setStatus: (status) => set({ status }),
        setLocationAvailability: (unavailableLocation) =>
            set({
                unavailableLocation,
            }),
        setAddress: (address) =>
            set({
                ...defaultState,
                address: {
                    id: address.id,
                    address: address.address,
                    city: address.city,
                    postCode: address.postCode,
                },
                position: address.position,
                isDummyLocation: address.isDummy,
                status: SolarJobLocationStatus.pending,
            }),
        addBuildings: (buildings) => set({ buildings: buildings }),
        reset: () => set({ ...defaultState }),
        getLocationState: () => objectPick(defaultState, get()),
        setLocation: (location) =>
            set({
                ...defaultState,
                ...location,
                status: SolarJobLocationStatus.ready,
            }),
        toggleAddRoofMode: () => set({ addRoofMode: !get().addRoofMode }),
    })
);
