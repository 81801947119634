import { useEffect } from 'react';
import { useRouter } from 'next/router';

import authApi from '/api/auth';
import { cookie } from '/lib/helpers';
import { useUserState } from '/store/userStore';

export default function useAutoLogin() {
    const router = useRouter();

    const setUser = useUserState((state) => state.setUser);

    // as path without hashes and query strings
    const cleanAsPath = router.asPath.split('#')[0].split('?')[0];

    useEffect(() => {
        if (router.query?.at) {
            (async () => {
                const authenticationByToken = await authApi.logInWithToken(
                    router.query.at
                );

                if (authenticationByToken?.status === 'success') {
                    cookie.set('AuthToken', authenticationByToken.token, 30);
                    if (authenticationByToken.user.contractor)
                        cookie.set(
                            'ContractorUuid',
                            authenticationByToken.user.contractor.uuid,
                            30
                        );
                    setUser(authenticationByToken.user);
                    router.replace(cleanAsPath);
                } else {
                    router.replace(
                        `/auth?redirect=${cleanAsPath}&expired=1`,
                        `/logginn?redirect=${cleanAsPath}&expired=1`
                    );
                }
            })();
        }
    }, [router.query?.at]);
}
