import { colors } from '/css';

const CircleCrossIcon = ({ color = colors.black, size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.6009L9.70847 8.29141L8.31003 9.70575L10.5821 12L8.31003 14.3082L9.70847 15.7088L12 13.4212L14.2936 15.7088L15.714 14.3082L13.4082 12L15.714 9.70575L14.2936 8.29141L12 10.6009Z"
            fill={color}
        />
    </svg>
);

export default CircleCrossIcon;
