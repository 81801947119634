import ContactModule from './ContactModule';
import ContactModuleSettings from './ContactModuleSettings';

export default {
    _key: '',
    _type: 'contact',
    title: 'Contact',
    description: 'Contact details with expandable email form',
    image: '/modules/contact.svg',
    moduleComponent: ContactModule,
    settingsComponent: ContactModuleSettings,
    data: {
        margin: 'mb-60',
        chatCardDescription: '',
        phoneCardDescription: '',
        emailCardDescription: '',
        phoneNumber: '',
    },
};
