import EmptySpaceModule from './EmptySpaceModule';
import EmptySpaceModuleSettings from './EmptySpaceModuleSettings';
import { EmptySpaceModuleType } from './types';

const moduleData: EmptySpaceModuleType = {
    _key: '',
    _type: 'empty_space',
    title: 'Empty space',
    description: 'Empty space for more breathing room between modules',
    image: '',
    moduleComponent: EmptySpaceModule,
    settingsComponent: EmptySpaceModuleSettings,
    data: {
        size: 'pt-80',
        background: 'transparent',
    },
};

export default moduleData;
