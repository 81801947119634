import styled from 'styled-components';

import { HowItWorksModuleType } from '../types';
import Container from '/components/Container';
import BrowseIllustration from '../illustrations/BrowseIllustration';
import InspectionIllustration from '../illustrations/InspectionIllustration';
import TimeslotIllustration from '../illustrations/TimeslotIllustration';
import ElectricianIllustration from '../illustrations/ElectricianIllustration';
import { Text } from '/front';
import Arrow from '../illustrations/Arrow';
import { colors } from '/css';
import Button from '/components/ui/buttons/Button';

const illustrationMap = {
    timeSelection: TimeslotIllustration,
    inspection: InspectionIllustration,
    offer: BrowseIllustration,
    electrician: ElectricianIllustration,
};

type Data = HowItWorksModuleType['data']['videoInspection'];

interface Props {
    data: Data;
    onChange: (d: Data) => void;
}

export default function VideoInspection({ data, onChange }: Props) {
    const { steps, title, description } = data;

    const onStepTextChange =
        (stepKey: string, key: 'text') => (val: string) => {
            onChange({
                ...data,
                steps: data.steps.map((s) =>
                    s.key === stepKey ? { ...s, [key]: val } : s
                ),
            });
        };

    return (
        <Wrapper className="pt-120 pb-120">
            <Container small>
                <div className="intro-text mb-120">
                    <Text
                        value={title}
                        onChange={(val) =>
                            onChange({
                                ...data,
                                title: val,
                            })
                        }
                        placeholder="Title"
                        multiline
                        className={`heading-xl mb-24 text-align-center`}
                        tag="h3"
                    />
                    <Text
                        value={description}
                        onChange={(val) =>
                            onChange({
                                ...data,
                                description: val,
                            })
                        }
                        placeholder="Description"
                        multiline
                        className={`paragraph-medium text-align-center`}
                        tag="p"
                    />
                </div>
                {steps.map((step, i) => {
                    const Illustration =
                        illustrationMap[step.key] || (() => null);
                    return (
                        <div
                            key={step.key}
                            className={`step mb-120 step-${step.key} ${
                                !(i % 2) ? 'odd' : 'even'
                            }`}
                        >
                            <div className="image">
                                <Illustration />
                            </div>
                            <div className="text">
                                <Text
                                    value={step.text}
                                    onChange={onStepTextChange(
                                        step.key,
                                        'text'
                                    )}
                                    placeholder="..."
                                    multiline
                                    className={`paragraph-medium`}
                                />
                                {i < steps.length - 1 && <Arrow />}
                            </div>
                        </div>
                    );
                })}
                <div className="bottom-cta">
                    <Button
                        link={{ href: '/bestilling/videobefaring' }}
                        variant="primary"
                        size="large"
                    >
                        Bestill videobefaring
                    </Button>
                </div>
            </Container>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .intro-text {
        .paragraph-medium {
            font-family: var(--font-bold) !important;
            line-height: 1.4;
        }
    }
    .step {
        display: flex;
        margin-bottom: 120px;
        @media (max-width: 450px) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 60px;
        }
        .text {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            text-align: center;
            padding: 20px 0;
            @media (max-width: 450px) {
                padding: 0;
            }
            .paragraph-medium {
                font-family: var(--font-bold) !important;
                line-height: 1.4;
            }
            .arrow {
                position: absolute;
                bottom: 0;
                transform: translateY(80%);
                left: 40px;
                @media (max-width: 450px) {
                    display: none;
                }
            }
        }
        .image {
            margin: 0 40px 0 0;
            display: flex;
            align-items: center;
            @media (max-width: 450px) {
                margin: 0 0 40px 0;
            }
            svg {
                width: auto;
                height: auto;
                min-width: 250px;
                display: block;
            }
        }
        &.even {
            @media (min-width: 451px) {
                .image {
                    order: 2;
                    margin: 0 0 0 40px;
                }
                .text {
                    order: 1;
                }
            }
        }

        &.step-timeSelection {
            .timeslots-illustration {
                max-height: 185px;
                .selected-timeslot {
                    fill: ${colors.purpleLighter2};
                }
                .timeslots-background {
                    fill: ${colors.grey3};
                }
            }
            .arrow {
                transform: translateY(120%);
                left: 80px;
            }
        }
        &.step-inspection {
            .inspection-illustration {
                max-height: 221px;
                bottom: -20px;
                position: relative;
                @media (max-width: 450px) {
                    bottom: 0;
                }
                .hand,
                .phone-background {
                    fill: ${colors.grey3};
                }
                .person {
                    fill: ${colors.purpleLighter2};
                }
            }
            .arrow {
                transform: translateY(90%) rotate(5deg) scaleX(-1);
                left: auto;
                right: 50px;
            }
        }
        &.step-offer {
            .browse-illustration {
                max-height: 236px;
                .hand {
                    fill: ${colors.grey3};
                }
                .phone-background {
                    fill: ${colors.purpleLighter2};
                }
            }
            .arrow {
                transform: translateY(100%) rotate(-5deg);
                left: 20px;
            }
        }
        &.step-electrician {
            .electrician-illustration {
                max-height: 208px;
                .hat {
                    fill: ${colors.purpleLighter5};
                }
                .cape {
                    fill: ${colors.purpleLighter2};
                }
                .pants {
                    fill: ${colors.purpleM1};
                }
            }
        }
    }
    .bottom-cta {
        display: flex;
        justify-content: center;
    }
`;
