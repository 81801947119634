import curry from 'ramda/src/curry';

import { ui, Row, Col } from '/front';
import { TextAndImageModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';
import { ContainerSizes } from '/components/Container';
import VerticalPaddingSetting from '../_shared/VerticalPaddingSetting';

export default function TextAndImageModuleSettings({
    module,
    onChange,
}: ModuleProps<TextAndImageModuleType>) {
    const { Select, ColorPicker, Switch } = ui();

    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const {
        margin,
        imageAlignment,
        textPosition,
        containerSize,
        verticalPadding,
        backgroundColor,
        textImageSpacing,
        imageRoundedBorders,
        textColor,
        proportions,
    } = module.data;

    return (
        <>
            <Row>
                <Col width={[6]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ContainerSizeSetting
                        containerSize={containerSize}
                        onChange={change('containerSize')}
                        allowedValues={[
                            ContainerSizes.LARGE,
                            ContainerSizes.MEDIUM,
                            ContainerSizes.MEDIUM_SMALL,
                        ]}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={imageAlignment}
                        options={['right', 'left']}
                        onChange={change('imageAlignment')}
                        medium
                        style={{ width: '100%' }}
                        label="Image alignment"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={proportions || '50-50'}
                        options={[
                            { name: '30 - 70', value: '30-70' },
                            { name: '40 - 60', value: '40-60' },
                            { name: '50 - 50', value: '50-50' },
                            { name: '60 - 40', value: '60-40' },
                            { name: '70 - 30', value: '70-30' },
                        ]}
                        onChange={change('proportions')}
                        medium
                        style={{ width: '100%' }}
                        label="Text - image % proportions "
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Switch
                        on={imageRoundedBorders}
                        onChange={change('imageRoundedBorders')}
                        style={{ marginTop: '5px' }}
                        label="Image rounded borders"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={textPosition}
                        options={['top', 'center']}
                        onChange={change('textPosition')}
                        medium
                        style={{ width: '100%' }}
                        label="Text position"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <VerticalPaddingSetting
                        verticalPadding={verticalPadding}
                        onChange={change('verticalPadding')}
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <ColorPicker
                        value={backgroundColor}
                        onChange={change('backgroundColor')}
                        medium
                        allowCustomColors
                        style={{ width: '100%' }}
                        label="Background color"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={textImageSpacing}
                        options={[
                            '0px',
                            '24px',
                            '32px',
                            '48px',
                            '60px',
                            '80px',
                            '120px',
                        ]}
                        onChange={change('textImageSpacing')}
                        medium
                        style={{ width: '100%' }}
                        label="Text-image spacing"
                    />
                </Col>
                <Col width={[6]} className="mb-24">
                    <Select
                        value={textColor || 'black'}
                        options={[
                            { name: 'Black', value: 'black' },
                            { name: 'White', value: 'white' },
                        ]}
                        onChange={change('textColor')}
                        medium
                        style={{ width: '100%' }}
                        label="Text color"
                    />
                </Col>
            </Row>
        </>
    );
}
