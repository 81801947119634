import JobCategoriesModule from './JobCategoriesModule';
import JobCategoriesModuleSettings from './JobCategoriesModuleSettings';

export default {
    _key: '',
    _type: 'job_categories',
    title: 'Job categories',
    description: 'All job categories with header and search',
    image: '/modules/jobCategories.svg',
    preview: '/modules/jobCategories-preview.png',
    moduleComponent: JobCategoriesModule,
    settingsComponent: JobCategoriesModuleSettings,
    data: {
        title: '',
        subtitle: '',
        margin: 'mb-60',
        topPadding: '',
        activeCategoryId: '',
        theme: 'default',
    },
};
