import { HTMLProps, CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '/css';

interface Props {
    on: boolean;
    onChange: (val: boolean) => void;
    className?: string;
    style?: CSSProperties;
}

const Switch = ({ on, onChange, ...props }: Props) => (
    <Wrapper isOn={on} onClick={() => onChange(!on)} {...props} />
);

export default Switch;

const Wrapper = styled.button<{ isOn: boolean }>`
    display: inline-block;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 26px;
    height: 26px;
    position: relative;
    width: 58px;
    min-width: 58px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    user-select: none;
    @media (max-width: 767px) {
        border-radius: 24px;
        height: 26px;
        width: 48px;
    }

    &:after {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        position: absolute;
        top: 6px;
        left: 6px;
        background: #fff;
        border-radius: 14px;
        transition: all 0.2s ease-in-out;
    }

    ${(p) =>
        p.isOn &&
        css`
            background: ${colors.purple};
            box-shadow: none;
            &:after {
                left: 38px;
            }
        `};
`;
