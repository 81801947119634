import ImageModule from './ImageModule';
import ImageModuleSettings from './ImageModuleSettings';
import { ImageModuleType } from './types';

const moduleData: ImageModuleType = {
    _key: '',
    _type: 'image',
    title: 'Image',
    description: 'Image with size settings',
    image: '/modules/image.svg',
    moduleComponent: ImageModule,
    settingsComponent: ImageModuleSettings,
    data: {
        image: null,
        margin: 'mb-60',
        width: 600,
        roundedBorders: false,
    },
};

export default moduleData;
