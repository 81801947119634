import { memo } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import { colors } from '/css';
import { Text } from '/front';
import { TimelineModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import CallToAction from './CallToAction';
import EditableTimeline from './EditableTimeline';

export default memo(function TimelineModule({
    module,
    onChange,
}: ModuleProps<TimelineModuleType>) {
    const { steps, margin, title, description, button, callToAction, theme } =
        module.data;

    const changeData = curry((key, value) =>
        onChange({
            data: {
                ...module.data,
                [key]: value,
            },
        })
    );

    const moduleTheme = theme || 'wide';

    return (
        <Wrapper className={`theme-${moduleTheme} ${margin}`}>
            <Container className="container" medium={moduleTheme === 'narrow'}>
                <div className="content-wrapper">
                    <Text
                        value={title}
                        onChange={changeData('title')}
                        placeholder="Title"
                        className="title heading-xl mb-24"
                        tag="h5"
                        multiline={true}
                    />
                    <div className="subtitle">
                        <Text
                            value={description}
                            onChange={changeData('description')}
                            placeholder="Description"
                            className="paragraph-large mb-24"
                            tag="p"
                            multiline={true}
                        />
                    </div>
                    {moduleTheme === 'narrow' && (
                        <EditableTimeline
                            theme={moduleTheme}
                            steps={steps}
                            onChange={changeData('steps')}
                        />
                    )}
                    <CallToAction
                        callToAction={callToAction}
                        button={button}
                        onButtonChange={changeData('button')}
                        theme={moduleTheme}
                    />
                </div>
                {moduleTheme === 'wide' && (
                    <EditableTimeline
                        theme={moduleTheme}
                        steps={steps}
                        onChange={changeData('steps')}
                    />
                )}
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    &.theme-wide {
        padding: 140px 0;
        background: ${colors.purpleLighter};
        @media (max-width: 767px) {
            padding: 48px 0;
        }
        .container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .content-wrapper {
                width: calc(50% - 150px);
                @media (min-width: 768px) and (max-width: 1160px) {
                    width: 45%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                    margin-bottom: 48px;
                }
                .address-search {
                    width: 100%;
                }
            }
        }
    }
    &.theme-narrow {
        margin-left: 24px;
        margin-right: 24px;
        .container {
            padding: 40px 130px;
            background: ${colors.purpleLighter};
            border-radius: 52px;
            @media (max-width: 1000px) {
                padding: 40px 40px;
            }
            @media (max-width: 767px) {
                padding: 24px;
                border-radius: 32px;
            }
            .title {
                text-align: center;
            }
            .subtitle {
                text-align: center;
                max-width: 568px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
`;
