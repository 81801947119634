import CustomerStoriesModule from './CustomerStoriesModule';
import CustomerStoriesModuleSettings from './CustomerStoriesModuleSettings';

export default {
    _key: '',
    _type: 'customer_stories',
    title: 'Customer stories',
    description: 'Presentation of one or more customer story pages',
    image: '/modules/customerStories.svg',
    preview: '/modules/customerStories-preview.png',
    moduleComponent: CustomerStoriesModule,
    settingsComponent: CustomerStoriesModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
    },
};
