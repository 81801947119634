import HowItWorksModule from './HowItWorksModule';
import HowItWorksModuleSettings from './HowItWorksModuleSettings';
import { HowItWorksModuleType } from './types';

const moduleData: HowItWorksModuleType = {
    _key: '',
    _type: 'how_it_works',
    title: 'How it works',
    description: '',
    image: '/modules/howItWorks.svg',
    moduleComponent: HowItWorksModule,
    settingsComponent: HowItWorksModuleSettings,
    data: {
        background: '#ffffff',
        margin: 'mb-60',
        process: 'electricianJobInstallation',
        electricianJobInstallation: {
            steps: [
                {
                    key: 'configuration',
                    title: '1. Velg en jobb fra vår nettbutikk',
                    description:
                        'Se våre jobber i nettbutikken. Husk at alt leveres med vår fastprisgaranti!',
                },
                {
                    key: 'timeSelection',
                    title: '2. Tidspunkt som passer deg',
                    description:
                        'Med SpotOn er det du som bestemmer når det passer å få jobben gjort. Velg enkelt i vår digitale kalender.',
                },
                {
                    key: 'electrician',
                    title: '3. Kun kvalifiserte elektrikere',
                    description:
                        'Jobben blir gjort av lokale, kvalifiserte elektrikere som vi har håndplukket på forhånd så du slipper å bruke tid på det.',
                },
                {
                    key: 'installation',
                    title: '4. Effektiv installasjon',
                    description:
                        'Vi pakker og sender alt av elektromateriell til elektrikeren slik at jobben kan utføres effektivt hjemme hos deg.',
                },
                {
                    key: 'payment',
                    title: '5. Evaluering og betaling',
                    description:
                        'Når jobben er utført kan du evaluere jobben. Med vår SpotOnGaranti™ betaler du ikke før du er fornøyd, og dersom resultatet ikke er som avtalt kommer vi å rydder opp kostnadsfritt.',
                },
            ],
        },
        videoInspection: {
            title: 'Slik fungerer videobefaring',
            description:
                'Pek og forklar med kameraet på telefonen, og vi lager et uforpliktende tilbud innen 24 timer. Til fastpris selvsagt.',
            steps: [
                {
                    key: 'timeSelection',
                    text: 'Velg tidspunkt i vår digitale kalender. Videobefaringen tar ca. 30 minutter',
                },
                {
                    key: 'inspection',
                    text: 'Pek og forklar til en av våre eksperter ',
                },
                {
                    key: 'offer',
                    text: 'Motta uforpliktende tilbud med fastpris innen 24 timer',
                },
                {
                    key: 'electrician',
                    text: 'Velg når du ønsker at jobben skal utføres av våre eminente elektrikere',
                },
            ],
        },
    },
};

export default moduleData;
