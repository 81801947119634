import Paragraph, { ParagraphProps } from '../typography/Paragraph';

interface Props extends ParagraphProps {}

const ModalDescription = ({ className, ...props }: Props) => (
    <Paragraph
        size="medium"
        centered
        className={`mb-24 ${className}`}
        {...props}
    />
);

export default ModalDescription;
