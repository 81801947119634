import { Panel } from './types/GeoTypes';

export const panel: Panel = {
    width: 1.134,
    height: 1.762,
};

export const MAX_PANELS_PER_ORDER = 60;
export const SHOWER_KWH = 5.6;
export const SOLAR_DEPOSIT_PRICE = 2500;
export const MIN_PANELS = 2;

export const SOLAR_PAGES = {
    start: '/solceller',
    configure: '/solceller/konfigurer',
    booking: '/solceller/velg-tidspunkt',
    confirm: '/solceller/bestill',
};
