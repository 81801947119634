import JobCategoryModule from './JobCategoryModule';
import JobCategoryModuleSettings from './JobCategoryModuleSettings';

export default {
    _key: '',
    _type: 'job_category',
    title: 'Job category',
    description: 'Job category module',
    image: '/modules/jobCategory.svg',
    preview: '/modules/jobCategory-preview.png',
    moduleComponent: JobCategoryModule,
    settingsComponent: JobCategoryModuleSettings,
    data: {
        title: '',
        description: '',
        image: null,
        background: '#FFFFFF',
        categoryId: 0,
        categoryLink: null,
        margin: 'mb-60',
        trackingId: 'Job category list',
    },
};
