import RichTextModule from './RichTextModule';
import RichTextModuleSettings from './RichTextModuleSettings';
import { RichTextModuleType } from './types';
import { ContainerSizes } from '/components/Container';

const richTextModuleSettings: RichTextModuleType = {
    _key: '',
    _type: 'richText',
    title: 'Rich text',
    description: 'Text content with formating options',
    image: '/modules/richText.svg',
    moduleComponent: RichTextModule,
    settingsComponent: RichTextModuleSettings,
    data: {
        content: [],
        containerWidth: ContainerSizes.LARGE,
        margin: 'mb-60',
        textStyle: 'default',
        textAlignment: 'left',
        verticalPadding: '',
        backgroundColor: 'transparent',
    },
};

export default richTextModuleSettings;
