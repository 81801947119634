import curry from 'ramda/src/curry';

import { Row, Col, ui } from '/front';
import { ModuleProps } from '/front/types/frontModuleType';
import { IllustrationCardsModuleType } from './types';
import MarginSetting from './../_shared/MarginSetting';

export default function IllustrationCardsModuleSettings({
    module,
    onChange,
}: ModuleProps<IllustrationCardsModuleType>) {
    const change = curry((key, value) =>
        onChange({ data: { ...module.data, [key]: value } })
    );

    const { Select } = ui();

    const { margin, theme } = module.data;

    return (
        <Row>
            <Col width={[12]} className="mb-24">
                <MarginSetting margin={margin} onChange={change('margin')} />
            </Col>
            <Col width={12} className="mb-24">
                <Select
                    value={theme || 'default'}
                    options={[
                        { name: 'Default', value: 'default' },
                        {
                            name: 'Detailed (wider cards in single column with description)',
                            value: 'detailed',
                        },
                    ]}
                    onChange={change('theme')}
                    medium
                    style={{ width: '100%' }}
                    label="Theme"
                />
            </Col>
        </Row>
    );
}
