import ResourceType from '../types/resourceType';
import usePostCodeState from './usePostCodeState';
import { useOrderState } from '/store/orderStore';
import { JobTemplate, JobTemplateType } from '/types/jobTemplateTypes';
import { TemplateJobLineItem } from '/types/lineItemTypes';

interface Props {
    resourceType: ResourceType;
    supportedJobTypes?: JobTemplate['typeKey'][];
}

export default function useIsJobSupported({
    resourceType,
    supportedJobTypes,
}: Props) {
    const { postCode, postCodeValid, postCodeSupportedJobTypes } =
        usePostCodeState();

    const cart = useOrderState((state) => state.cart);

    return (jobTypeKey: JobTemplate['typeKey']) => {
        if (resourceType === 'cart') {
            if (!postCode) {
                // post code not checked yet
                return true;
            }
            if (
                postCode &&
                postCodeValid &&
                !postCodeSupportedJobTypes.length
            ) {
                // for states before heat pump release
                return true;
            }
            if (postCode && !postCodeSupportedJobTypes.includes(jobTypeKey)) {
                // post code checked and job type NOT supported BUT...
                // if supported job type is a heat pump job
                // we will enavle electrician jobs even tho they are not supported
                if (
                    cart?.lineItems?.find((li) =>
                        li.type === 'JOB'
                            ? li.data.typeKey === JobTemplateType.HEAT_PUMP
                            : false
                    ) &&
                    postCodeSupportedJobTypes.includes(
                        JobTemplateType.HEAT_PUMP
                    ) &&
                    jobTypeKey === JobTemplateType.ELECTRICIAN
                ) {
                    return true;
                }

                // post code checked and job type NOT supported
                return false;
            }

            // supported by default unles no support detected
            return true;
        }

        if (!supportedJobTypes) {
            //suported types not provided (support by default)
            return true;
        }

        if ((supportedJobTypes || []).length === 0) {
            //suported types not provided (support by default)
            return true;
        }

        // support based on supportedJobTypes & type key
        return supportedJobTypes.includes(jobTypeKey);
    };
}
