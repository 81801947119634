import { useState, useEffect } from 'react';
import config from '/front/config';
import Menu from '/front/types/frontMenuType';

export default function useMenus() {
    const [menus, setMenus] = useState<Menu[]>([]);

    useEffect(() => {
        const fetchMenus = async () => {
            const menusResp = await config.api.menu.get();
            setMenus('error' in menusResp ? [] : menusResp);
        };
        fetchMenus();
    }, []);

    return menus;
}
