import useWindowWidth from '/hooks/useWindowWidth';

interface Sizes {
    large: boolean;
    medium: boolean;
    small: boolean;
}
export default function useScreenSize(): Sizes {
    const windowWidth = useWindowWidth();

    return {
        large: windowWidth >= 1024,
        medium: windowWidth > 767 && windowWidth < 1024,
        small: windowWidth <= 767,
    };
}
