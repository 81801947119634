import TipModule from './TipModule';
import TipModuleSettings from './TipModuleSettings';

export default {
    _key: '',
    _type: 'tip',
    title: 'Tip',
    description: 'Call to action with heading, description & button',
    image: '/modules/tip.svg',
    preview: '/modules/tip-preview.jpg',
    moduleComponent: TipModule,
    settingsComponent: TipModuleSettings,
    data: {
        description: '',
        heading: '',
        backgroundColor: '#DCFBD5',
        textAlignment: 'center',
        textColor: '#333333',
        backgroundImage: null,
        margin: 'mb-60',
        button: null,
        overlayColor: 'black',
        overlayOpacity: 0,
    },
};
