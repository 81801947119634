import CarouselModule from './CarouselModule';
import CarouselModuleSettings from './CarouselModuleSettings';

export default {
    _key: '',
    _type: 'carousel',
    title: 'Carousel',
    description: 'Sliding images with heading & call to action',
    image: '/modules/carousel.svg',
    preview: '/modules/carousel-preview.png',
    moduleComponent: CarouselModule,
    settingsComponent: CarouselModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
    },
};
