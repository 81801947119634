import {
    useState,
    useEffect,
    useRef,
    forwardRef,
    useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import Button from '/components/ui/buttons/Button';
import Icon from '/components/ui/Icon';
import useWindowWidth from '/hooks/useWindowWidth';
import { effects, colors } from '/css';
import useUnsavedChanges from '/hooks/useUnsavedChanges';

const Carousel = (
    {
        children,
        onChange = () => null,
        className,
        withDots,
        withArrows,
        withNumbers,
        startIndex = 0,
        detectUnsavedChanges = false,
    },
    ref
) => {
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const [current, setCurrent] = useState(startIndex + 1);
    const [slotSizes, setSlotSizes] = useState([]);
    const [ready, setReady] = useState(false);

    useImperativeHandle(ref, () => ({
        move: move,
    }));

    const handlers = useSwipeable({
        onSwipedLeft: () => move('NEXT'),
        onSwipedRight: () => move('PREV'),
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
    });

    const wrapperRef = useRef();

    const wrapperRefPassthrough = (el) => {
        // call useSwipeable ref prop with el
        handlers.ref(el);
        // set wrapperRef el so we can access it
        wrapperRef.current = el;
    };

    const windowWidth = useWindowWidth();

    const { confirmNavigation, areUnsavedChanges } = useUnsavedChanges();

    const items = Array.isArray(children) ? children : [children];

    const slots = (() => {
        return [
            items[items.length - 1], // add last to support looping
            ...items,
            items[0], // add first to support looping
        ];
    })();

    useEffect(() => {
        // if start index is null it means that we are not ready yet...
        if (startIndex !== null) setTimeout(() => setReady(true), 300);
    }, [startIndex]);

    useEffect(() => {
        if (!wrapperRef?.current) return;

        setWrapperWidth(wrapperRef.current.offsetWidth);

        const images = [...wrapperRef.current.querySelectorAll('img')];

        if (images.length) {
            let areLoadedImages = false;
            images.forEach((img) => {
                if (!img.complete || img.naturalHeight === 0) {
                    img.addEventListener('load', slotSizeSetup);
                } else {
                    areLoadedImages = true;
                }
            });
            if (areLoadedImages) slotSizeSetup();
        } else {
            const wrapperChildren = [...wrapperRef.current.children];
            wrapperChildren.forEach(slotSizeSetup);
        }
    }, [wrapperRef?.current?.offsetWidth, children, windowWidth]);

    useEffect(() => {
        if (startIndex) quickMove(startIndex + 1);
    }, [startIndex]);

    const slotSizeSetup = () =>
        setSlotSizes(
            [...(wrapperRef.current?.children || [])].reduce((acc, item) => {
                return [
                    ...acc,
                    {
                        w: item.offsetWidth,
                        h: item.offsetHeight,
                        bcr: item.getBoundingClientRect(),
                    },
                ];
            }, [])
        );

    const move = (dir) => {
        if (slots.length < 4) return;

        if (detectUnsavedChanges) {
            if (areUnsavedChanges() && !confirmNavigation()) return;
        }

        const newIndex = (() => {
            switch (dir) {
                case 'PREV':
                    return current - 1;
                case 'NEXT':
                    return current + 1;
                case 'LAST':
                    return slots.length - 2;
                default:
                    current;
            }
        })();

        const loopIndex = !newIndex
            ? slots.length - 2
            : newIndex > slots.length - 2
            ? 1
            : 0;
        setCurrent(newIndex);
        // loop support - don't emit change event when about to loop
        !loopIndex
            ? onChange(newIndex - 1)
            : setTimeout(() => {
                  quickMove(loopIndex);
                  onChange(loopIndex - 1);
              }, 300);
    };

    const quickMove = (index) => {
        if (!wrapperRef?.current) return;
        wrapperRef.current.classList.add('no-transition');
        setCurrent(index);
        setTimeout(() => {
            if (!wrapperRef?.current) return;
            wrapperRef.current.offsetHeight; // Trigger a reflow, flushing the CSS changes
            wrapperRef.current.classList.remove('no-transition');
        }, 300);
    };

    const numberInfo = () => {
        let cur = current;
        let max = slots.length - 2;
        if (cur < 0) cur = 1;
        if (cur > max) cur = max;
        return `${cur} / ${max}`;
    };

    return (
        <div className={className} style={{ position: 'relative' }}>
            <CarouselWrapper
                {...handlers}
                ref={wrapperRefPassthrough}
                className={[
                    'carousel',
                    ready && 'ready',
                    `slot-count-${items.length}`,
                ]
                    .filter((c) => c)
                    .join(' ')}
                style={{
                    height: slotSizes[current]
                        ? slotSizes[current].h + 'px'
                        : 'auto',
                }}
            >
                {slots.map((slot, i) => (
                    <div
                        key={i}
                        className={`slot`}
                        style={{
                            transform: `translateX(${
                                (i - current) * wrapperWidth
                            }px)`,
                        }}
                    >
                        {slot}
                    </div>
                ))}
            </CarouselWrapper>
            {withDots && slots.length > 3 && (
                <Dots className="dots">
                    {slots.map((slot, i) => (
                        <div
                            key={i}
                            className={`dot ${current === i ? 'current' : ''}`}
                            onClick={() => {
                                setCurrent(i);
                                onChange(i - 1);
                            }}
                        />
                    ))}
                </Dots>
            )}
            {withArrows && slots.length > 3 && (
                <Arrows className="carousel-arrows">
                    <Button
                        className="arrow-left"
                        variant="tertiary"
                        size="small"
                        icon={<Icon icon="arrow-left" />}
                        onClick={() => move('PREV')}
                    />
                    <Button
                        className="arrow-right"
                        variant="tertiary"
                        size="small"
                        icon={<Icon icon="arrow-right" />}
                        onClick={() => move('NEXT')}
                    />
                </Arrows>
            )}
            {withNumbers && (
                <Numbers className="carousel-numbers">{numberInfo()}</Numbers>
            )}
        </div>
    );
};

export default forwardRef(Carousel);

const CarouselWrapper = styled.div`
    width: 100%;
    transition: height 0.15s ease-in-out;
    position: relative;
    overflow: hidden;
    .slot {
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        user-select: none;
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
        img {
            pointer-events: none;
            display: block;
            margin: 0;
        }
    }
    &.ready {
        .slot {
            opacity: 1;
        }
    }
    &.no-transition {
        // dont transition when looping
        .slot {
            transition: none !important;
        }
    }
`;

const Dots = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 16px;
    .dot {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background: rgba(0, 0, 0, 0.25);
        margin: 0 8px;
        cursor: pointer;
        transition: background 0.15s ease-in-out;
        &.current {
            background: #333333;
        }
        &:first-child,
        &:last-child {
            display: none; // loop ghots dot items
        }
    }
`;

const Arrows = styled.div`
    @media (max-width: 767px) {
        display: none;
    }
    > button {
        position: absolute;
        top: 50%;
        transform: translateY(calc(-50% - 18px));
        padding-left: 8px;
        padding-right: 8px;
        &:first-child {
            left: -70px;
        }
        &:last-child {
            right: -70px;
        }
        border-color: #fff !important;
        ${effects.shadow};
        .button-icon {
            margin: 0;
        }
        &:hover {
            box-shadow: none;
            border: 3px solid ${colors.purple}!important;
        }
    }
`;

const Numbers = styled.div`
    position: absolute;
    right: 17px;
    bottom: 17px;
    font-size: 15px;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    padding: 6px 17px 8px;
`;
