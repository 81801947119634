import styled from 'styled-components';
import Image from 'next/image';
import Button from '/components/ui/buttons/Button';
import { useState } from 'react';
import ContactCallbackModuleModal from './ContactCallbackModule.modal';
import { success } from '/components/ui/toast';
import { mobile } from '/css/breakpoints';
import Text from '/front/presentation/Text';
import Container, { ContainerSizes } from '/components/Container';
import contactApi from '/api/contactApi';
import { ContactCallbackModuleType } from './type';
import curry from 'ramda/src/curry';
import { ModuleProps } from '/front/types/frontModuleType';
import SolarContactFormButton from './SolarContactFormButton';

interface Props {
    data: ContactCallbackModuleType['data'];
    onChange?: ModuleProps<ContactCallbackModuleType>['onChange'];
    containerSize?: ContainerSizes;
    className?: string;
    modalStateOverride?: [boolean, (val: boolean) => void];
}

const ContactCallbackModule = ({
    data,
    onChange = () => null,
    containerSize,
    className,
    modalStateOverride,
}: Props) => {
    const [modalOpenInternal, setModalOpenInternal] = useState(false);

    const [modalOpen, setModalOpen] = modalStateOverride || [
        modalOpenInternal,
        setModalOpenInternal,
    ];

    const change = curry((key, value) =>
        onChange({ data: { ...data, [key]: value } })
    );

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleSubmit = async (
        formData: { name: string; phoneNumber: string; description: string },
        errorCallback: (errors: any) => void
    ) => {
        const response = await contactApi.requestCallback(
            formData.name,
            formData.phoneNumber,
            data.type,
            formData.description
        );

        if (response.error) {
            errorCallback(response.errors);
            return;
        }

        setModalOpen(false);
        success(
            'Takk for din henvendelse, vi kontakter deg så fort som mulig!'
        );
    };

    return (
        <Container
            size={containerSize || data.containerSize}
            className={[className, data.margin].join(' ')}
        >
            <Component>
                <div>
                    <Image
                        src="/images/illustrations/yoga.svg"
                        alt="Illustrasjon av yoga"
                        width={0}
                        height={0}
                    />
                </div>
                <div className="content-col">
                    <div>
                        <Text
                            tag="h4"
                            value={data.title}
                            placeholder="Title"
                            className="heading-medium mb-16"
                            onChange={change('title')}
                        />
                        <Text
                            tag="p"
                            className="paragraph-medium mb-24"
                            value={data.description}
                            onChange={change('description')}
                        />
                    </div>

                    {data.type === 'solar' ? (
                        <SolarContactFormButton
                            modalStateOverride={modalStateOverride}
                        />
                    ) : (
                        <>
                            <Button
                                variant="tertiary"
                                onClick={handleOpenModal}
                            >
                                {data.buttonText}
                            </Button>
                            {modalOpen && (
                                <ContactCallbackModuleModal
                                    onClose={() => setModalOpen(false)}
                                    onSubmit={handleSubmit}
                                    formTitle={data.modalTitle}
                                    formDescription={data.modalDescription}
                                />
                            )}
                        </>
                    )}
                </div>
            </Component>
        </Container>
    );
};

const Component = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;

    ${mobile} {
        grid-template-columns: 1fr;
    }

    .content-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        ${mobile} {
            align-items: center;
            margin-top: 32px;
        }

        button {
            width: auto;
            max-width: 100%;
            div {
                word-break: break-word;
                white-space: break-spaces;
            }

            ${mobile} {
                margin-top: 24px;
                width: 100%;
            }
        }
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
    }
`;

export default ContactCallbackModule;
