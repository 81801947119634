import { memo, useState, useEffect } from 'react';
import styled from 'styled-components';

import { PromoBannerModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import Banner from './banner/Banner';
import MoreInfo from './banner/MoreInfo';

export default memo(function PromoBannerModule({
    module,
    onChange,
}: ModuleProps<PromoBannerModuleType>) {
    const { margin } = module.data;

    const [showingMoreInfo, setShowingMoreInfo] = useState<boolean>(false);

    useEffect(() => {
        const onKeyup = (e: KeyboardEvent) =>
            e.key === 'Escape' ? hideMore() : null;
        window.addEventListener('keyup', onKeyup);
        return () => {
            window.removeEventListener('keyup', onKeyup);
            hideMore();
        };
    }, []);

    const showMore = () => {
        document.getElementsByTagName('html')[0].classList.add('ft-oh');
        setShowingMoreInfo(true);
    };

    const hideMore = () => {
        document.getElementsByTagName('html')[0].classList.remove('ft-oh');
        setShowingMoreInfo(false);
    };

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLElement;
        if (target.id === 'banner-modal') {
            hideMore();
        }
    };

    return (
        <div className={`${margin} flex `}>
            <Badge className="centered">
                <Banner onClick={showMore} />
            </Badge>
            {showingMoreInfo && (
                <MoreInfoWrapper id="banner-modal" onMouseDown={onMouseDown}>
                    <MoreInfo />
                </MoreInfoWrapper>
            )}
        </div>
    );
});

const Badge = styled.div`
    cursor: pointer;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    svg {
        display: block;
        @media (max-width: 767px) {
            width: 280px;
        }
    }
    @media (pointer: fine) {
        &:hover {
            background: rgba(68, 215, 203, 50%);
            box-shadow: 0px 0px 15px rgba(68, 215, 203, 50%);
        }
    }
`;

const MoreInfoWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.48);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABGdBTUEAALGPC/xhBQAAAQBJREFUSA2tldENgjAQQIkJI8AejuAE/jML/25jHMT45QYO4AIm+K56eiS00F4vuVwJ6XttaWnTfGOapp22a1TlBSgPR6BXal8JPsI5w2sbMZE3UuJOuiT0HwVEvMhDGDCNnhS4RLGEvhY+zFaDly5JEq6mUskmeKkkC54rKYJvlbjga5Iq8ITkhEBC9vl8K2qn3ArIbuG6cB0MVB25CB6k68QrN1Rg9oQKXKL4xKfgA2C7XD4JMDvy3wetIonBdXouyRo8Ien0XbRuhStgYSZxSS48S1IKN5IOhr20/jPxwpMS4HInX8gq/xY4ek6etPdBTqMlPxe0DsdRYclyBfgbXpalja44wn4AAAAASUVORK5CYII='),
        auto;
    > div {
        position: relative;
        cursor: default;
    }
`;
