import { memo } from 'react';
import styled from 'styled-components';

import Container from '/components/Container';
import { Text } from '/front';
import { QuoteModuleType } from './types';
import { colors } from '/css';
import { ModuleProps } from '/front/types/frontModuleType';

export default memo(function QuoteModule({
    module,
    onChange,
}: ModuleProps<QuoteModuleType>) {
    const { margin, backgroundColor, text, author, theme, containerWidth } =
        module.data;

    const isMinimal = theme === 'minimal';

    return (
        <div
            className={[!isMinimal && 'pt-80 pb-80', margin]
                .filter((x) => x)
                .join(' ')}
            style={
                !isMinimal ? { backgroundColor: backgroundColor } : undefined
            }
        >
            <StyledContainer
                medium={containerWidth === 'medium'}
                small={containerWidth === 'small'}
                className={`theme-${theme}`}
            >
                <div>
                    <Text
                        value={text}
                        onChange={(val: string) =>
                            onChange({ data: { ...module.data, text: val } })
                        }
                        placeholder="Quote"
                        multiline
                        className={
                            !isMinimal
                                ? `heading-xl text-align-center mb-24`
                                : `paragraph-large  mb-24`
                        }
                        tag="div"
                    />
                    <Text
                        value={author}
                        onChange={(val: string) =>
                            onChange({
                                data: { ...module.data, author: val },
                            })
                        }
                        placeholder="Author"
                        multiline
                        className={`author paragraph-${
                            !isMinimal ? 'medium text-align-center' : 'small'
                        } weak mr-8`}
                        tag="p"
                    />
                </div>
            </StyledContainer>
        </div>
    );
});

const StyledContainer = styled(Container)`
    &.theme-minimal > div {
        border-left: 3px solid ${colors.purple};
        padding-left: 24px;
    }
    .author:before {
        content: '— ';
        left: -100%;
    }
    .editable-wrapper.empty .author:first-of-type {
        &:before {
            content: '';
        }
    }
`;
