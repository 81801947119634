import SolarInstallationDetailsModule from './SolarInstallationDetailsModule';
import SolarInstallationDetailsModuleSettings from './SolarInstallationDetailsModuleSettings';
import { SolarInstallationDetailsModuleType } from './types';
import { ContainerSizes } from '/components/Container';

const moduleData: SolarInstallationDetailsModuleType = {
    _key: '',
    _type: 'solar_installation_details',
    title: 'Solar installation details',
    description: 'Module describing details of sollar pannel installation',
    image: '/modules/solar-info.svg',
    preview: '',
    moduleComponent: SolarInstallationDetailsModule,
    settingsComponent: SolarInstallationDetailsModuleSettings,
    data: {
        margin: 'mb-60',
        containerSize: ContainerSizes.LARGE,
        cards: {
            fuseBox: {
                title: '',
                description: '',
                content: [],
            },
            pannels: {
                title: '',
                description: '',
                content: [],
            },
            inverter: {
                title: '',
                description: '',
                content: [],
            },
        },
    },
};

export default moduleData;
