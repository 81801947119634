import GoodBetterBestModule from './GoodBetterBestModule';
import GoodBetterBestModuleSettings from './GoodBetterBestModuleSettings';

export default {
    _key: '',
    _type: 'good_better_best',
    title: 'Good better best',
    description: 'Usefull to compare products',
    image: '/modules/goodBetterBest.svg',
    preview: '/modules/goodBetterBest-preview.png',
    moduleComponent: GoodBetterBestModule,
    settingsComponent: GoodBetterBestModuleSettings,
    data: {
        margin: 'mb-60',
        withTitle: true,
        title: '',
        withButton: true,
        button: null,
        background: '#DCFBD5',
        items: [],
        trackingId: 'Good better best job list',
    },
};
