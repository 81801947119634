import { memo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import { RichText, Text, ui } from '/front';
import Icon from '/components/ui/Icon';
import { effects } from '/css';
import { changeArrayItemPosition } from '/lib/helpers';
import id from '/front/lib/id';

const CardIcon = ({ iconType, iconColor, index }) => (
    <>
        {iconType === 'spoton-logo' && (
            <Icon icon="spoton" fill={iconColor} className="mr-24" />
        )}
        {iconType === 'number' && (
            <span className="number mr-24" style={{ background: iconColor }}>
                {index + 1}
            </span>
        )}
    </>
);

export default memo(function CardsModule({ module, onChange, addAction }) {
    const { margin, items, iconType, iconColor, columnCount } = module.data;

    const { editMode, Actions } = ui();

    const newItem = {
        _key: id(),
        title: '',
        content: '',
    };

    useEffect(() => {
        if (editMode && !items.length) addItem();
    }, [editMode]);

    useEffect(() => {
        if (editMode) addAction('onAdd', addItem);
    }, [module.data.items, editMode]); // update action everythime module items change to ensure access to up to date module data in the callback

    const addItem = () =>
        onChange({
            data: { ...module.data, items: [...module.data.items, newItem] },
        });

    const removeItem = (index) =>
        onChange({
            data: {
                ...module.data,
                items: module.data.items.filter((_, i) => i !== index),
            },
        });

    const changeItem = curry((index, key, value) =>
        onChange({
            data: {
                ...module.data,
                items: items.map((item, i) => {
                    if (i !== index) return item;
                    return { ...item, [key]: value };
                }),
            },
        })
    );

    // to provide item keys for content prevoiusly saved without item keys
    const addMissingKeys = (items) =>
        items.map((item) => ({ ...item, _key: item._key || id() }));

    const moveItems = useCallback(
        (currentIndex, dir) => {
            const newIndex = dir === -1 ? currentIndex - 1 : currentIndex + 1;
            const newItems = changeArrayItemPosition(
                items,
                currentIndex,
                newIndex
            );
            onChange({
                data: { ...module.data, items: addMissingKeys(newItems) },
            });
        },
        [items]
    );

    return (
        <Wrapper className={`${margin} column-count-${columnCount}`}>
            <Container medium={columnCount === '1'}>
                <div className="cards-container">
                    {items.map((item, index) => (
                        <div className="card" key={item._key || index}>
                            <div className="p-48">
                                <div className="title">
                                    <CardIcon
                                        iconType={iconType}
                                        iconColor={iconColor}
                                        index={index}
                                    />
                                    <Text
                                        value={item.title}
                                        onChange={changeItem(index)('title')}
                                        placeholder="Card title"
                                        multiline
                                        className={`heading-large mb-24`}
                                        tag="h5"
                                    />
                                </div>
                                <div className="content">
                                    <RichText
                                        className="rich-text"
                                        value={item.content}
                                        onChange={changeItem(index)('content')}
                                        disabledOptions={['image']}
                                    />
                                </div>
                                <Actions
                                    onDelete={
                                        items.length > 1
                                            ? () => removeItem(index)
                                            : null
                                    }
                                    onMoveLeft={
                                        index
                                            ? () => moveItems(index, -1)
                                            : null
                                    }
                                    onMoveRight={
                                        index < items.length - 1
                                            ? () => moveItems(index, 1)
                                            : null
                                    }
                                    align="top-right"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .cards-container {
        column-count: 2;
        column-gap: 0;
        margin: -12px -12px 0 -12px;
        @media (max-width: 767px) {
            column-count: 1;
        }

        .card {
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            padding: 12px;
            break-inside: avoid;
            position: relative;
            > div {
                background: #fff;
                ${effects.shadow};
                border-radius: 12px;
                .title {
                    display: flex;
                    .heading-large,
                    .editable-wrapper {
                        width: 100%;
                    }
                    > svg {
                        width: 42px;
                        min-width: 42px;
                        height: 42px;
                        min-height: 42px;
                        bottom: -6px;
                        position: relative;
                        @media (max-width: 767px) {
                            width: 32px;
                            height: 32px;
                            min-width: 32px;
                            min-height: 32px;
                            bottom: -5px;
                        }
                        @media (max-width: 767px) {
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            min-height: 24px;
                            bottom: -5px;
                        }
                    }
                    .number {
                        color: #fff;
                        width: 42px;
                        min-width: 42px;
                        height: 42px;
                        line-height: 1;
                        padding-bottom: 5px;
                        font-size: 26px;
                        display: block;
                        border-radius: 90px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-self: flex-start;
                        margin-top: 6px;
                        @media (min-width: 768px) and (max-width: 1024px) {
                            font-size: 24px;
                            width: 32px;
                            min-width: 32px;
                            height: 32px;
                        }
                        @media (max-width: 767px) {
                            font-size: 16px;
                            width: 24px;
                            min-width: 24px;
                            height: 24px;
                            padding-bottom: 3px;
                        }
                    }
                }
                .content {
                }
            }
        }
    }

    &.column-count-1 {
        .cards-container {
            column-count: 1;
        }
    }
`;
