import IllustrationCardsModule from './IllustrationCardsModule';
import IllustrationCardsModuleSettings from './IllustrationCardsModuleSettings';
import { IllustrationCardsModuleType } from './types';

const moduleData: IllustrationCardsModuleType = {
    _key: '',
    _type: 'illustration_cards',
    title: 'Illustration cards',
    description: 'Grid of cards with text, image and link support',
    image: '/modules/illustrationCards.svg',
    preview: '/modules/illustrationCards-preview.png',
    moduleComponent: IllustrationCardsModule,
    settingsComponent: IllustrationCardsModuleSettings,
    data: {
        margin: 'mb-60',
        items: [],
        theme: 'default',
    },
};

export default moduleData;
