import ModuleType from '/front/types/frontModuleType';
import ButtonType from '/front/types/frontButtonType';
import { TimelineStepProps } from '/components/ui/Timeline';
import { Margin } from './../_shared/MarginSetting';

export enum CallToAction {
    BUTTON = 'button',
    SOLAR = 'solar',
    SOLAR_BUTTON = 'solar-button',
    SOLAR_CONTACT = 'solar-contact',
}

export type Theme = 'wide' | 'narrow';

export interface TimelineModuleType extends ModuleType {
    data: {
        steps: TimelineStepProps[];
        margin: Margin;
        title: string;
        description: string;
        button: ButtonType | null;
        callToAction: CallToAction;
        theme: Theme;
    };
}
