const objectPick = (keys: string[] | object, target: { [k: string]: any }) => {
    const pickKeys = Array.isArray(keys) ? keys : Object.keys(keys);

    return pickKeys.reduce((p, c) => {
        return {
            ...p,
            [c]: target[c],
        };
    }, {});
};

export default objectPick;
