import { ui, Row, Col } from '/front';
import { TogglesModuleType, Theme } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import MarginSetting from './../_shared/MarginSetting';
import ContainerSizeSetting from './../_shared/ContainerSizeSetting';
import { ContainerSizes } from '/components/Container';

type Data = TogglesModuleType['data'];

export default function TogglesModuleSettings({
    module,
    onChange,
}: ModuleProps<TogglesModuleType>) {
    const { Select, Switch } = ui();

    const change =
        <K extends keyof Data>(key: K) =>
        (value: Data[K]) =>
            onChange({ data: { ...module.data, [key]: value } });

    const {
        margin,
        containerSize,
        listTitle,
        listSubtitle,
        theme,
        withReadMoreLink,
    } = module.data;

    return (
        <>
            <Row className="mb-24">
                <Col width={[6, 12]} className="mb-24">
                    <ContainerSizeSetting
                        containerSize={containerSize}
                        onChange={change('containerSize')}
                    />
                </Col>
                <Col width={[6, 12]} className="mb-24">
                    <MarginSetting
                        margin={margin}
                        onChange={change('margin')}
                    />
                </Col>
                <Col width={[12]} className="mb-24">
                    <Select
                        value={theme || Theme.DEFAULT}
                        options={[
                            { name: 'Default', value: Theme.DEFAULT },
                            {
                                name: 'Large Title + background and vertical padding',
                                value: Theme.PADDED_BACKGROUND,
                            },
                        ]}
                        onChange={change('theme')}
                        medium
                        style={{ width: '100%' }}
                        label="Theme"
                    />
                </Col>
                <Col
                    width={[6, 12]}
                    className="mb-24"
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: '4px',
                    }}
                >
                    <Switch
                        on={listTitle}
                        onChange={change('listTitle')}
                        style={{ marginRight: '8px' }}
                    />{' '}
                    With list title
                </Col>
                <Col
                    width={[6, 12]}
                    className="mb-24"
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: '4px',
                    }}
                >
                    <Switch
                        on={listSubtitle}
                        onChange={change('listSubtitle')}
                        style={{ marginRight: '8px' }}
                    />{' '}
                    With list subtitle
                </Col>
                <Col
                    width={[6, 12]}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        paddingBottom: '4px',
                    }}
                >
                    <Switch
                        on={withReadMoreLink}
                        onChange={change('withReadMoreLink')}
                        style={{ marginRight: '8px' }}
                    />{' '}
                    With read more link
                </Col>
            </Row>
        </>
    );
}
