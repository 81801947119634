import timelineModule from './TimelineModule';
import timelineModuleSettings from './TimelineModuleSettings';
import { TimelineModuleType, CallToAction } from './types';

const moduleData: TimelineModuleType = {
    _key: '',
    _type: 'timeline',
    title: 'Timeline',
    description: 'Timeline with title, description & CTA',
    image: '/modules/timeline.svg',
    preview: '',
    moduleComponent: timelineModule,
    settingsComponent: timelineModuleSettings,
    data: {
        steps: [],
        margin: 'mb-60',
        title: '',
        description: '',
        button: null,
        callToAction: CallToAction.BUTTON,
        theme: 'wide',
    },
};

export default moduleData;
