import styled from 'styled-components';

import { colors } from '/css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    active?: boolean;
}

export default function Skeleton({ active = true, children, ...rest }: Props) {
    if (!active) return children as JSX.Element;

    return <Wrapper {...rest}>{children}</Wrapper>;
}

const Wrapper = styled.div`
    position: relative;
    min-width: 24px;
    min-height: 24px;
    background: ${colors.lightGrey};
    border-radius: 12px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        bottom: 0;
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0) 100%
        );
        display: block;
        transform: translateX(-75%);
        animation: shine 1.5s ease-in-out 0s infinite;
    }
    @keyframes shine {
        0% {
            transform: translateX(-75%);
        }
        100% {
            transform: translateX(175%);
        }
    }
`;
