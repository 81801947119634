import { HTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components';

import Icon from '/components/ui/Icon';
import { colors, effects } from '/css';

interface CardProps extends HTMLAttributes<HTMLElement> {
    onClick: () => void;
    active: boolean;
}

const InfoIcon = forwardRef<HTMLDivElement, CardProps>(
    ({ onClick, active }, ref) => {
        return (
            <Wrapper
                ref={ref}
                onClick={onClick}
                className={['info-icon', active && 'active']
                    .filter((c) => c)
                    .join(' ')}
            >
                <Icon icon="info" />
            </Wrapper>
        );
    }
);

InfoIcon.displayName = 'InfoIcon';

export default InfoIcon;

const Wrapper = styled.div`
    position: relative;
    height: 36px;
    svg {
        position: relative;
        z-index: 12;
        width: 36px;
        height: 36px;
        ${effects.shadow};
        border-radius: 36px;
        circle {
            fill: white;
        }
        path {
            fill: ${colors.purple};
        }
    }
    &.active {
        svg {
            box-shadow: none;
            circle {
                fill: ${colors.purple};
            }
            path {
                fill: #fff;
            }
        }
    }
`;
