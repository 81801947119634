import { memo } from 'react';
import { ContactCallbackModuleType } from './type';
import ContactCallback from './ContactCallback';
import { ModuleProps } from '/front/types/frontModuleType';

const ContactCallbackModule = ({
    module,
    onChange,
}: ModuleProps<ContactCallbackModuleType>) => {
    return <ContactCallback data={module.data} onChange={onChange} />;
};

export default memo(ContactCallbackModule);
