import { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';
import debounce from 'lodash/debounce';

import Container, { ContainerSizes } from '/components/Container';
import { percentToNum } from '/front/lib/helpers';
import InfoCard from './InfoCard';
import Line from './Line';
import House from './House';
import InfoIcon from './InfoIcon';
import ItemCarousel from '/components/layout/ItemCarousel';
import { SolarInstallationDetailsModuleType, CardName } from './types';
import { ModuleProps } from '/front/types/frontModuleType';

interface LinePoints {
    fuse: { x1: number; y1: number; x2: number; y2: number };
    pannel: { x1: number; y1: number; x2: number; y2: number };
    inverter: { x1: number; y1: number; x2: number; y2: number };
}

const getPoints = (
    houseRef: any,
    cardRef: any,
    housePointXPercent: number,
    housePointYPercent: number
) => {
    const house = houseRef.current;
    const card = cardRef.current;
    if (!house || !card) return { x1: 0, y1: 0, x2: 0, y2: 0 };

    return {
        x1: percentToNum(housePointXPercent, house.clientWidth),
        y1: percentToNum(housePointYPercent, house.clientHeight),
        x2: -house.offsetLeft + card.offsetLeft + card.clientWidth / 2,
        y2: -house.offsetTop + card.offsetTop + card.clientHeight / 2,
    };
};

export default memo(function SolarInstallationDetailsModule({
    module,
    onChange,
}: ModuleProps<SolarInstallationDetailsModuleType>) {
    const houseRef = useRef<HTMLDivElement>(null);
    const fuseCardRef = useRef<HTMLDivElement>(null);
    const pannelCardRef = useRef<HTMLDivElement>(null);
    const inverterCardRef = useRef<HTMLDivElement>(null);

    const [points, setPoints] = useState<LinePoints>({
        fuse: { x1: 0, y1: 0, x2: 0, y2: 0 },
        pannel: { x1: 0, y1: 0, x2: 0, y2: 0 },
        inverter: { x1: 0, y1: 0, x2: 0, y2: 0 },
    });
    const [carouselIndex, setCarouselIndex] = useState<number>(0);

    const setLinePoints = () => {
        setPoints({
            fuse: getPoints(houseRef, fuseCardRef, 27, 48),
            pannel: getPoints(houseRef, pannelCardRef, 60, 32),
            inverter: getPoints(houseRef, inverterCardRef, 13, 74),
        });
    };

    useEffect(() => {
        const onResize = debounce(setLinePoints, 100);

        window.addEventListener('resize', onResize);

        setLinePoints();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [module.data]);

    const changeCard = curry((card: CardName, key, value) =>
        onChange({
            data: {
                ...module.data,
                cards: {
                    ...module.data.cards,
                    [card]: {
                        ...module.data?.cards[card],
                        [key]: value,
                    },
                },
            },
        })
    );

    const cardProps = (cardName: CardName) => ({
        name: cardName,
        change: changeCard(cardName),
        onExpandToggle: setLinePoints,
        card: module.data.cards[cardName],
        reset: carouselIndex,
    });

    const onIconClick = (newCarouselIndex: number) => {
        setCarouselIndex(newCarouselIndex);
    };

    const scalesForContainerSize: { [k: string]: number } = {
        large: 1,
        medium: 0.85,
        mediumSmall: 0.8,
        small: 0.75,
        smaller: 0.7,
    };

    return (
        <Container
            className={`${module.data.margin}`}
            size={module.data.containerSize || ContainerSizes.LARGE}
        >
            <Wrapper>
                <div className="top-info-container">
                    <div ref={fuseCardRef}>
                        <InfoCard {...cardProps('fuseBox')} />
                        <InfoIcon
                            active={carouselIndex === 0}
                            onClick={() => onIconClick(0)}
                        />
                    </div>
                    <div ref={pannelCardRef}>
                        <InfoCard {...cardProps('pannels')} />
                        <InfoIcon
                            active={carouselIndex === 1}
                            onClick={() => onIconClick(1)}
                        />
                    </div>
                </div>
                <div className="house" ref={houseRef}>
                    <House
                        scale={
                            scalesForContainerSize[module.data.containerSize] ||
                            1
                        }
                    />
                    <Line points={points.fuse} />
                    <Line points={points.pannel} />
                    <Line points={points.inverter} />
                </div>
                <div className="bottom-info-container">
                    <div ref={inverterCardRef}>
                        <InfoCard {...cardProps('inverter')} />
                        <InfoIcon
                            active={carouselIndex === 2}
                            onClick={() => onIconClick(2)}
                        />
                    </div>
                </div>
            </Wrapper>
            <CarouselWrapper>
                <ItemCarousel
                    className="card-carousel"
                    onStage={[1]}
                    startIndex={carouselIndex}
                    itemPixelGap={12}
                    onChange={(i: number) => setCarouselIndex(i)}
                >
                    <InfoCard {...cardProps('fuseBox')} />
                    <InfoCard {...cardProps('pannels')} />
                    <InfoCard {...cardProps('inverter')} />
                </ItemCarousel>
            </CarouselWrapper>
        </Container>
    );
});

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    .house {
        max-width: 545px;
        position: relative;
        z-index: 10;
        @media (min-width: 768px) and (max-width: 1024px) {
            max-width: 445px;
        }
        @media (max-width: 767px) {
            max-width: 80%;
        }
        svg {
            display: block;
            z-index: 10;
            position: relative;
            width: 100%;
            height: auto;
        }
    }
    .top-info-container,
    .bottom-info-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        > div {
            width: 40%;
            .info-icon {
                display: none;
            }
            @media (min-width: 768px) and (max-width: 1024px) {
                width: calc(50% - 12px);
            }
            @media (max-width: 767px) {
                width: 36px;
                .info-card {
                    display: none;
                }
                .info-icon {
                    display: block;
                }
            }
        }
    }
    .top-info-container {
        & > div:nth-child(1) {
            margin-bottom: -50px;
            @media (max-width: 767px) {
                margin-left: 50px;
            }
        }
        & > div:nth-child(2) {
            margin-bottom: 50px;
            @media (max-width: 767px) {
                margin-bottom: -36px;
            }
        }
    }
    .bottom-info-container {
        & > div:nth-child(1) {
            @media (max-width: 767px) {
                margin-top: -36px;
            }
        }
    }
`;

const CarouselWrapper = styled.div`
    padding-top: 36px;
    display: none;
    @media (max-width: 767px) {
        display: block;
    }
    .card-carousel {
        .row .item {
            width: calc(100% - 12px);
            min-width: calc(100% - 12px);
        }
    }
`;
