import styled from 'styled-components';

import { colors } from '/css';

const lineStyle = (points: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}) => {
    const { x1, y1, x2, y2 } = points;
    const length = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
    return {
        width: length + 'px',
        top: y1 + 'px',
        left: x1 + 'px',
        transform: 'rotate(' + angle + 'deg)',
    };
};

interface Props {
    points: { x1: number; y1: number; x2: number; y2: number };
}

export default function Line({ points }: Props) {
    if (points.x1 && points.y1 && points.x2 && points.y2) {
        return <LineEl style={lineStyle(points)} />;
    }

    return null;
}

const LineEl = styled.div`
    transform-origin: 0 100%;
    height: 2px;
    background: ${colors.purple};
    position: absolute;
    z-index: 11;
    @media (max-width: 767px) {
        height: 1px;
    }
    &:after {
        content: '';
        width: 12px;
        height: 12px;
        background: ${colors.purple};
        border-radius: 12px;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 1px;
        @media (max-width: 767px) {
            width: 8px;
            height: 8px;
        }
    }
`;
