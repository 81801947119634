import { memo, useState } from 'react';
import styled from 'styled-components';
import curry from 'ramda/src/curry';

import Container from '/components/Container';
import { Image, config, Text, ui, RichText } from '/front';
import { TableModuleType, RowHeaderCell } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import { colors } from '/css';
import { insertAtIndex } from '/lib/helpers';
import id from '/front/lib/id';

export default memo(function JobCategoriesModule({
    module,
    onChange,
}: ModuleProps<TableModuleType>) {
    const {
        margin,
        containerSize,
        rows,
        headers,
        useRowHeaders,
        rowHeaderContentType,
        rowHeaders,
        textAlignment,
        useFixedLayout,
    } = module.data;

    const [rowKeys, setRowKeys] = useState<string[]>(rows.map((_) => id()));
    const [headerKeys, setHeaderKeys] = useState<string[]>(
        headers.map((_) => id())
    );

    const { Actions, editMode } = ui();

    const changeData = (data: Partial<TableModuleType['data']>) => {
        onChange({ data: { ...module.data, ...data } });
    };

    const rowHeaderTextValue = (val: RowHeaderCell) => {
        return typeof val === 'string' ? val : '';
    };

    const rowHeaderImageValue = (val: RowHeaderCell) => {
        if (typeof val === 'string') return null;
        return val;
    };

    const addColumn = (colIndex: number) => {
        setHeaderKeys(insertAtIndex(headerKeys, colIndex + 1, id()));
        changeData({
            headers: insertAtIndex(headers, colIndex + 1, ''),
            rows: rows.map((r) => insertAtIndex(r, colIndex + 1, '')),
        });
    };

    const removeColumn = (colIndex: number) => {
        setHeaderKeys(headerKeys.filter((_, hi) => hi !== colIndex));
        changeData({
            headers: headers.filter((_, hi) => hi !== colIndex),
            rows: rows.map((r) =>
                r.filter((_, cellIndex) => cellIndex !== colIndex)
            ),
        });
    };

    const addRowActions = (rowIndex: number) => (
        <Actions
            className="add-row"
            onAdd={() => addRow(rowIndex)}
            onRemove={rows.length === 1 ? undefined : () => removeRow(rowIndex)}
            align="bottom-left"
        />
    );

    const addRow = (rowIndex: number) => {
        setRowKeys(insertAtIndex(rowKeys, rowIndex + 1, id()));
        changeData({
            rows: insertAtIndex(
                rows,
                rowIndex + 1,
                headers.map((_) => '')
            ),
            rowHeaders: insertAtIndex(
                rowHeaders,
                rowIndex + 1,
                rowHeaderContentType === 'image' ? null : ''
            ),
        });
    };

    const removeRow = (rowIndex: number) => {
        setRowKeys(rowKeys.filter((_, ri) => ri !== rowIndex));
        changeData({
            rows: rows.filter((_, ri) => ri !== rowIndex),
            rowHeaders: rowHeaders.filter((_, rhi) => rhi !== rowIndex),
        });
    };

    const changeHeaderCell = curry((cellIndex: number, val: string) => {
        changeData({
            headers: headers.map((c, ci) => (ci !== cellIndex ? c : val)),
        });
    });

    const changeRowCell = curry(
        (rowIndex: number, headerIndex: number, val: string) => {
            changeData({
                rows: rows.map((r, ri) =>
                    ri !== rowIndex
                        ? r
                        : r.map((c, ci) => (ci !== headerIndex ? c : val))
                ),
            });
        }
    );

    const changeRowHeadercell = curry(
        (rowIndex: number, val: RowHeaderCell) => {
            changeData({
                rowHeaders: rowHeaders.map((r, ri) =>
                    ri !== rowIndex ? r : val
                ),
            });
        }
    );

    const rowTextHeader = (ri: number) => (
        <Text
            value={rowHeaderTextValue(rowHeaders[ri])}
            onChange={changeRowHeadercell(ri)}
            placeholder=""
            className={`paragraph-small light ${
                textAlignment === 'center' ? 'text-align-center' : ''
            }`}
            multiline
            tag="div"
        />
    );

    const rowImageHeader = (ri: number) => (
        <Image
            image={rowHeaderImageValue(rowHeaders[ri])}
            onChange={changeRowHeadercell(ri)}
            src={config
                .imageUrl(rowHeaderImageValue(rowHeaders[ri]))
                .width(120)
                .height(120)
                .url()}
            cropRatio={'1:1'}
            alt={rowHeaderImageValue(rowHeaders[ri])?.alt}
            style={{ display: 'block' }}
        />
    );

    return (
        <Wrapper className={[margin, 'pt-32'].join(' ')}>
            <Container
                size={containerSize}
                className={`container-size-${containerSize}`}
            >
                <Table
                    className={`text-align-${textAlignment} ${
                        useFixedLayout === true ? 'layout-fixed' : ''
                    }`}
                >
                    <thead>
                        <tr>
                            {useRowHeaders && (
                                <th
                                    className={`hidden ${
                                        rowHeaderContentType === 'image'
                                            ? 'image-cell'
                                            : 'text-cell'
                                    }`}
                                />
                            )}
                            {(headers || []).map((header, i: number) => {
                                return (
                                    <th key={headerKeys[i] || i} className="th">
                                        <Actions
                                            className="add-col"
                                            onAdd={() => addColumn(i)}
                                            onRemove={
                                                headers.length === 1
                                                    ? undefined
                                                    : () => removeColumn(i)
                                            }
                                            align="top-left"
                                        />
                                        <Text
                                            value={header}
                                            onChange={changeHeaderCell(i)}
                                            placeholder="..."
                                            multiline
                                            className={`paragraph-small ${
                                                textAlignment === 'center'
                                                    ? 'text-align-center'
                                                    : ''
                                            }`}
                                            tag="div"
                                        />
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {(rows || []).map((row, ri: number) => {
                            return (
                                <tr
                                    key={rowKeys[ri] || ri}
                                    className="has-front-actions"
                                >
                                    {useRowHeaders && (
                                        <th
                                            className={`${
                                                rowHeaderContentType === 'image'
                                                    ? 'image-cell'
                                                    : 'text-cell'
                                            }`}
                                        >
                                            {addRowActions(ri)}
                                            {rowHeaderContentType === 'text'
                                                ? rowTextHeader(ri)
                                                : rowImageHeader(ri)}
                                        </th>
                                    )}
                                    {row.map((cell, ci: number) => {
                                        return (
                                            <td
                                                key={ci}
                                                className={
                                                    textAlignment === 'center'
                                                        ? 'cell-center'
                                                        : undefined
                                                }
                                            >
                                                {!useRowHeaders &&
                                                    !ci &&
                                                    addRowActions(ri)}
                                                <RichText
                                                    value={cell}
                                                    className={`rich-text small ${
                                                        textAlignment ===
                                                        'center'
                                                            ? 'text-align-center'
                                                            : ''
                                                    }`}
                                                    onChange={changeRowCell(ri)(
                                                        ci
                                                    )}
                                                    editable={editMode}
                                                    placeholder="..."
                                                    disabledOptions={[
                                                        'h2',
                                                        'h3',
                                                        'h4',
                                                        'h5',
                                                        'paragraph',
                                                        'bulleted-list',
                                                        'numbered-list',
                                                        'link',
                                                        'italic',
                                                        'underlined',
                                                        'image',
                                                    ]}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    .add-col {
        transform: scale(0.7) translateX(-20%) translateY(-120%);
        left: 0;
        top: 0;
        width: 140%;
    }
    .add-row {
        transform: scale(0.7) translateX(-120%) translateY(-20%);
        top: 0;
        height: 140%;
        left: 0;
        padding-top: 10px;
    }
    overflow-x: visible;
    overflow-y: hidden;
    .container-size-large {
        min-width: 1160px;
    }
    .container-size-medium {
        min-width: 1000px;
    }
    .container-size-mediumSmall {
        min-width: 800px;
    }
    .container-size-small {
        min-width: 650px;
    }
    .container-size-smaller {
        min-width: 550px;
    }
`;

const Table = styled.table`
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;
    border-spacing: 0 16px;
    &.layout-fixed {
        table-layout: fixed;
    }
    th,
    td {
        text-align: left;
        padding: 8px 8px 9px;
        height: 38px;
        min-height: 38px;
        min-width: 120px;
    }
    th {
        background: ${colors.featherGrey};
        &.image-cell {
            width: 132px;
            padding: 0;
            background: transparent;
            padding-right: 12px;
            img {
                border-radius: 12px;
                overflow: hidden;
            }
        }
    }
    tr {
        border-bottom: 16px solid transparent;
    }
    thead {
        tr {
            th {
                &.hidden {
                    border: none;
                    background: transparent;
                }
                &.th:not(.th ~ .th) {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }
                &.th:last-child {
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
    tbody {
        tr {
            &:hover {
                .add-col,
                .add-row {
                    opacity: 1;
                    visibility: visible;
                }
            }
            th {
                border-radius: 12px;
            }
            td {
                vertical-align: top;
                &.cell-center {
                    vertical-align: middle;
                }
            }
        }
    }
    &.text-align-top-left {
        td,
        th {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
`;
