import { useMemo, useState } from 'react';
import Modal from '/components/ui/Modal';
import Button from '/components/ui/buttons/Button';
import ModalDescription from '/components/ui/modal/ModalDescription';
import ModalFooter from '/components/ui/modal/ModalFooter';
import ModalHeader from '/components/ui/modal/ModalHeader';
import Label from '/components/ui/typography/Label';
import LegacyInput from '/components/ui/LegacyInput';
import styled from 'styled-components';
import { ValidationErrors } from '/types/apiResponseTypes';

interface Props {
    onClose: () => void;
    formTitle: string;
    formDescription: string;
    onSubmit: (
        data: {
            name: string;
            phoneNumber: string;
            description: string;
        },
        errorCallback: (errors?: any) => void
    ) => void;
}

const ContactCallbackModuleModal = ({
    onClose,
    onSubmit,
    formTitle,
    formDescription,
}: Props) => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [description, setDescription] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] =
        useState<ValidationErrors>();

    const handleSubmit = () => {
        setSubmitting(true);
        setValidationErrors(undefined);

        onSubmit(
            {
                name,
                phoneNumber,
                description,
            },
            (errors: any) => {
                setSubmitting(false);
                setValidationErrors(errors);
            }
        );
    };

    return (
        <StyledModal
            className="p-48"
            closeRequestCallback={onClose}
            zIndex={10000}
        >
            <ModalHeader>{formTitle}</ModalHeader>
            <ModalDescription>{formDescription}</ModalDescription>

            <Label className="mb-8 ml-8" htmlFor="contact-name">
                Navn
            </Label>
            <LegacyInput
                value={name}
                onChange={(val: string) => setName(val)}
                placeholder="Skriv inn navnet ditt"
                className="mb-24"
                id="contact-name"
                name="name"
                apiValidationErrors={validationErrors?.name}
            />

            <Label className="mb-8 ml-8" htmlFor="contact-phone">
                Telefonnummer
            </Label>
            <LegacyInput
                value={phoneNumber}
                onChange={(val: string) => setPhoneNumber(val)}
                placeholder="Skriv inn telefonnummeret ditt"
                className="mb-24"
                id="contact-phone"
                name="phonenumber"
                apiValidationErrors={validationErrors?.phoneNumber}
            />

            <Label className="mb-8 ml-8" htmlFor="contact-description">
                Beskriv prosjektet (valgfritt)
            </Label>
            <LegacyInput
                value={description}
                onChange={(val: string) => setDescription(val)}
                placeholder="Her kan du beskrive prosjektet ditt eller fortelle oss hva du lurer på"
                multiline
                className="mb-48"
                htmlFor="contact-description"
                name="description"
                apiValidationErrors={validationErrors?.description}
            />

            <ModalFooter>
                <Button size="large" onClick={handleSubmit} busy={submitting}>
                    Send forespørsel
                </Button>
                <Button variant="tertiary" size="large" onClick={onClose}>
                    Avbryt
                </Button>
            </ModalFooter>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    width: 100%;
    max-width: 800px;
`;

export default ContactCallbackModuleModal;
