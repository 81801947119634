import AddressSearchResult, {
    RoofData,
} from '/modules/solar/types/AddressSearchResults';
export const dummyAddress: AddressSearchResult = {
    id: '15075111100030000000',
    address: 'Lovollvegen 3',
    city: 'SKODJE',
    postCode: '6260',
    position: {
        lat: 62.50004982188091,
        lng: 6.708087440699315,
    },
    isDummy: true,
};

export const dummyRoofs: RoofData[] = [
    {
        id: 150710179878,
        buildingId: '300425686',
        slope: 29.639999389648438,
        direction: 125,
        width: 13.300000190734863,
        length: 7.28000020980835,
        area2d: 43.27000045776367,
        area3d: 48.41999816894531,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500026948,
                    lng: 6.708165249,
                    elevation: 36.64,
                },
                {
                    lat: 62.500052649,
                    lng: 6.708335506,
                    elevation: 33.37,
                },
                {
                    lat: 62.499946723,
                    lng: 6.708217849,
                    elevation: 33.37,
                },
                {
                    lat: 62.500026948,
                    lng: 6.708165249,
                    elevation: 36.64,
                },
            ],
        },
        production: {
            yearly100W: 71948,
        },
    },
    {
        id: 150710179879,
        buildingId: '300425686',
        slope: 29.3700008392334,
        direction: 34,
        width: 11.029999732971191,
        length: 10.979999542236328,
        area2d: 53.310001373291016,
        area3d: 59.900001525878906,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500052649,
                    lng: 6.708335506,
                    elevation: 33.37,
                },
                {
                    lat: 62.500026948,
                    lng: 6.708165249,
                    elevation: 36.64,
                },
                {
                    lat: 62.500033417,
                    lng: 6.708138111,
                    elevation: 36.62,
                },
                {
                    lat: 62.500112624,
                    lng: 6.70808312,
                    elevation: 33.34,
                },
                {
                    lat: 62.500098595,
                    lng: 6.70814227,
                    elevation: 33.35,
                },
                {
                    lat: 62.500093402,
                    lng: 6.708164013,
                    elevation: 33.352,
                },
                {
                    lat: 62.500061549,
                    lng: 6.708298119,
                    elevation: 33.367,
                },
                {
                    lat: 62.500055924,
                    lng: 6.708321701,
                    elevation: 33.37,
                },
                {
                    lat: 62.500052649,
                    lng: 6.708335506,
                    elevation: 33.37,
                },
            ],
        },
        production: {
            yearly100W: 48928,
        },
    },
    {
        id: 150710179880,
        buildingId: '300425686',
        slope: 18.25,
        direction: 35,
        width: 10.40999984741211,
        length: 1.4900000095367432,
        area2d: 12.979999542236328,
        area3d: 13.529999732971191,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500109936,
                    lng: 6.708154782,
                    elevation: 30.52,
                },
                {
                    lat: 62.500067354,
                    lng: 6.708334207,
                    elevation: 30.52,
                },
                {
                    lat: 62.500061549,
                    lng: 6.708298119,
                    elevation: 30.94,
                },
                {
                    lat: 62.500093402,
                    lng: 6.708164013,
                    elevation: 30.94,
                },
                {
                    lat: 62.500109936,
                    lng: 6.708154782,
                    elevation: 30.52,
                },
            ],
        },
        production: {
            yearly100W: 55184,
        },
    },
    {
        id: 150710179881,
        buildingId: '300425686',
        slope: 28.770000457763672,
        direction: 215,
        width: 14.649999618530273,
        length: 7.480000019073486,
        area2d: 45.95000076293945,
        area3d: 51.08000183105469,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500006702,
                    lng: 6.707965385,
                    elevation: 33.34,
                },
                {
                    lat: 62.500033417,
                    lng: 6.708138111,
                    elevation: 36.62,
                },
                {
                    lat: 62.500026948,
                    lng: 6.708165249,
                    elevation: 36.64,
                },
                {
                    lat: 62.499946723,
                    lng: 6.708217849,
                    elevation: 33.37,
                },
                {
                    lat: 62.499965038,
                    lng: 6.708140667,
                    elevation: 33.36,
                },
                {
                    lat: 62.500001528,
                    lng: 6.708115702,
                    elevation: 34.84,
                },
                {
                    lat: 62.499988387,
                    lng: 6.708042567,
                    elevation: 33.36,
                },
                {
                    lat: 62.500006702,
                    lng: 6.707965385,
                    elevation: 33.34,
                },
            ],
        },
        production: {
            yearly100W: 74461,
        },
    },
    {
        id: 150710179882,
        buildingId: '300425686',
        slope: 29.139999389648438,
        direction: 124,
        width: 4.570000171661377,
        length: 3.3499999046325684,
        area2d: 9.15999984741211,
        area3d: 10.210000038146973,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500001528,
                    lng: 6.708115702,
                    elevation: 34.84,
                },
                {
                    lat: 62.499965038,
                    lng: 6.708140667,
                    elevation: 33.36,
                },
                {
                    lat: 62.499952873,
                    lng: 6.708127058,
                    elevation: 33.36,
                },
                {
                    lat: 62.499965126,
                    lng: 6.708075376,
                    elevation: 34.84,
                },
                {
                    lat: 62.500001528,
                    lng: 6.708115702,
                    elevation: 34.84,
                },
            ],
        },
        production: {
            yearly100W: 71716,
        },
    },
    {
        id: 150710179883,
        buildingId: '300425686',
        slope: 29.489999771118164,
        direction: 305,
        width: 13.300000190734863,
        length: 7.340000152587891,
        area2d: 43.66999816894531,
        area3d: 48.810001373291016,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500033417,
                    lng: 6.708138111,
                    elevation: 36.62,
                },
                {
                    lat: 62.500006702,
                    lng: 6.707965385,
                    elevation: 33.34,
                },
                {
                    lat: 62.500112624,
                    lng: 6.70808312,
                    elevation: 33.34,
                },
                {
                    lat: 62.500033417,
                    lng: 6.708138111,
                    elevation: 36.62,
                },
            ],
        },
        production: {
            yearly100W: 53055,
        },
    },
    {
        id: 150710179884,
        buildingId: '300425686',
        slope: 32,
        direction: 304,
        width: 4.570000171661377,
        length: 3.069999933242798,
        area2d: 8.210000038146973,
        area3d: 9.369999885559082,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.499988387,
                    lng: 6.708042567,
                    elevation: 33.36,
                },
                {
                    lat: 62.500001528,
                    lng: 6.708115702,
                    elevation: 34.84,
                },
                {
                    lat: 62.499965126,
                    lng: 6.708075376,
                    elevation: 34.84,
                },
                {
                    lat: 62.499976134,
                    lng: 6.708029139,
                    elevation: 33.36,
                },
                {
                    lat: 62.499988387,
                    lng: 6.708042567,
                    elevation: 33.36,
                },
            ],
        },
        production: {
            yearly100W: 52437,
        },
    },
    {
        id: 150710179885,
        buildingId: '300425686',
        slope: 20.43000030517578,
        direction: 304,
        width: 1.4199999570846558,
        length: 1.3300000429153442,
        area2d: 0.8999999761581421,
        area3d: 0.949999988079071,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500109936,
                    lng: 6.708154782,
                    elevation: 30.52,
                },
                {
                    lat: 62.500093402,
                    lng: 6.708164013,
                    elevation: 30.94,
                },
                {
                    lat: 62.500098595,
                    lng: 6.70814227,
                    elevation: 30.52,
                },
                {
                    lat: 62.500109936,
                    lng: 6.708154782,
                    elevation: 30.52,
                },
            ],
        },
        production: {
            yearly100W: 56775,
        },
    },
    {
        id: 150710179886,
        buildingId: '300425686',
        slope: 18.940000534057617,
        direction: 124,
        width: 1.4299999475479126,
        length: 1.4299999475479126,
        area2d: 0.9800000190734863,
        area3d: 1.0299999713897705,
        geometry: {
            type: 'Polygon',
            coordinates: [
                {
                    lat: 62.500061549,
                    lng: 6.708298119,
                    elevation: 30.94,
                },
                {
                    lat: 62.500067354,
                    lng: 6.708334207,
                    elevation: 30.52,
                },
                {
                    lat: 62.500055924,
                    lng: 6.708321701,
                    elevation: 30.52,
                },
                {
                    lat: 62.500061549,
                    lng: 6.708298119,
                    elevation: 30.94,
                },
            ],
        },
        production: {
            yearly100W: 70402,
        },
    },
];

export const dummyEstimates = dummyRoofs.map((r) => ({
    id: r.id,
    potential: r.production.yearly100W,
}));
