import { memo } from 'react';
import styled from 'styled-components';

import Container, { ContainerSizes } from '/components/Container';
import { RichText, Image, config, Buttons } from '/front';
import { TextAndImageModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';

export default memo(function TextAndImageModule({
    module,
    onChange,
}: ModuleProps<TextAndImageModuleType>) {
    const {
        content,
        margin,
        buttons,
        image,
        imageAlignment,
        textPosition,
        containerSize,
        verticalPadding,
        backgroundColor,
        textImageSpacing,
        imageRoundedBorders,
        textColor,
        proportions,
    } = module.data;

    const textImageProportions = (proportions || '50-50')
        .split('-')
        .map(Number);

    return (
        <div
            className={[
                margin,
                verticalPadding &&
                    `pt-${verticalPadding} pb-${verticalPadding}`,
            ]
                .filter((c) => c)
                .join(' ')}
            style={{ backgroundColor: backgroundColor }}
        >
            <Container
                medium={containerSize === ContainerSizes.MEDIUM}
                mediumSmall={containerSize === ContainerSizes.MEDIUM_SMALL}
            >
                <Wrapper
                    className={`image-alignment-${imageAlignment} text-color-${
                        textColor || 'black'
                    }`}
                    textImageSpacing={textImageSpacing || '24px'}
                    imageWidth={
                        imageAlignment === 'right'
                            ? textImageProportions[1]
                            : textImageProportions[0]
                    }
                    textWidth={
                        imageAlignment === 'right'
                            ? textImageProportions[0]
                            : textImageProportions[1]
                    }
                >
                    <div
                        className={`text-container has-front-actions ${
                            textPosition === 'center' ? 'y-centered' : 'y-top'
                        }`}
                    >
                        <RichText
                            className="rich-text"
                            value={content}
                            onChange={(content: any) =>
                                onChange({ data: { ...module.data, content } })
                            }
                            editable={undefined}
                            disabledOptions={['image']}
                        />
                        <Buttons
                            buttons={buttons}
                            onChange={(buttons: any) =>
                                onChange({ data: { ...module.data, buttons } })
                            }
                        />
                    </div>
                    <div
                        className={`image-container ${
                            textPosition === 'center' ? 'y-centered' : 'y-top'
                        }`}
                    >
                        <div
                            className={
                                imageRoundedBorders ? 'rounded-borders' : ''
                            }
                        >
                            <div>
                                <Image
                                    image={image}
                                    src={config
                                        .imageUrl(image)
                                        .width(580)
                                        .url()}
                                    onChange={(image: any) =>
                                        onChange({
                                            data: { ...module.data, image },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Wrapper>
            </Container>
        </div>
    );
});

const Wrapper = styled.div<{
    textImageSpacing: string;
    imageWidth: number;
    textWidth: number;
}>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .text-container {
        width: ${(props) => props.textWidth}%;
        padding-right: ${(props) => props.textImageSpacing};
        &.y-centered {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div:first-child {
                min-width: 100%;
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            order: 2;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 32px;
            .buttons-container {
                width: 100%;
                > * {
                    width: 100%;
                    margin: 0 0 20px 0;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            .button {
                width: 100%;
            }
        }
    }
    .image-container {
        width: ${(props) => props.imageWidth}%;
        @media (max-width: 767px) {
            width: 100%;
            order: 1;
            margin-bottom: 32px;
            padding-left: 0;
            padding-right: 0;
        }
        &.y-centered {
            display: flex;
            flex-direction: column;
            justify-content: center;
            > div:first-child {
                min-width: 100%;
            }
        }
        > div {
            position: relative;
            &.rounded-borders {
                border-radius: 12px;
                overflow: hidden;
            }
        }
        img {
            display: block;
            margin-left: auto;
            @media (max-width: 767px) {
                margin-left: initial;
            }
        }
    }
    &.image-alignment-left {
        .text-container {
            order: 2;
            padding-left: ${(props) => props.textImageSpacing};
            padding-right: 0;
            @media (max-width: 767px) {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 32px;
            }
        }
        .image-container {
            order: 1;
        }
    }
    &.text-color-white {
        .rich-text {
            p,
            div[data-block='true'],
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            li {
                color: white;
            }
        }
        .button-type-tertiary {
            color: white;
            border-color: rgba(255, 255, 255, 0.5);
        }
    }
`;
