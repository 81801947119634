import { useState, CSSProperties, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import Label from '/components/ui/Label';
import { colors } from '/css';

const percentOfPrecise = (x: number, y: number) => (x / (y || 1)) * 100;

export interface NumberSliderProps {
    name: string;
    label?: string;
    value: number;
    onChange: (val: number) => void;
    minValue: number;
    maxValue: number;
    style?: CSSProperties;
    className?: string;
    formatNumbers?: (val: number) => string | number;
}

export default function NumberSlider({
    name,
    label,
    value,
    onChange,
    minValue = 0,
    maxValue = 100,
    style,
    className,
    formatNumbers = (val) => val,
}: NumberSliderProps) {
    const [val, setVal] = useState(value);

    const id = 'ns-' + name;

    const valuePercent = percentOfPrecise(val - minValue, maxValue - minValue);

    const handleChange = (newVal: number) => {
        setVal(newVal);
        onChange(newVal);
    };

    return (
        <div
            className={['number-slider', className || ''].join(' ')}
            style={{ position: 'relative' }}
        >
            {label && <Label style={{ marginBottom: '4px' }}>{label}</Label>}
            <Slider style={style}>
                <input
                    id={id}
                    type="range"
                    min={minValue}
                    max={maxValue}
                    value={val}
                    onChange={(e) => handleChange(Number(e.target.value))}
                />
                {val !== minValue && (
                    <span className="min-max min">
                        {formatNumbers(minValue)}
                    </span>
                )}
                {val !== maxValue && (
                    <span className="min-max max">
                        {formatNumbers(maxValue)}
                    </span>
                )}
                <div className="track" />
                <div
                    className="track track-value"
                    style={{ width: `${valuePercent}%` }}
                />
                <span
                    className="value-text"
                    style={{
                        left: `${valuePercent}%`,
                        transform: `translateX(-${valuePercent}%)`,
                    }}
                >
                    {formatNumbers(val)}
                </span>
            </Slider>
        </div>
    );
}

const Slider = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    align-items: flex-end;
    position: relative;
    input {
        -webkit-appearance: none;
        width: 100%;
        outline: none;
        transition: all 0.2s;
        box-shadow: none;
        border: none;
        padding: 0;
        height: 6px;
        border-radius: 3px;
        background: transparent;
        position: relative;
        z-index: 3;
        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: none;
            background: ${colors.purple};
            cursor: pointer;
        }
        &::-moz-range-thumb {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: ${colors.purple};
            border: none;
            cursor: pointer;
        }
    }
    .min-max {
        position: absolute;
        bottom: 16px;
        font-size: 17px;
        line-height: 26px;
        color: ${colors.grey};
        font-family: 'gellix-medium';
        &.min {
            left: 0;
        }
        &.max {
            right: 0;
        }
    }
    .track {
        position: absolute;
        bottom: 1px;
        left: 0;
        display: block;
        height: 6px;
        border-radius: 8px;
        background: ${colors.lightGrey};
        z-index: 1;
        width: 100%;
        &.track-value {
            background: ${colors.purple};
            z-index: 2;
            max-width: 99%;
        }
    }
    .value-text {
        position: absolute;
        top: 0;
        z-index: 10;
        user-select: none;
        pointer-events: none;
        display: flex;
        justify-content: center;
        font-family: 'gellix-bold';
        text-align: center;
        font-size: 20px;
        line-height: 26px;
        border: 4px solid #f5f5f7;
        border-radius: 4px;
        padding: 5px 12px 7px;
    }
`;
