import { useState } from 'react';

import { success } from '/components/ui/toast';
import contactApi from '/api/contactApi';
import styled from 'styled-components';
import Button from '/components/ui/buttons/Button';
import ContactCallbackModuleModal from './ContactCallbackModule.modal';

interface Props {
    buttonText?: string;
    modalStateOverride?: [boolean, (val: boolean) => void];
}

export default function SolarContactFormButton({
    buttonText = 'Snakk med våre solcelleeksperter',
    modalStateOverride,
}: Props) {
    const [modalOpenInternal, setModalOpenInternal] = useState<boolean>(false);

    const [modalOpen, setModalOpen] = modalStateOverride || [
        modalOpenInternal,
        setModalOpenInternal,
    ];

    const handleSubmit = async (
        formData: { name: string; phoneNumber: string; description: string },
        errorCallback: (errors: any) => void
    ) => {
        const response = await contactApi.requestCallback(
            formData.name,
            formData.phoneNumber,
            'solar',
            formData.description
        );

        if (response.error) {
            errorCallback(response.errors);
            return;
        }

        setModalOpen(false);
        success(
            'Takk for din henvendelse, vi kontakter deg så fort som mulig!'
        );
    };

    return (
        <Wrapper className="solar-contact">
            <Button
                variant="tertiary"
                onClick={() => setModalOpen(true)}
                fullWidth
            >
                {buttonText}
            </Button>
            {modalOpen && (
                <ContactCallbackModuleModal
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleSubmit}
                    formTitle="Snakk med våre solcelleeksperter"
                    formDescription="Legg igjen navn og nummer, og en av våre solcelleeksperter vil ta kontakt for å hjelpe deg med prosjektet ditt."
                />
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    > button div {
        word-break: break-word;
        white-space: break-spaces;
    }
`;
