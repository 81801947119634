import ArticleIntro from './ArticleIntroModule';
import ArticleIntroSettings from './ArticleIntroModuleSettings';
import { ArticleIntroModuleType } from './types';

const moduleData: ArticleIntroModuleType = {
    _key: '',
    _type: 'article_intro',
    title: 'Article intro',
    description: '',
    image: '/modules/articleIntro.svg',
    preview: '/modules/articleIntro-preview.png',
    moduleComponent: ArticleIntro,
    settingsComponent: ArticleIntroSettings,
    data: {
        description: '',
        title: '',
        textColor: 'black',
        margin: 'mb-32',
        image: null,
        overlayOpacity: 0,
        overlayColor: 'white',
        hideDate: false,
        withButtons: false,
        buttons: [],
    },
};

export default moduleData;
