import ModuleType from '/front/types/frontModuleType';
import { Margin } from './../_shared/MarginSetting';
import { ContainerSizes } from '/components/Container';
import ButtonType from '/front/types/frontButtonType';

export enum Theme {
    DEFAULT = 'default',
    PADDED_BACKGROUND = 'padded-background',
}

export interface Item {
    _key: string;
    title: string;
    content: string;
}

export interface TogglesModuleType extends ModuleType {
    data: {
        margin: Margin;
        items: Item[];
        containerSize: ContainerSizes;
        listTitle: boolean;
        listSubtitle: boolean;
        title: string;
        subtitle: string;
        theme: Theme;
        withReadMoreLink: boolean;
        readMoreLink: ButtonType | undefined;
    };
}
