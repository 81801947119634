import { memo } from 'react';

import { HowItWorksModuleType } from './types';
import { ModuleProps } from '/front/types/frontModuleType';
import ElectricianJobInstallation from './processes/ElectricianJobInstallation';
import VideoInspection from './processes/VideoInspection';

export default memo(function HowItWorksModule({
    module,
    onChange,
}: ModuleProps<HowItWorksModuleType>) {
    const {
        margin,
        electricianJobInstallation,
        videoInspection,
        process,
        background,
    } = module.data;

    return (
        <div
            className={`${margin}`}
            style={{ background: background || 'white' }}
        >
            {process === 'electricianJobInstallation' && (
                <ElectricianJobInstallation
                    data={electricianJobInstallation}
                    onChange={(data) =>
                        onChange({
                            ...module,
                            data: {
                                ...module.data,
                                electricianJobInstallation: data,
                            },
                        })
                    }
                />
            )}
            {process === 'videoInspection' && (
                <VideoInspection
                    data={videoInspection}
                    onChange={(data) =>
                        onChange({
                            ...module,
                            data: {
                                ...module.data,
                                videoInspection: data,
                            },
                        })
                    }
                />
            )}
        </div>
    );
});
