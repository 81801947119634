enum SolarJobLocationStatus {
    missingAddress = 'missingAddress',
    pending = 'pending',
    fetching = 'fetching',
    calculating = 'calculating',
    noRoofs = 'noRoofs',
    ready = 'ready',
    updating = 'updating', // Used for when adding more buildings
}

export default SolarJobLocationStatus;
